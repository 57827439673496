import React, { useState } from 'react';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

// const SortableItem = SortableElement(({value}) => <li>{value}</li>);

export const SortableItem = SortableElement(({ children }) => children)

const SortableList = SortableContainer(({ children }) => {
  return (
    children
  );
});

const shouldCancelStart = (e) => {
  // Cancel sorting if the event target is an anchor tag (`a`)
  if (e.target.tagName.toLowerCase() === 'a' || e.target.tagName.toLowerCase() === 'span' || e.target.tagName.toLowerCase() === 'svg') {
      return true; // Return true to cancel sorting
  } else {
    return false;
  }
}

const SortableComponent = ({ onSortEnd, children }) => {

  return (
    <SortableList
      onSortEnd={onSortEnd}
      axis="xy"
      shouldCancelStart={shouldCancelStart}
    >
      {children}
    </SortableList>
  )
}

export default SortableComponent
