import React from "react";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../StyledComponents/styledComponents";
import { Menu, Dropdown, Button, message } from "antd";
import { EllipsisOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";
import SpotlightDetailsModal from "./SpotlightDetailsModal";
import EditSpotlightModal from "./EditSpotlightModal";
import DeleteSpotlightModal from "./DeleteSpotlightModal";

const SpotlightTableActions = ({ spotlight, currentBusiness }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();

  /**
   * Moves spotlight up the array
   */
  const moveUp = (spotlight, currentBusiness) => {
    if (spotlight.index === 0) {
      message.warn("Cannot not move up first spotlight.");
    } else {
      console.log("\n");
      let spotlightsList = currentBusiness.spotlight;

      spotlightsList.forEach((item, idx) => {
        let currentSpotlight = item;

        // Find the target spotlight and is not the last element of the array
        if (
          currentSpotlight.index === spotlight.index &&
          currentSpotlight.index !== 0
        ) {
          let prevSpotlight = spotlightsList[idx - 1];
          spotlightsList[idx] = prevSpotlight;
          spotlightsList[idx - 1] = currentSpotlight;
        }
      });

      spotlightsList = spotlightsList.filter(
        (item, idx) => typeof item !== "undefined"
      );
      spotlightsList.forEach((item, idx) => {
        item.index = idx;
      });

      const refPath = `/companies/${currentBusiness.id}`;
      let businessToUpdate = currentBusiness;
      businessToUpdate.spotlight = spotlightsList;
      console.log("Updated Business (up): ", businessToUpdate, refPath);

      firebase
        .getDb()
        .doUpdateDoc("companies", currentBusiness.id, businessToUpdate, () => {
          dispatch(enableFetch());
          dispatch(disableFetch());
          message.success("Successfully moved up spotlight.");
        });
    }
  };

  /**
   * Moves spotlight down the array
   */
  const moveDown = (spotlight, currentBusiness) => {
    if (spotlight.index === currentBusiness.spotlight.length - 1) {
      message.warn("Cannot not move down last spotlight.");
    } else {
      console.log("\n");
      let spotlightsList = currentBusiness.spotlight;
      let nextSpotlight = null;

      spotlightsList.forEach((item, idx) => {
        let currentSpotlight = item;

        // Find the target spotlight and is not the last element of the array
        if (
          currentSpotlight.index === spotlight.index &&
          currentSpotlight.index !== spotlightsList.length - 1
        ) {
          nextSpotlight = spotlightsList[idx + 1];
          spotlightsList[idx] = nextSpotlight;
          spotlightsList[idx + 1] = currentSpotlight;

          console.log("current spotlight: ", currentSpotlight);
          console.log("next spotlight: ", nextSpotlight);
        }
      });

      spotlightsList = spotlightsList.filter(
        (item, idx) => typeof item !== "undefined"
      );
      spotlightsList.forEach((item, idx) => {
        item.index = idx;
      });

      const refPath = `/companies/${currentBusiness.id}`;
      let businessToUpdate = currentBusiness;
      businessToUpdate.spotlight = spotlightsList;
      console.log("Updated Business: ", businessToUpdate, refPath);

      firebase
        .getDb()
        .doUpdateDoc("companies", currentBusiness.id, businessToUpdate, () => {
          dispatch(enableFetch());
          dispatch(disableFetch());
          message.success("Successfully moved down spotlight.");
        });
    }
  };

  const menu = (
    <StyledMenu>
      {spotlight.index !== 0 ? (
        <StyledMenuItem onClick={() => moveUp(spotlight, currentBusiness)}>
          <UpOutlined /> Move up
        </StyledMenuItem>
      ) : (
        <></>
      )}

      {spotlight.index !== currentBusiness.spotlight.length - 1 ? (
        <StyledMenuItem onClick={() => moveDown(spotlight, currentBusiness)}>
          <DownOutlined /> Move down
        </StyledMenuItem>
      ) : (
        <></>
      )}

      <Menu.Divider />

      <StyledMenuItem>
        <SpotlightDetailsModal spotlight={spotlight} />
      </StyledMenuItem>
      <StyledMenuItem>
        <EditSpotlightModal
          spotlight={spotlight}
          currentBusiness={currentBusiness}
        />
      </StyledMenuItem>
      <StyledMenuItem danger>
        <DeleteSpotlightModal
          currentBusiness={currentBusiness}
          spotlight={spotlight}
        />
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Button size="small" shape="round" icon={<EllipsisOutlined />} />
    </Dropdown>
  );
};

export default SpotlightTableActions;
