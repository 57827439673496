import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import {
  FacebookFilled,
  InstagramFilled,
  TwitterSquareFilled,
} from "@ant-design/icons";

export const LandingFooter = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <Row span={24} gutter={30}>
          <Col span={12}>
            <BrandLogo>Student Life 101</BrandLogo>
            <Row>
              <Col span={24}>United States.</Col>
              <Col span={24}>English (United States)</Col>
              <Col span={24}>Alligator Applications LLC.</Col>
            </Row>
          </Col>
          <Col span={12}>
            <h3>Social</h3>
            <Row>
              <Col span={24}>
                <a href="https://www.instagram.com/studentlife101usf/" target="_blank">
                  <InstagramFilled /> Instagram
                </a>
              </Col>
              <Col span={24}>
                <a href="https://studentdiscounts.page.link/app" target="_blank">
                  <FacebookFilled /> Facebook
                </a>
              </Col>
              <Col span={24}>
                <a href="https://twitter.com/studentlife101?lang=en" target="_blank">
                  <TwitterSquareFilled /> Twitter
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </FooterContainer>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  margin: 0 auto;
  height: 400px;
  border-top: 1px solid #eee;
  padding-top: 100px;
  background-color: #f6f7f9 !important;
`;

const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 980px;
  padding: 0 10px 0 10px;
`;

const BrandLogo = styled.div`
  font-size: 17px;
  font-weight: 700;
  color: #000;

  &:hover {
    cursor: pointer;
    color: #101010;
  }
`;