import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Divider, Row, Col, Card } from "antd";
import { LoadingOutlined, EyeOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const AdsImpressionsCounts = (props) => {
  // const { currentBusiness } = props;
  const { couponsList } = props;
  const [adsList, setAdsList] = useState(null);

  useEffect(() => {
    if (couponsList) {
      let data = [];
      const ads = couponsList.filter(
        (coupon) => coupon.headline === "" || coupon.headline === undefined
      );

      ads.forEach((coupon) => {
        data.push({
          headline: coupon.url,
          impression: coupon.impressionsCount,
        });
      });

      data.sort((a, b) => a.impression - b.impression);
      setAdsList(data);
    }
  }, [couponsList]);

  if (!adsList) {
    return <LoadingOutlined />;
  }

  if (adsList) {
    return (
      <>
        <Row>
          <Col span={24}>
            <Title level={4}>
              <EyeOutlined /> Image Ads Impressions
            </Title>
            Total views of your image advertisements from start to end date.
          </Col>
          <Col span={12} style={{ textAlign: "right" }}></Col>
        </Row>
        <br />
        <Row gutter={[20, 20]}>
          {adsList.length === 0 ? (
            <Paragraph>There are no image ads yet.</Paragraph>
          ) : (
            <>
              {adsList.map((ad, idx) => (
                <Col xxl={4} xl={6} lg={12} md={12} sm={12} xs={24} key={idx}>
                  <Card className="sm-box-shadow round">
                    <span style={{ fontSize: "24px", fontWeight: "600" }}>
                      {ad.impression}
                    </span>{" "}
                    impressions
                    <Divider />
                    <ImpressionsImg
                      src={ad.headline}
                      alt="label-img"
                      draggable={false}
                      className="round"
                    />
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Row>
      </>
    );
  }
};

const ImpressionsImg = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export default AdsImpressionsCounts;
