import React, { useEffect } from "react";
import styled from "styled-components";
import { Row, Layout, Divider } from "antd";
import { HeaderNav } from "./HeaderNav.js";
import SideNavMenu from "./SideNavMenu.js";
import { useSelector, useDispatch } from "react-redux";
import {
  showCollapsed,
  hideCollapsed,
} from "../../../redux/actions/collapsedStateActions.js";

const { Sider, Content } = Layout;

export default function AdminLayout({ children }) {
  const { sidebarCollapseReducer } = useSelector((state) => state);
  const { collapsed } = sidebarCollapseReducer;
  const dispatch = useDispatch();

  /**
   * Handle dashboard transformation when toggling collapsible sidebar.
   * Dependent on collapsed state changing.
   * This happens by default by Ant Design, but because we use fixed sider and
   * header, we have to tweak it.
   */
  useEffect(() => {
    vertNavCollapseStyles(collapsed);
  });

  const vertNavCollapseStyles = (collapsed) => {
    let dashboardHeader = document.getElementById("adminDashboardHeader");
    let dashboardNav = document.getElementById("adminDashboardNav");
    let siteLayout = document.getElementById("siteLayout");

    const nodesExists =
      siteLayout !== null || dashboardHeader !== null || dashboardNav !== null;

    if (nodesExists) {
      if (collapsed) {
        siteLayout.style.marginLeft = "80px";
        siteLayout.style.transition = `all ease-in-out 0.2s`;
      } else {
        siteLayout.style.marginLeft = "250px";
        siteLayout.style.transition = `all ease-in-out 0.1s`;
      }
    }
  };

  const onCollapse = () => {
    collapsed ? dispatch(hideCollapsed()) : dispatch(showCollapsed());
  };

  return (
    <Layout>
      <HeaderNav />
      {typeof window !== "undefined" ? (
        <DashboardSider
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          id="globalSidebar"
        >
          <SideNavMenu />
        </DashboardSider>
      ) : null}

      <Layout id="siteLayout">
        <DashboardContent className="site-layout-background">
          <Wrapper>
            <Container>
              {children}
              <Divider />
              <Footer />
            </Container>
          </Wrapper>
        </DashboardContent>
      </Layout>
    </Layout>
  );
}

const Footer = () => (
  <Row justify="end">
    Alligator Applications LLC. &copy; 2020 All Rights Reserved.
  </Row>
);

/**
 * Styled Components
 */
const DashboardContent = styled(Content)`
  min-height: 100vh;
`;

const DashboardSider = styled(Sider)`
  overflow: auto;
  position: fixed;
  left: 0;
  background-color: #fff;
  min-height: 100vh;
  margin-top: 58px;
  .ant-layout-sider-trigger {
    background-color: #fff;
    color: #000;
  }
`;

const Wrapper = styled.div`
  background-color: #fff;
  padding: 24px 24px;
  min-height: 100vh;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1470px;
  padding: 0 60px 0 60px;
`;
