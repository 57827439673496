import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import HeaderNav from "./HeaderNav.js";
import Screenshots from "./Screenshots.js";
import PartnerSection from "./PartnerSection.js";
import ProductInfoSection from "./ProductInfoSection.js";
import { WhySection } from "./WhySection.js";
import { EmailBusinessCard } from "./EmailBusinessCard.js";
import { LandingFooter } from "./LandingFooter.js";

const Landing = () => (
  <LandingWrapper>
    <GradientBackground />
    <HeaderNav />
    <HomePageHero>
      <Row gutter={[20, 20]}>
        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
          <Row>
            <Col style={{ zIndex: "99" }}>
              <H1>Digital Marketing Platform for the Internet</H1>
            </Col>
            <Col lg={20} md={10}>
              <p style={{ fontSize: "19px", fontWeight: 500, color: "#000" }}>
                Thousands of students and lots of companies of all
                sizes use Student Life 101’s software to create new deals,
                advertise promotions, and analyze their growth online.
              </p>
            </Col>
          </Row>
          <EmailBusinessCard />
        </Col>
        {/* which -> Tijuana flats
        cici -> culvers
        churhes -> hungry howies */}
        <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
          <Screenshots />
        </Col>
      </Row>
    </HomePageHero >
    <PartnerSection />
    <ProductInfoSection />
    <WhySection />
    <LandingFooter />
  </LandingWrapper >
);

const LandingWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  min-height: 100vh;
`;

const HomePageHero = styled.div`
  margin: 0 auto;
  max-width: 980px;
  height: 640px;
  padding: 80px 10px 0 10px;
  background-color: #ffffff;
`;

const GradientBackground = styled.div`
  transform: rotate(162deg) translateY(90%);
  position: absolute;
  overflow: hidden !important;
  min-width: 3200px;
  min-height: 800px;
  background: rgb(144, 218, 53);
  background: linear-gradient(
    90deg,
    rgba(144, 218, 53, 1) 0%,
    rgba(112, 218, 53, 1) 6%,
    rgba(23, 206, 192, 1) 99%
  );
`;

const H1 = styled.h1`
  font-weight: 700;
  font-size: 46px;
  line-height: 1.2em;
  opacity: 0.9;
`;

export default Landing;