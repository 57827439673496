import React, { useState } from "react";
import { Modal, Row, Col } from "antd";
import ShowCouponPage from "../../Coupons/ShowCoupon/ShowCouponPage";

export default function CouponDetailsModal(props) {
  const { modalAction, couponParams } = props;
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <span onClick={showModal}>{modalAction}</span>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
      >
        <Row
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "6px",
            marginBottom: "6px",
          }}
        >
          <Col
            span={24}
            align="center"
            style={{ fontSize: "22px", fontWeight: "600" }}
          >
            {couponParams.headline !== "" ||
            typeof couponParams.headline !== "undefined"
              ? couponParams.headline
              : "Coupon"}
          </Col>
          <Col span={24}>
            {/* <EditImageAdPage handleCancel={handleCancel} imageAd={imageAd} /> */}
            <ShowCouponPage
              couponParams={couponParams}
              handleCancel={handleCancel}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
