/**
 * ROUTES
 */

export const routes = {
  rootRoute: "/",
  landing: {
    index: "/landing",
  },
  pricing: {
    index: "/pricing",
  },
  auth: {
    signIn: "/sign-in",
    signUp: "/sign-up",
    forgotMyPassword: "/forgot-my-password",
  },
  onboarding: {
    index: "/onboarding",
    createAccount: "/onboarding/create-account"
  },
  dashboard: {
    home: "/dashboard",
    account: {
      index: "/dashboard/account",
    },
    business: {
      index: "/dashboard/business",
      edit: "/dashboard/business/edit",
    },
    coupons: {
      index: "/dashboard/coupons",
      new: "/dashboard/coupons/new",
      show: "/dashboard/coupon",
      edit: "/dashboard/coupon/edit",
    },
    ads: {
      index: "/dashboard/image-ads",
      new: "/dashboard/new/image-ad",
      edit: "/dashboard/edit/image-ad",
    },
    insights: {
      index: "/dashboard/analytics",
      show: "/dashboard/analytics",
    },
    subscriptions: {
      index: "/subscriptions",
      new: "/dashboard/subscription-plans",
      checkout: "/dashboard/checkout",
    }
  },
  admin: {
    index: "/admin",
    auth: {
      signUp: "/admin/sign-up",
      signIn: "/admin/sign-in",
    },
    dashboard: {
      index: "/admin/dashboard",
    },
    businesses: {
      index: "/admin/businesses",
      show: "/admin/business",
      new: "/admin/business/new",
      newStepOne: "/admin/business/new-step-one"
    },
    users: {
      index: "/admin/users",
      show: "/admin/user",
    },
    products: {
      index: "/admin/products"
    },
    tickets: {
      index: "/admin/tickets",
    },
    insights: {
      index: "/admin/insights",
      show: "/admin/insight"
    }
  },
  legal: {
    privacyPolicy: "/privacy-policy",
    termsAndConditions: "/terms-and-conditions",
  },
};
