import React, { useEffect, useState } from "react";
import { Divider, Tabs, Typography, Row, Col, Card } from "antd";
import { TagOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;
const DATE_FORMAT = "MM/DD/YYYY";

const TotalRedeemedContext = React.createContext();

export default function RedeemedCardsTotalStatsSection({ couponsList }) {
  const [activeList, setActiveList] = useState([]);
  const [inactiveList, setInactiveList] = useState([]);

  useEffect(() => {
    if (couponsList) {
      let tmpActiveList = [];
      let tmpInactiveList = [];
      const coupons = couponsList.filter((coupon) => coupon.type !== "image");

      coupons.forEach((coupon) => {
        const isActive = moment(coupon.expires.toDate()).isSameOrAfter();

        const updateObj = {
          headline: coupon.headline,
          redeemedCount: coupon.redeemed ? coupon.redeemed.length : 0,
          startDate: moment(coupon.startDate.toDate()).format(DATE_FORMAT),
          endDate: moment(coupon.expires.toDate()).format(DATE_FORMAT),
          active: moment(coupon.expires.toDate()).isBefore(),
        };

        isActive
          ? tmpActiveList.push(updateObj)
          : tmpInactiveList.push(updateObj);
      });

      tmpActiveList.sort((a, b) => b.redeemedCount - a.redeemedCount);
      tmpInactiveList.sort((a, b) => b.redeemedCount - a.redeemedCount);

      setActiveList(tmpActiveList);
      setInactiveList(tmpInactiveList);
    }
  }, [couponsList]);

  return (
    <TotalRedeemedContext.Provider value={{ activeList, inactiveList }}>
      <TotalRedeemedTabs />
    </TotalRedeemedContext.Provider>
  );
}

const TotalRedeemedTabs = () => (
  <TotalRedeemedContext.Consumer>
    {({ activeList, inactiveList }) => (
      <>
        <Header />
        <Tabs defaultActiveKey="1">
          <TabPane tab="Active" key="1">
            <Row gutter={[20, 20]}>
              {activeList.length === 0 ? (
                <Card>No data yet.</Card>
              ) : (
                <RedeemedList dataList={activeList} />
              )}
            </Row>
          </TabPane>
          <TabPane tab="Expired" key="2">
            <Row gutter={[20, 20]}>
              {inactiveList.length === 0 ? (
                <Card>No data yet.</Card>
              ) : (
                <RedeemedList dataList={inactiveList} />
              )}
            </Row>
          </TabPane>
        </Tabs>
      </>
    )}
  </TotalRedeemedContext.Consumer>
);

const Header = () => (
  <Row>
    <Col span={24}>
      <Title level={4}>
        <TagOutlined /> Total Coupons Redeemed
      </Title>
    </Col>
    <Col span={24}>
      <Paragraph>
        Displays aggregate redeemed for each coupon from start to end date.
      </Paragraph>
    </Col>
  </Row>
);

const RedeemedList = ({ dataList }) => (
  <>
    {dataList.map((item, idx) => (
      <Col key={idx} xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}>
        <Card className="sm-box-shadow round">
          <Row gutter={[10, 0]}>
            <Col>
              <span style={{ fontSize: "24px", fontWeight: 600 }}>
                {item.redeemedCount}
              </span>
            </Col>
            <Col>
              <span>redeemed</span>
            </Col>
          </Row>
          <Divider style={{ margin: "6px 0 6px 0" }} />
          {item.headline}
        </Card>
      </Col>
    ))}
  </>
);
