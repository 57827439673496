import React, { useState, useEffect } from 'react';
import { Row, Col, message, Upload, Spin, Form, Input, Button } from 'antd';
import { Loading3QuartersOutlined, PlusOutlined } from '@ant-design/icons';
import {
  useConstants,
  useFirebase,
  useGetCompanyData
} from '../../../hooks.js';
import { formLayout } from '../../../utility/formLayout';
import { useDispatch } from 'react-redux';
import { enableFetch } from '../../../redux/actions/componentLoaderActions.js';

const EditCompanyForm = (props) => {
  const { handleCancel } = props;
  const firebase = useFirebase();
  const { COLLECTIONS } = useConstants();
  const { companyData } = useGetCompanyData();
  const dispatch = useDispatch();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (companyData !== null) {
      setCompany(companyData);
    }
  }, [companyData, imageUrl, loading]);

  const updateCompany = (companyCollection, companyId, companyToUpdate) => {
    firebase
      .getDb()
      .doUpdateDoc(companyCollection, companyId, companyToUpdate)
      .then(() => {
        message.success(`Successfully updated ${companyToUpdate.name}.`);
      });
  };

  const onFinish = (values) => {
    // If there is no image (undefined), means we already have an image for the company.
    // Keep the same logo, but upload the company data.
    if (values.image === undefined) {
      console.log('values: ', values);
      delete values.image;
      const prevCompanyData = companyData.data;
      const companyToUpdate = { ...prevCompanyData, ...values };

      console.log('companyToUpdate: ', companyToUpdate);

      // Save coupon to database and send a message
      updateCompany(COLLECTIONS.COMPANIES, company.id, companyToUpdate);
      dispatch(enableFetch());
      handleCancel();
      // setLoading(false);
    } else {
      // Add the new image and the rest of company data.
      console.log('values: ', values);
      const { image } = values;
      const { file } = image;

      firebase.doUploadFile(file, 'logos').then((data) => {
        const { downloadURL } = data;
        const url = downloadURL; // create the property to add to coupon

        const logo = url;
        delete values.image; // we don't need the image property anymore
        const prevCompanyData = companyData.data;
        const companyToUpdate = { ...prevCompanyData, ...values, logo };

        // Save coupon to database and send a message
        updateCompany(COLLECTIONS.COMPANIES, company.id, companyToUpdate);
        dispatch(enableFetch());
        handleCancel();
        // setLoading(false);
      });
    }
  };

  const onFinishFailed = (e) => {
    console.log(e);
  };

  const UploadButton = () => (
    <>
      {loading ? (
        <Loading3QuartersOutlined />
      ) : (
        <>
          {typeof company.data.logo === 'undefined' ? (
            <PlusOutlined />
          ) : (
            <img src={company.data.logo} alt='logo' style={{ width: '100%' }} />
          )}
        </>
      )}
    </>
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleOnUploadChange = (info) => {
    console.log('info: ', info);
    // We don't want multiple images uploaded.
    let { file } = info;
    let newFileList = [file];
    info.fileList = newFileList;

    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done' || info.file.status === 'error') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setLoading(false);
      });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <>
      {company === null ? (
        <Row align='center'>
          <Spin indicator={<Loading3QuartersOutlined spin />} />
        </Row>
      ) : (
        <>
          <Form
            {...formLayout}
            name='basic'
            layout='horizontal'
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row>
              <Col span={18}>
                <div>
                  <h3>Upload a logo for your business</h3>
                </div>
                <div>
                  Image must be either a <b>JPEG</b> or <b>PNG</b>.
                </div>
                <div>
                  <em>
                    Image must smaller than <b>2MB</b>.
                  </em>
                </div>
              </Col>
              <Col span={6}>
                <Form.Item label='' name='image'>
                  <Upload
                    name='image'
                    listType='picture-card'
                    className='avatar-uploader'
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleOnUploadChange}
                    multiple={false}
                  >
                    {/* imageUrl is the new Image */}
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt='avatar'
                        style={{ width: '100%' }}
                      />
                    ) : (
                      <UploadButton />
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label='Business Name'
              name='name'
              initialValue={company.data.name}
              rules={[
                {
                  required: true,
                  message: 'Please add a business name.'
                }
              ]}
            >
              <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} />
            </Form.Item>

            <Form.Item
              label={<>Description (optional)</>}
              name='description'
              initialValue={company.data.description}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item>
              <Row gutter={10} justify='end'>
                <Col>
                  <Button
                    block
                    onClick={handleCancel}
                    className='sm-box-shadow'
                    shape='round'
                    // onClick={() =>
                    //   history.push(routes.dashboard.business.index)
                    // }
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    block
                    type='primary'
                    htmlType='submit'
                    className='sm-box-shadow'
                    shape='round'
                  >
                    Update Business
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
          {/* <br />
          <CompanyLocations company={company} /> */}
        </>
      )}
    </>
  );
};

export default EditCompanyForm;
