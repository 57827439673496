import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import papaJohns from "../../assets/images/testimonies/papaJohns.png";
import whichWich from "../../assets/images/testimonies/whichWich.png";
import cicisPizza from "../../assets/images/testimonies/cicisPizza.png";
import qdoba from "../../assets/images/testimonies/qdoba.png";
import jerseyMikes from "../../assets/images/testimonies/jerseyMikes.png";
import uptownCheapskate from "../../assets/images/testimonies/uptownCheapskate.png";
import churchsLogo from "../../assets/images/testimonies/churchsLogo.png";
import orangeTheoryFitness from "../../assets/images/testimonies/orangeTheoryFitness.png";

export default function PartnerSection() {
  return (
    <PartnerSectionWrapper>
      <PartnerSectionContainer>
        <Row lg={24} gutter={40}>
          <Col lg={6} md={12} sm={12} xs={12} align="center">
            <img
              src={papaJohns}
              alt="Papa Johns"
              style={{ height: 60, width: 140 }}
              draggable={false}
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} align="center">
            <img
              src={whichWich}
              alt="Which Wich"
              style={{ height: 50, width: 240 }}
              draggable={false}
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} align="center">
            <img
              src={cicisPizza}
              alt="Cici's Pizza"
              style={{ bottom: 0, height: 60, width: 120 }}
              draggable={false}
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} align="center">
            <img
              src={qdoba}
              alt="Qdoba Mexican Eats"
              style={{ bottom: 0, height: 40, width: 180 }}
              draggable={false}
            />
          </Col>
        </Row>
        <Row gutter={[30, 30]} style={{ marginTop: "40px" }}>
          <Col lg={6} md={12} sm={12} xs={12} align="center">
            <img
              src={jerseyMikes}
              alt="Jersey Mikes"
              style={{ height: 90, width: 140 }}
              draggable={false}
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} align="center">
            <img
              src={uptownCheapskate}
              alt="Uptown Cheapskate"
              style={{ height: 60, width: 240 }}
              draggable={false}
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} align="center">
            <img
              src={churchsLogo}
              alt="Churchs Logo"
              style={{ bottom: 0, height: 100, width: 120 }}
              draggable={false}
            />
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} align="center">
            <img
              src={orangeTheoryFitness}
              alt="Orange Theory Fitness"
              style={{ bottom: 0, height: 100, width: 180 }}
              draggable={false}
            />
          </Col>
        </Row>
      </PartnerSectionContainer>
    </PartnerSectionWrapper>
  );
}

const PartnerSectionWrapper = styled.div`
  background-color: #ffffff;
`;


const PartnerSectionContainer = styled.div`
  margin: 0 auto;
  max-width: 980px;
  padding: 80px 0 80px 0;
`