import React, { useEffect, useState } from "react";
import { StyledMenuItem } from "../../../StyledComponents/styledComponents";
import { Typography, Row, Col, Menu, Button, Dropdown } from "antd";
import {
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import {
  useFirebase,
  useRoutes,
  useWindowSize,
  useGetCurrentAdmin,
} from "../../../hooks";
import styled from "styled-components";
import { HeaderNavDrawer } from "./HeaderNavDrawer.js";

const { Title } = Typography;

export const HeaderNav = () => {
  const { routes } = useRoutes();
  const history = useHistory();
  const firebase = useFirebase();
  const size = useWindowSize();
  const { currentAdminData } = useGetCurrentAdmin();

  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (currentAdminData) {
      setCurrentAdmin(currentAdminData);
    }
  }, [currentAdminData]);

  const handleSignOut = () => {
    firebase.doSignOut().then(() => {
      history.push(routes.admin.auth.signIn);
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleRedirect = (routeName) => {
    if (routeName === "coupons") {
      history.push(routes.dashboard.coupons.index);
      setVisible(false);
    } else if (routeName === "ads") {
      history.push(routes.dashboard.ads.index);
      setVisible(false);
    } else if (routeName === "account") {
      history.push(routes.dashboard.account.index);
      setVisible(false);
    }
  };

  return (
    <>
      <HeaderNavDrawer
        onClose={onClose}
        visible={visible}
        setVisible={setVisible}
        handleRedirect={handleRedirect}
      />
      <DashboardHeader id="dashboardHeader">
        <Row id="dashboardNav">
          <Col span={12}>
            <Title level={4}>Student Life 101</Title>
          </Col>
          <Col span={12}>
            <Row justify="end">
              {size.width <= 800 ? (
                <Button
                  shape="round"
                  icon={<MenuOutlined />}
                  onClick={() => setVisible(!visible)}
                />
              ) : (
                <NonMobileRightNav
                  history={history}
                  routes={routes}
                  handleSignOut={handleSignOut}
                  currentAdmin={currentAdmin}
                />
              )}
            </Row>
          </Col>
        </Row>
      </DashboardHeader>
    </>
  );
};

const NonMobileRightNav = ({
  history,
  routes,
  handleSignOut,
  currentAdmin,
}) => (
  <Dropdown
    getPopupContainer={(trigger) => trigger.parentNode}
    overlay={accountMenuItems({
      currentAdmin,
      history,
      routes,
      handleSignOut,
    })}
    placement="bottomRight"
    arrow
  >
    <Button shape="round" id="userDropdownBtn" icon={<UserOutlined />}>
      {currentAdmin?.email}
    </Button>
  </Dropdown>
);

const accountMenuItems = ({ currentAdmin, history, routes, handleSignOut }) => (
  <StyledMenu>
    <StyledMenuItem>
      <b>{currentAdmin !== null ? currentAdmin.email : "loading"}</b>
      <p style={{ fontSize: "14px" }}>Admin</p>
    </StyledMenuItem>
    <StyledMenuItem onClick={() => history.push(routes.admin.dashboard.index)}>
      Account
    </StyledMenuItem>
    <StyledMenuItem danger onClick={() => handleSignOut()}>
      Sign Out
    </StyledMenuItem>
  </StyledMenu>
);

/**
 * Styled Components
 */
const DashboardHeader = styled.div`
  border-bottom: 1px solid #d6d6d6;
  background-color: #fff;
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 10px 20px;
`;

const StyledMenu = styled(Menu)`
  width: 240px;
  border-radius: 14px;

  svg {
    font-size: 20px;
  }
`;