import { componentLoaderInitState } from "../componentLoaderInitState.js";

export const componentLoaderReducer = (state = componentLoaderInitState, action) => {
  switch (action.type) {
    case "TRUE":
      return { loading: true };
    case "FALSE":
      return { loading: false };
    default:
      return state;
  }
};
