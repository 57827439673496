import React, { useState, useEffect } from "react";
import { useGetCoupons, useConstants, useFirebase } from "../../../hooks.js";
import { Tag, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CouponsListActions from "./CouponsListActions.js";
import ExpirationTags from "./ExpirationTags.js";
import { humanizeDate } from "../../../utility/dateUtility.js";
import { TableSpinner } from "../../../components/TableSpinner.js";
import SortableTable, {
  DragHandle,
} from "../../../components/DragDropSort/SortableTable";
import { useSelector } from "react-redux";

const CouponsList = () => {
  const { componentLoaderReducer } = useSelector((state) => state);
  const { couponsList, loading } = useGetCoupons();
  const firebase = useFirebase();
  const { DATES } = useConstants();
  const columns = useColumns(DATES);
  const [coupons, setCoupons] = useState(null);

  // Everytime this componenet renders, check if the hook fetched something back.
  useEffect(() => {
    let onlyCoupons = couponsList.filter(
      (coupon, idx) => coupon.type !== "image"
    );
    // Sort by index
    onlyCoupons.sort((a, b) => (a.index > b.index ? 1 : -1));
    // add index if not arleady pressent
    for (let i = 0; i < onlyCoupons.length; i++) {
      const coup = onlyCoupons[i];
      // Remove ref becuase router can't serialize it
      delete coup.redeemed;
      onlyCoupons[i] = { ...coup, index: i };
    }
    console.log(Date.now(), onlyCoupons)
    setCoupons(onlyCoupons);
  }, [couponsList, loading, componentLoaderReducer]);

  const updateFirestoreNewOrder = (newData) => {
    const batch = firebase.app.firestore().batch();
    newData.forEach((item, index) => {
      const { companyId, id } = item;
      const coupRef = firebase.app
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("coupons")
        .doc(id);
      batch.update(coupRef, { index });
    });
    batch
      .commit()
      .then(function () {
        // console.log("batch committed!");
        message.success("Order Updated!");
      })
      .catch(() => {
        message.error("Order Failed to Update.");
      });
  };

  if (loading) {
    return <LoadingOutlined />;
  }

  // Preliminary, render a loader while the hook fetches a return value.
  if (couponsList === null) {
    return <TableSpinner />;
  }

  // No list, returns a message.
  // if (couponsList.length === 0) {
  //   return (
  //     <Empty description="" style={{ paddingTop: "40px" }}>
  //       <Row align="center" gutter={[0, 20]}>
  //         <Col span={24} align="center">
  //           <b>Create a your first coupon</b>
  //         </Col>
  //         <Col span={24} align="center">
  //           <NewCouponModal
  //             modalAction={
  //               <Button
  //                 shape="round"
  //                 type="default"
  //                 icon={<PlusOutlined />}
  //                 className="box-shadow"
  //               >
  //                 Create a coupon
  //               </Button>
  //             }
  //           />
  //         </Col>
  //       </Row>
  //     </Empty>
  //   );
  // }

  // Render the list only if there are elements in the list.
  if (couponsList.length > 0) {
    if (couponsList.length > 1) {
      columns.unshift({
        title: "Order",
        dataIndex: "sort",
        width: 30,
        className: "drag-visible",
        render: () => <DragHandle />,
      });
    }

    return (
      <SortableTable
        columns={columns}
        data={coupons}
        updateFirestoreNewOrder={updateFirestoreNewOrder}
      />
    );
  }
};

export default CouponsList;

const useColumns = (DATES) => [
  // {
  //   title: "Order",
  //   dataIndex: "sort",
  //   width: 30,
  //   className: "drag-visible",
  //   render: () => <DragHandle />,
  // },
  {
    title: "Headline",
    dataIndex: "headline",
    className: "drag-visible",
    render: (headline) => <>{headline}</>,
  },
  {
    title: "Start Date",
    dataIndex: "startDate",
    render: (startDate, coupon) => (
      <>
        {typeof startDate === "undefined" ? (
          <>n/a</>
        ) : (
          <Tag>{humanizeDate(startDate)}</Tag>
        )}
      </>
    ),
  },
  {
    title: "Expires",
    dataIndex: "expires",
    render: (expires, coupon) => (
      <ExpirationTags expires={expires} startDate={coupon.startDate} />
    ),
  },
  {
    title: "",
    key: "actions",
    render: (actions, coupon) => <CouponsListActions coupon={coupon} />,
  },
];
