import React, { useEffect, useState } from "react";
import { Divider, Card, Row, Col, Typography } from "antd";
import { LoadingOutlined, EyeOutlined } from "@ant-design/icons";

const { Title } = Typography;

const CouponsImpressionsStats = (props) => {
  const { couponsList } = props;
  const [redeemables, setRedeemables] = useState(null);

  useEffect(() => {
    if (couponsList) {
      const redeemablesList = couponsList.filter(
        (coupon, idx) => coupon.type !== "image"
      );
      console.log("redeemables: ", redeemablesList);
      setRedeemables(redeemablesList);
    }
  }, [couponsList]);

  if (!redeemables) {
    return <LoadingOutlined />;
  }

  if (redeemables) {
    redeemables.sort((a, b) => b.impressionsCount - a.impressionsCount);

    return (
      <>
        <Row>
          <Col span={24}>
            <Title level={4}>
              <EyeOutlined /> Total Impressions Coupons
            </Title>
          </Col>
          <Col>
            <p>
              Displays aggregate impressions for each coupon from start to end
              date.
            </p>
          </Col>
        </Row>

        <Row gutter={[20, 20]}>
          {redeemables.map((coupon, idx) => (
            <Col key={idx} xxl={4} xl={6} lg={8} md={12} sm={12} xs={24}>
              <Card
                className="sm-box-shadow round"
                style={{ minHeight: "200px" }}
              >
                <span style={{ fontSize: "24px", fontWeight: 600 }}>
                  {coupon.impressionsCount}
                </span>
                <Divider />
                {coupon.headline}
              </Card>
            </Col>
          ))}
        </Row>
      </>
    );
  }
};

export default CouponsImpressionsStats;
