import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useRoutes } from "./hooks.js";
import { SignIn } from "./ClientApp/Auth/SignIn/SignIn.js";
import { SignUp } from "./ClientApp/Auth/SignUp/SignUp.js";
import DashboardPage from "./ClientApp/Dashboard/DashboardPage.js";
import NotFound from "./ClientApp/ErrorResultPages/NotFound.js";
import PrivacyPolicy from "./ClientApp/Legal/PrivacyPolicy";
import TermsAndConditions from "./ClientApp/Legal/TermsAndConditions";
import ForgotMyPasswordPage from "./ClientApp/Auth/ForgotMyPasswordPage.js";
import Landing from "./ClientApp/Landing/Landing.js";
import PricingPage from "./ClientApp/Pricing/PricingPage.js";
import AdminSignIn from "./Admin/Auth/SignIn/AdminSignIn.js";
import AdminSignUp from "./Admin/Auth/SignUp/AdminSignUp.js";
import AdminDashboard from "./Admin/Dashboard/AdminDashboard.js";
import Onboarding from "./ClientApp/Onboarding/Onboarding.js";
import CreateAccount from "./ClientApp/Onboarding/CreateAccount.js";
import "./App.less";

export default function App() {
  const { routes } = useRoutes();

  return (
    <Router>
      <AppContainer>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Route
          render={({ location }) => (
            <div>
              <Switch location={location}>
                <Route exact path={routes.rootRoute} component={Landing} />
                <Route path={routes.onboarding.createAccount} component={CreateAccount} />
                <Route path={routes.onboarding.index} component={Onboarding} />
                <Route path={routes.pricing.index} component={PricingPage} />
                <Route path={routes.auth.signIn} component={SignIn} />
                <Route path={routes.auth.signUp} component={SignUp} />
                <Route path={routes.auth.forgotMyPassword} component={ForgotMyPasswordPage} />
                <Route path={routes.dashboard.home} component={DashboardPage} />
                <Route path={routes.admin.index} component={AdminDashboard} />
                <Route path={routes.admin.auth.signIn} component={AdminSignIn} />
                <Route path={routes.admin.auth.signUp} component={AdminSignUp} />
                <Route path={routes.admin.dashboard.index} component={AdminDashboard} />
                <Route path={routes.legal.privacyPolicy} component={PrivacyPolicy} />
                <Route path={routes.legal.termsAndConditions} component={TermsAndConditions} />
                <Route render={() => <NotFound />} />
              </Switch>
            </div>
          )}
        />
      </AppContainer>
    </Router>
  );
}

const AppContainer = styled.div`
  background-color: #fff !important;
  min-height: 100vh !important;
`;
