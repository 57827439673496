import React, { useState } from "react";
import { Button, Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditBusinessForm from "./EditBusinessForm";

export default function EditBusinessModal(props) {
  const { currentBusiness } = props;
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <a onClick={showModal}>
        <Button shape="round" icon={<EditOutlined />} size="large">
          Edit
        </Button>
      </a>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Edit business"
      >
        <EditBusinessForm
          currentBusiness={currentBusiness}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
}
