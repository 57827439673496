import React, { useState } from "react";
import { message, Button, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks";
import { useHistory } from "react-router-dom";
import { routes } from "../../../constants/routes";

const DeleteBusinessModal = (props) => {
  const { currentBusiness } = props;
  const firebase = useFirebase();
  const history = useHistory();
  const [visible, setVisible] = useState(false);

  /**
   * Delete business doc and push back to business index.
   */
  const handleOk = () => {
    if (currentBusiness) {
      const { name } = currentBusiness;
      const doc = currentBusiness.id;
      firebase.getDb().deleteDocFromCollection("companies", doc, () => {
        message.success(`Successfully deleted ${name}.`);
        setVisible(false);
        history.push(routes.admin.businesses.index);
      });
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <Button
        icon={<DeleteOutlined />}
        type="default"
        shape="round"
        onClick={() => setVisible(true)}
      >
        Delete Business
      </Button>
      <Modal
        title="Confirm delete business"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Delete business"
        okButtonProps={{
          className: "sm-box-shadow",
          type: "danger",
          shape: "round",
        }}
        cancelButtonProps={{
          className: "sm-box-shadow",
          type: "default",
          shape: "round",
        }}
      >
        <p>
          This is a destructive command. Deleting this business will remove the
          business and all of its data.
        </p>
        <b>The following will also be deleted:</b>
        <ul>
          <li>Locations</li>
          <li>Image Ads</li>
          <li>Coupons</li>
        </ul>
      </Modal>
    </>
  );
};

export default DeleteBusinessModal;
