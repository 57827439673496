import React, { useState } from "react";
import useReactRouter from "use-react-router";
import { useDispatch } from "react-redux";
import { enableLoader } from "../../../redux/actions/fullPageLoaderActions.js";
import { Form, Input, Button } from "antd";
import { useFirebase, useRoutes } from "../../../hooks.js";
import SignInError from "./SignInError.js";
import ForgotMyPasswordLink from "../ForgotMyPasswordLink.js";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

export const SignInForm = () => {
  const dispatch = useDispatch();
  const { routes } = useRoutes();
  const firebase = useFirebase();
  const { history } = useReactRouter();
  const [errorExist, setErrorExists] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    const { email, password } = values;

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((data) => {
        if (data) {
          const { user } = data;
          const { uid } = user;
          console.log("user: ", user);

          firebase
            .getDb()
            .doFirestoreGetCurrentUser("companyUsers", uid)
            .then((data) => {
              if (data) {
                console.log("data: ", data);
                if (data.uid === uid) {
                  dispatch(enableLoader("Signing in..."));
                  history.push(routes.dashboard.home);
                } else {
                  firebase.doSignOut().then(() => {
                    history.push(routes.dashboard.home);
                  });
                }
              }
            });
        }
      })
      .catch((error) => {
        if (error) {
          const { message } = error;
          setErrorExists(true);
          setErrorMessage(message);
          setLoading(false);
        }
      });
    setLoading(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <SignInError value={{ errorExist, errorMessage }} />
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        scrollToFirstError={true}
        size="large"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Please input your email" }]}
        >
          <Input placeholder="jane@example.com" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            htmlType="submit"
            block
            className="sm-box-shadow"
            loading={loading}
          >
            Continue
          </Button>
        </Form.Item>
        <ForgotMyPasswordLink />
      </Form>
    </>
  );
};
