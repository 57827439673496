import React, { useState } from "react";
import { Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditSpotlightForm from "./EditSpotlightForm";

const EditSpotlightModal = ({ spotlight, currentBusiness }) => {
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div onClick={() => setVisible(true)}><EditOutlined /> Edit spotlight</div>
      <Modal
        title="Edit spotlight"
        visible={visible}
        footer={false}
        onCancel={handleCancel}
        handleCancel={handleCancel}
      >
        <EditSpotlightForm
          spotlight={spotlight}
          currentBusiness={currentBusiness}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
};

export default EditSpotlightModal;
