import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFirebase } from "../../hooks";
import { useHistory } from "react-router-dom";
import { routes } from "../../constants/routes";
import { Row, Col, Card } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function UserDetails(props) {
  const firebase = useFirebase();
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  // const [favoritesList, setFavoritesList] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { location } = props;
    const { state } = location;

    // Before we destructure anything from state.
    // If state is undefined, bounce back to the admin users index page.
    if (typeof state === "undefined") {
      history.push(routes.admin.users.index);
    }

    const { userId } = state;

    if (userId) {
      const refPath = "/users/" + userId;
      firebase
        .getDb()
        .doGetDocByRefPath(refPath)
        .then((data) => {
          if (data) {
            setCurrentUser(data.data);
          }
        });

      setLoading(false);
    }
  }, [firebase, loading, history, props]);

  if (currentUser === null) {
    return <LoadingOutlined />
  } else {
    return (
      <Wrapper>
        <Container>
          <Row gutter={[30, 30]}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <UserDetailsTitle>User Details</UserDetailsTitle>
            </Col>
          </Row>
          <Card>
            <UserDetailsBody gutter={[30, 30]}>
              <Col lg={24} md={24} sm={24} xs={24}>
                <Row gutter={[10, 10]}>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    {currentUser.uid}
                  </Col>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    {currentUser.name}
                  </Col>
                </Row>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                Favorite Coupons
              </Col>
              <Col lg={24} md={24} sm={24} xs={24}>
                Redeemed Coupons
                <ul>
                  <li>Item</li>
                  <li>Item</li>
                  <li>Item</li>
                </ul>
              </Col>
            </UserDetailsBody>
          </Card>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  margin: 0 auto;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 980px;
`;

const UserDetailsTitle = styled.div`
  width: 100%;
  font-size: 26px;
  font-weight: 600;
  border-bottom: 1px solid #d3d3d3;
`;

const UserDetailsBody = styled(Row)``;
