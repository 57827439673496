import React, { useEffect } from "react";
import { Tag, Button, Card, Row, Col } from "antd";
import { ArrowsAltOutlined, ShrinkOutlined } from "@ant-design/icons";
import { Chart } from "@antv/g2";
import Helpers from "../../Admin/Insights/utility/Helpers";

const RedeemedOvertimeLineChart = (props) => {
  const { coupon, colSize, maximizeChart } = props;
  const { key, redeemed, headline } = coupon;

  useEffect(() => {
    if (coupon) {
      const chart = new Chart({
        container: `container-${key}`,
        autoFit: true,
        height: 200,
      });

      chart.data(redeemed);

      // Increase the max value by a bit so the max label value doesn't get cut off.
      let max = Helpers.getMaxValue(redeemed);
      chart.scale({
        date: {
          range: [0, 1],
        },
        value: {
          min: 0,
          max: max + 2,
          nice: true,
        },
      });

      chart.annotation().line({
        start: ["min", max],
        end: ["max", max],
        style: {
          stroke: "#595959",
          lineWidth: 1,
          lineDash: [3, 3],
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.animate(true);
      chart.line().position("date*value").shape("smooth");

      if (colSize === 24) {
        chart.axis("date", true);
        chart.point().position("date*value");
      } else {
        chart.axis("date", false);
        chart.point().position("date*value").shape("smooth");
      }

      chart.render();

      // Cleans up previous renders of the graph if new graphs are rendered by changing date filter
      Helpers.cleanCanvas(`container-${key}`);
    }
  }, [coupon, colSize, maximizeChart]);

  return (
    <Card className="sm-box-shadow round">
      <Row>
        <Col span={22}>
          <h4>{headline}</h4>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Button
            onClick={() => maximizeChart()}
            type="ghost"
            size="small"
            shape="round"
            icon={colSize === 8 ? <ArrowsAltOutlined /> : <ShrinkOutlined />}
          ></Button>
        </Col>
      </Row>

      <Row>
        <Col>
          <Tag color="green">Start date: {coupon.startDate}</Tag>
        </Col>
        <Col>
          <Tag color="red">End date: {coupon.expires}</Tag>
        </Col>
      </Row>

      <br />
      <div id={`container-${key}`}></div>
    </Card>
  );
};

export default RedeemedOvertimeLineChart;
