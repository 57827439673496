import React, { useState, useEffect } from "react";
import { Modal } from "antd";
// import { useFirebase } from "../../../hooks.js";
// import { enableFetch } from "../../../redux/actions/componentLoaderActions";
// import { useDispatch } from "react-redux";
import NewImageAdForm from "./NewImageAdForm.js";

const NewImageAdModal = (props) => {
  const { currentBusiness, modalAction } = props;
  // const firebase = useFirebase();
  // const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const handleCancel = () => {
    setVisible(false);
  };

  // const onFinish = (value) => {
  //   if (currentBusiness) {
  //     const { id, data } = currentBusiness;
  //     console.log(id, data);
  //     console.log(value);
  //     let businessToUpdate = data;
  //     businessToUpdate.locations.push({ ...value });
  //     console.log("business to update: ", businessToUpdate);
  //     firebase
  //       .getDb()
  //       .doUpdateDoc("companies", id, businessToUpdate)
  //       .then(() => {
  //         dispatch(enableFetch());
  //         handleCancel();
  //         message.success(`Successfully added ${value.label}`);
  //       });
  //   }
  // };

  // const onFinishFailed = () => {};

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <a onClick={showModal}>{modalAction}</a>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
        title="Create a new image ad"
      >
        <NewImageAdForm
          currentBusiness={currentBusiness}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
};

export default NewImageAdModal;
