import React, { useEffect, useState } from "react";
import { Button, message, Tabs, Row, Col, Card, Empty } from "antd";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useFirebase, useAdminGetCoupons, useRoutes } from "../../../hooks";
import { useHistory } from "react-router-dom";
import { isExpired, humanizeDate } from "../../../utility/dateUtility.js";
import SortableGrid, {
  SortableItem,
} from "../../../components/DragDropSort/SortableGrid";
import arrayMove from "array-move";
import { useSelector } from "react-redux";
import NewImageAdModal from "./NewImageAdModal";
import EditImageAdModal from "./EditImageAdModal";
import DeleteImageAdModal from "./DeleteImageAdModal";

const { TabPane } = Tabs;

const callback = (key) => {
  console.log(key);
};

/**
 * Root page to display Image Ads.
 */
const ImageAdsList = (props) => {
  const { currentBusiness } = props;
  const { couponsList, loading } = useAdminGetCoupons(currentBusiness);
  const history = useHistory();
  const { routes } = useRoutes();
  const firebase = useFirebase();
  const { componentLoaderReducer } = useSelector((state) => state);

  // States
  const [imageAdsList, setImageAdsList] = useState(null);
  const [expiredImageAdsList, setExpiredImageAdsList] = useState([]);
  const [currentAd, setCurrentAd] = useState(null);
  const [visible, setVisible] = useState(false);

  // Everytime this componenet renders, check if the hook fetched something back.
  useEffect(() => {
    let onlyImageAds = couponsList.filter(
      (coupon, idx) => coupon.type === "image" && isExpired(coupon) === false
    );
    let onlyExpiredImageAds = couponsList.filter(
      (coupon, idx) => coupon.type === "image" && isExpired(coupon)
    );
    // Sort by index
    onlyImageAds.sort((a, b) => (a.index > b.index ? 1 : -1));
    onlyExpiredImageAds.sort((a, b) => (a.index > b.index ? 1 : -1));
    // console.log(onlyExpiredImageAds);
    // add index if not arleady pressent
    for (let i = 0; i < onlyImageAds.length; i++) {
      onlyImageAds[i] = { ...onlyImageAds[i], index: i };
    }
    for (let i = 0; i < onlyExpiredImageAds.length; i++) {
      onlyExpiredImageAds[i] = { ...onlyExpiredImageAds[i], index: i };
    }
    // setImageAdsList([]); // Test if empty image ads
    // setExpiredImageAdsList([]); // Test if empty expired image ads
    setImageAdsList(onlyImageAds);
    setExpiredImageAdsList(onlyExpiredImageAds);
  }, [couponsList, componentLoaderReducer]);

  // const handleRedirectToEdit = (imageAdToEdit) => {
  //   // Convert the dates before we pass it off to edit image component.
  //   const startDate = imageAdToEdit.startDate.toDate();
  //   const expires = imageAdToEdit.expires.toDate();

  //   let ad = { ...imageAdToEdit, startDate, expires };

  //   history.push({
  //     pathname: routes.dashboard.ads.edit,
  //     state: { ad },
  //   });
  // };

  const handleOnClickDeleteModal = (ad) => {
    setCurrentAd(ad);
    setVisible(true);
  };

  const updateFirestoreNewOrder = (newData) => {
    console.log("new data: ", newData);
    const batch = firebase.app.firestore().batch();
    newData.forEach((item, index) => {
      const { companyId, id } = item;
      const coupRef = firebase.app
        .firestore()
        .collection("companies")
        .doc(currentBusiness.id)
        .collection("coupons")
        .doc(id);
      batch.update(coupRef, { index });
    });
    batch
      .commit()
      .then(() => {
        console.log("batch committed!");
        message.success("Order Updated!");
      })
      .catch((error) => {
        console.log(error);
        message.error("Order Failed to Update.");
      });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMove(
        [].concat(imageAdsList),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setImageAdsList(newData);
      updateFirestoreNewOrder(newData);
    }
  };

  /**
   * Render single image ad.
   */
  const RenderImageAd = ({ index, imageAd, status }) => {
    return (
      <SortableItem index={index}>
        <Col span={8}>
          <Card
            className="round"
            hoverable
            actions={[
              <EditImageAdModal
                currentBusiness={currentBusiness}
                modalAction={<EditOutlined key="edit" />}
                imageAd={imageAd}
              />,
              <DeleteOutlined
                key="delete"
                onClick={() => handleOnClickDeleteModal(imageAd)}
              />,
            ]}
          >
            <Row gutter={[10, 0]}>
              <Col span={24}>
                <img
                  className="round"
                  src={imageAd.url}
                  alt="ad-logo"
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                />
              </Col>
              <Col span={24}>
                <Row gutter={[0, 4]}>
                  <Col span={24}>
                    <b>Aspect Ratio: </b>
                    {imageAd.aspectRatio !== undefined
                      ? imageAd.aspectRatio
                      : ""}
                  </Col>
                  <Col span={24}>
                    <b>Start Date: </b>
                    <br />
                    {imageAd.startDate !== undefined
                      ? humanizeDate(imageAd.startDate)
                      : ""}
                  </Col>
                  <Col span={24}>
                    <b>Expiration Date: </b>
                    <br />
                    {imageAd.expires !== undefined
                      ? humanizeDate(imageAd.expires)
                      : ""}
                  </Col>
                  <Col span={24}>
                    <b>Link: </b>
                    <br />
                    {imageAd.link!== undefined
                      ? imageAd.link
                      : ""}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </SortableItem>
    );
  };

  /**
   * Render active image ads.
   */
  const RenderImageAds = () => {
    return (
      <SortableGrid onSortEnd={onSortEnd}>
        <Row span={24} gutter={[12, 48]}>
          {imageAdsList !== null || typeof imageAdsList !== "undefined" ? (
            imageAdsList.map((imageAd, idx) => (
              <RenderImageAd
                index={idx}
                imageAd={imageAd}
                status="Active"
                key={`imageAdsList-${idx}`}
              />
            ))
          ) : (
            <p>There are no Image Ads yet.</p>
          )}
        </Row>
      </SortableGrid>
    );
  };

  /**
   * Render expired image ads.
   */
  const RenderExpiredImageAds = () => {
    return (
      <SortableGrid onSortEnd={onSortEnd}>
        <Row span={24} gutter={[12, 48]}>
          {expiredImageAdsList !== null ||
          typeof expiredImageAdsList !== "undefined" ? (
            expiredImageAdsList.map((imageAd, idx) => (
              <RenderImageAd
                key={idx}
                imageAd={imageAd}
                status="Expired"
                key={`expiredImageAdsList-${idx}`}
              />
            ))
          ) : (
            <p>There are no Image Ads yet.</p>
          )}
        </Row>
      </SortableGrid>
    );
  };

  /**
   * Render a loader while the hook fetches a return value.
   */
  if (loading || imageAdsList === null) {
    return <LoadingOutlined />;
  }

  /**
   * Hook returned an empty list.
   */
  if (imageAdsList.length === 0 || expiredImageAdsList.length === 0) {
    return (
      <>
        <DeleteImageAdModal
          currentBusiness={currentBusiness}
          currentAd={currentAd}
          visible={visible}
          setVisible={setVisible}
        />
        <Tabs type="line" defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Active" key="1">
            {/* Render active image ads. */}
            {imageAdsList.length === 0 ? (
              <Empty description="" style={{ padding: "40px 0 40px 0" }}>
                <Row gutter={[0, 20]}>
                  <Col span={24} align="center">
                    <b>Create your first Ad</b>
                  </Col>
                  <Col span={24} align="center">
                    <NewImageAdModal
                      currentBusiness={currentBusiness}
                      modalAction={
                        <Button
                          shape="round"
                          icon={<PlusOutlined />}
                          className="sm-box-shadow"
                        >
                          Create an Ad
                        </Button>
                      }
                    />
                  </Col>
                </Row>
              </Empty>
            ) : (
              <RenderImageAds />
            )}
          </TabPane>
          <TabPane tab="Expired" key="2">
            {/* Render expired image ads. */}
            {expiredImageAdsList.length === 0 ? (
              <Empty description="" style={{ padding: "40px 0 40px 0" }}>
                <Row gutter={[0, 20]}>
                  <Col span={24} align="center">
                    <b>Create your first Ad</b>
                  </Col>
                  <Col span={24} align="center">
                    <NewImageAdModal
                      currentBusiness={currentBusiness}
                      modalAction={
                        <Button
                          shape="round"
                          icon={<PlusOutlined />}
                          className="box-shadow"
                        >
                          Create an Ad
                        </Button>
                      }
                    />
                  </Col>
                </Row>
              </Empty>
            ) : (
              <RenderExpiredImageAds />
            )}
          </TabPane>
        </Tabs>
      </>
    );
  }

  /**
   * Hook returned a non-empty list.
   */
  if (imageAdsList.length > 0) {
    return (
      <>
        <DeleteImageAdModal
          currentBusiness={currentBusiness}
          currentAd={currentAd}
          visible={visible}
          setVisible={setVisible}
        />
        <Tabs type="line" defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Active" key="1">
            {/* Render active image ads. */}
            {imageAdsList.length === 0 ? (
              <LoadingOutlined />
            ) : (
              <RenderImageAds />
            )}
          </TabPane>
          <TabPane tab="Expired" key="2">
            {/* Render expired image ads. */}
            <RenderExpiredImageAds />
          </TabPane>
        </Tabs>
      </>
    );
  }
};

export default ImageAdsList;
