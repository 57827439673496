import React from "react";
import { Row, Col } from "antd";
import BusinessProfile from "./BusinessProfile.js";
import BusinessTabs from "./BusinessTabs.js";
import { Wrapper, Container } from "../../../StyledComponents/styledComponents";

const Dashboard = () => (
  <Wrapper>
    <Container>
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <BusinessProfile />
        </Col>
        <Col span={24}>
          <BusinessTabs />
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

export default Dashboard;
