import React from "react";
import { Wrapper, Container } from "../../StyledComponents/styledComponents";
import { Card, Button, Typography, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ImageAdsList from "./ImageAdsList.js";
import NewAdModal from "./components/NewAdModal";

const { Title } = Typography;

const ImageAdsPage = () => (
  <Wrapper>
    <Container>
      <Row span={24}>
        <Col span={12}>
          <Title level={3}>Image Ads</Title>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <NewAdModal
            modalAction={
              <Button
                className="sm-box-shadow"
                shape="round"
                id="createDropdownBtn"
                type="default"
                icon={<PlusOutlined />}
                style={{ marginLeft: "10px" }}
              >
                New
              </Button>
            }
          />
        </Col>
      </Row>
      <Card className="sm-box-shadow round">
        <ImageAdsList />
      </Card>
    </Container>
  </Wrapper>
);

export default ImageAdsPage;
