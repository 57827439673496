import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Card, Divider, Row, Col } from "antd";
import SignUpForm from "./SignUpForm.js";
import SignUpNav from "./SignUpNav.js";
import { routes } from "../../../constants/routes.js";
import { useWindowSize } from "../../../hooks.js";
import SignInNav from "../SignIn/SignInNav.js";
import { AppFooter } from "../../../components/Footer/AppFooter.js";

export const SignUp = () => {
  const windowSize = useWindowSize();
  const { width } = windowSize;

  return (
    <>
      {width < 992 ? (
        <Wrapper>
          <Container>
            <Row gutter={[0, 30]}>
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ textAlign: "center" }}
              >
                <SignInNav />
              </Col>
              <Col
                xl={24}
                lg={24}
                md={24}
                sm={24}
                xs={24}
                style={{ minHeight: "600px" }}
              >
                <Card>
                  <SignInTitle>Sign in to your account</SignInTitle>
                  <SignUpForm />
                </Card>
                <br />
                <div style={{ textAlign: "center" }}>
                  Don't have an account?{" "}
                  <Link to={routes.auth.signIn}>Sign in</Link>
                </div>
              </Col>
            </Row>
          </Container>
          <AppFooter />
        </Wrapper>
      ) : (
        <SignUpWrapper>
          <Row lg={24}>
            <Col
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ borderRight: "1px solid #eee", paddingTop: "60px" }}
            >
              <SignUpNav />
            </Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 24 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ backgroundColor: "#fff", paddingTop: "60px" }}
            >
              <SignUpContainer>
                <SignUpTitle>Create your account</SignUpTitle>
                <SignUpForm />
                <br />
                <Row align="center">
                  <p>
                    Have an account?{" "}
                    <Link to={routes.auth.signIn}>Sign in</Link>
                  </p>
                </Row>
                <br />
                <Row align="center" gutter={4}>
                  <Col>
                    <Link to={routes.legal.termsAndConditions} target="_blank">
                      Terms
                    </Link>
                  </Col>
                  <Col>&amp;</Col>
                  <Col>
                    <Link to={routes.legal.privacyPolicy} target="_blank">
                      Privacy
                    </Link>
                  </Col>
                </Row>
              </SignUpContainer>
            </Col>
          </Row>
          <SignOutDivider />
        </SignUpWrapper>
      )}
    </>
  );
};

/**
 * Styled Components
 */

const SignUpWrapper = styled.div`
  background-color: #f6f7f9 !important;
  min-height: 100vh !important;
`;

const SignUpContainer = styled.div`
  min-height: 100vh;
  padding: 0 0 0 90px;
  width: 520px;
`;

const SignUpTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
`;

const SignOutDivider = styled(Divider)`
  border-color: #e3e3e3;
  padding: 0;
  margin: 0;
`;

const Wrapper = styled.div``;

const Container = styled.div`
  margin: 0 auto;
  max-width: 480px;
  padding: 80px 10px 0 10px;
`;

const SignInTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
`;
