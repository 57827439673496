import React from "react";
import { PageHeader } from "antd";

export default function AppPageHeader() {
  return (
    <PageHeader
      ghost={false}
      subTitle="Back"
      onBack={() => window.history.back()}
    />
  );
}
