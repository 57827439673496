import React from "react";
import { Alert } from "antd";

const SignUpError = ({ value }) => {
  const { errorExist, errorMessage } = value;
  const onClose = (e) => {};

  return (
    <>
      {errorExist ? (
        <Alert
          message="Error:"
          description={errorMessage}
          type="error"
          closable
          onClose={onClose}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SignUpError;