import React from "react";
import styled from "styled-components";
import mobileScreenshot from "../../assets/images/mobileScreenshot.jpeg";
import desktopScreenshot from "../../assets/images/desktopScreenshot.png";

export default function Screenshots() {
  return (
    <div style={{ width: "100px" }}>
      <MobileScreenshot />
    </div>
  );
}

const MobileScreenshot = () => {
  return (
    <>
      <MobileImg
        src={mobileScreenshot}
        alt="mobile coupons list"
        draggable={false}
      />
      <DesktopScreenshot
        src={desktopScreenshot}
        alt="mobile coupons list"
        draggable={false}
      />
    </>
  );
};

const DesktopScreenshot = () => {
  return <DesktopImg src={desktopScreenshot} alt="desktop screenshot" />;
};

const MobileImg = styled.img`
  margin: 40px 0 0 0;
  height: 464px;
  width: 264px;
  border-radius: 20px;
  z-index: 2;
  position: absolute;
  background-color: #f6f9fc;
  padding: 12px;
  box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35) !important;
`;

const DesktopImg = styled.img`
  margin-left: 60px;
  width: 900px;
  background-color: #f6f9fc;
  border-radius: 20px;
  z-index: 1;
  padding: 12px;
  box-shadow: 0 50px 100px -20px rgba(50,50,93,.25), 0 30px 60px -30px rgba(0,0,0,.3), inset 0 -2px 6px 0 rgba(10,37,64,.35) !important;
`;
