import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { Alert, Form, Input, Button } from "antd";
import { useFirebase, useRoutes } from "../../../hooks";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

export const SignInForm = () => {
  const { routes } = useRoutes();
  const firebase = useFirebase();
  const { history } = useReactRouter();
  const [processing, setProcessing] = useState(false);
  const [errorExist, setErrorExists] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => { }, [processing]);

  /**
   *
   * @param {Object} values
   * Signs in the admin user
   */
  const onFinish = (values) => {
    setProcessing(true);
    const { email, password } = values;

    firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((data) => {
        if (data) {
          const { user } = data;
          const { uid } = user;
          // Check if the auth user is an admin
          // If admin, push to admin dashboard
          // If not, sign out and push to portal sign in page
          firebase
            .getDb()
            .doFirestoreGetCurrentUser("admins", uid)
            .then((data) => {
              if (data) {
                const { isAdmin } = data;
                if (isAdmin) {
                  history.push(routes.admin.dashboard.index);
                } else {
                  firebase.doSignOut().then(() => {
                    history.push(routes.auth.signIn);
                  });
                }
              }
            });
        }
      })
      .catch((error) => {
        if (error) {
          const { message } = error;
          setErrorExists(true);
          setErrorMessage(message);
        }
      });
    setProcessing(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  /**
   *
   * @param {Event} e
   * Closes error alert
   */
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };

  /**
   * Displays error message
   */
  const ShowErrorMessage = () => (
    <>
      {errorExist ? (
        <Alert
          message="Error"
          description={errorMessage}
          type="error"
          closable
          onClose={onClose}
        />
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <ShowErrorMessage />
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        scrollToFirstError={true}
        size="large"
      >
        <Form.Item
          label={<b>Email</b>}
          name="email"
          rules={[{ required: true, message: "Please input your username" }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item
          label={<b>Password</b>}
          name="password"
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>

        <br />

        <Form.Item {...tailLayout}>
          <Button
            loading={processing}
            type="primary"
            htmlType="submit"
            block
            className="sm-box-shadow"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </>
  );
}
