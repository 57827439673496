import React from "react";
import { Wrapper, Container } from "../../StyledComponents/styledComponents";
import { Card, Button, Typography, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// import { useSelector } from "react-redux";
import CouponsList from "./CouponsList/CouponsList.js";
import NewCouponModal from "./components/NewCouponModal";

const { Title } = Typography;

const CouponsPage = () => {
  // const { componentLoaderReducer } = useSelector((state) => state);
  // const { loading } = componentLoaderReducer;
  // const [toggleCalendar, setToggleCalendar] = useState(false);

  // useEffect(() => {}, [loading, toggleCalendar]);

  return (
    <Wrapper>
      <Container>
        <Row span={24}>
          <Col span={12}>
            <Title level={3}>Coupons</Title>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <NewCouponModal
              modalAction={
                <Button
                  className="sm-box-shadow"
                  shape="round"
                  id="createDropdownBtn"
                  type="default"
                  icon={<PlusOutlined />}
                  style={{ marginLeft: "10px" }}
                >
                  New
                </Button>
              }
            />
          </Col>
        </Row>

        <Card className="sm-box-shadow round">
          <CouponsList />
        </Card>

        {/* {toggleCalendar ? <CalendarComponent /> : <CouponsList />} */}
      </Container>
    </Wrapper>
  );
};

export default CouponsPage;
