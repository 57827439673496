import React, { useState, useEffect } from "react";
import { Table, Row, Col, Button, Empty } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import NewLocationModal from "./components/NewLocationModal";
import LocationsTableActions from "./components/LocationsTableActions";

export default function LocationsPage({ currentBusiness }) {
  const { locations } = currentBusiness;
  const { componentLoaderReducer } = useSelector((state) => state);
  const [currentLocations, setCurrentLocations] = useState(locations);

  console.log("currentLocations: ", currentLocations);

  useEffect(() => {
    if (currentBusiness) {
      const { locations } = currentBusiness;
      setCurrentLocations(locations);
    }
  }, [currentBusiness, componentLoaderReducer.loading]);

  const columns = [
    {
      title: "Label",
      dataIndex: "label",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "index",
      render: (actions, location, index) => (
        <LocationsTableActions
          location={location}
          currentBusiness={currentBusiness}
          idx={index}
        />
      ),
    },
  ];

  if (!currentLocations) {
    return (
      <Empty description="" style={{ marginTop: "40px" }}>
        <Row align="center" gutter={[0, 20]}>
          <Col span={24} align="center" style={{ fontWeight: "600" }}>
            Create a location so students can find your stores.{" "}
          </Col>
          <Col span={24} align="center">
            <NewLocationModal
              currentBusiness={currentLocations}
              modalAction={
                <Button
                  shape="round"
                  type="default"
                  icon={<PlusOutlined />}
                  className="sm-box-shadow"
                >
                  Create a location
                </Button>
              }
            />
          </Col>
        </Row>
      </Empty>
    );
  }

  if (currentLocations.length === 0) {
    return (
      <Empty description="" style={{ marginTop: "40px" }}>
        <Row align="center" gutter={[0, 20]}>
          <Col span={24} align="center" style={{ fontWeight: "600" }}>
            Create a location so students can find your stores.{" "}
          </Col>
          <Col span={24} align="center">
            <NewLocationModal
              currentBusiness={currentBusiness}
              modalAction={
                <Button
                  shape="round"
                  type="default"
                  icon={<PlusOutlined />}
                  className="sm-box-shadow"
                >
                  Create a location
                </Button>
              }
            />
          </Col>
        </Row>
      </Empty>
    );
  }

  if (currentLocations) {
    return (
      <Table size="large" dataSource={currentLocations} columns={columns} />
    );
  }
}
