const collapseInitState = { collapsed: false };

export const sidebarCollapseReducer = (state = collapseInitState, action) => {
  switch (action.type) {
    case "SHOW":
      return { collapsed: true };
    case "HIDE":
      return { collapsed: false };
    default:
      return state;
  }
};
