import { useState, createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "./constants/routes.js";
import * as CONSTANTS from "./constants/constants.js";
import * as COLLECTIONS from "./constants/collections.js";
import * as DATES from "./constants/dateFormat.js";
import { calculateDaysActive } from "./utility/dateUtility.js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

export const FirebaseContext = createContext(null);

export const useFirebase = () => {
  return useContext(FirebaseContext);
};

export const useConstants = () => {
  return { COLLECTIONS, DATES };
};

/**
 * Routes
 */
export const useRoutes = () => {
  return { routes };
};

/**
 * Get All Users
 */
export const useGetAllUsers = () => {
  const firebase = useFirebase();
  const [usersData, setUsersData] = useState(null);

  useEffect(() => {
    firebase
      .getDb()
      .doFirestoreGetCollection("users")
      .then((data) => {
        if (data) {
          setUsersData(data);
        }
      });
  }, [firebase]);

  return { usersData };
};

/**
 * Gets current admin user
 */
export const useGetCurrentAdmin = () => {
  const firebase = useFirebase();
  const history = useHistory();
  const [currentAdmin, setCurrentAdmin] = useState(null);
  const [currentAdminData, setCurrentAdminData] = useState(null);
  const [authState, setAuthState] = useState(CONSTANTS.USER_STATES.LOADING);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = firebase.getAuth().onAuthStateChanged((user) => {
      if (user) {
        setAuthState(CONSTANTS.USER_STATES.SIGNED_IN);
        const { uid } = user;
        setCurrentAdmin(user);
        firebase
          .getDb()
          .doFirestoreGetCurrentUser("admins", uid)
          .then((data) => {
            if (_.isEmpty(data)) {
              // if no document is found in the admins collection, firebase returns {}
              // then this user is not an admin.
              firebase.doSignOut().then(() => {
                history.push(routes.admin.auth.signIn);
              });
            } else if (data) {
              if (data) {
                setCurrentAdminData(data);
              }
            }
          });
      } else {
        setAuthState(CONSTANTS.USER_STATES.SIGNED_OUT);
      }
    });

    // Unsubscribe to the listener when unmounting.
    return () => unsubscribe();
  }, [firebase]);

  return { currentAdmin, currentAdminData, authState };
};

/**
 * Gets the current auth user and the auth user's data from firestore.
 *
 * Since onAuthStateChange sets an observer on the Auth object, so it will
 * dispatch the sign in state to true or false.
 *
 * If the user exists, dispatch and persist the sign in state to true
 * else, dispatch and persist the sign in state to false
 *
 */

export const useGetCurrentUser = () => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [authState, setAuthState] = useState(CONSTANTS.USER_STATES.LOADING);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = firebase.getAuth().onAuthStateChanged((user) => {
      if (user) {
        setAuthState(CONSTANTS.USER_STATES.SIGNED_IN);
        const { uid } = user;
        setCurrentUser(user);
        firebase
          .getDb()
          .doFirestoreGetCurrentUser(COLLECTIONS.COMPANY_USERS, uid)
          .then((data) => {
            if (_.isEmpty(data)) {
              // if no document is found in the admins collection, firebase returns {}
              // then this user is not an admin.
              firebase.doSignOut().then(() => {
                history.push(routes.dashboard.home);
              });
            } else if (data) {
              if (data) {
                setCurrentUserData(data);
              }
            }
          });
      } else {
        setAuthState(CONSTANTS.USER_STATES.SIGNED_OUT);
        // dispatch(signedOut());
      }
    });

    // Unsubscribe to the listener when unmounting.
    return () => unsubscribe();
  }, [firebase, dispatch]);

  return { currentUser, currentUserData, authState };
};

/**
 * Retrieves company's document.
 */
export const useGetCompanyData = () => {
  const firebase = useFirebase();
  const { componentLoaderReducer } = useSelector((state) => state);
  const { currentUserData } = useGetCurrentUser();
  const [companyData, setCompanyData] = useState(null);

  useEffect(() => {
    // FIX ERROR: (when no internet) FirebaseError: Invalid document reference.
    // Document references must have an even number of segments, but undefined
    // has 1
    if (currentUserData !== null) {
      const { companyRefPath } = currentUserData;
      firebase
        .getDb()
        .doGetDocByRefPath(companyRefPath + "/")
        .then((doc) => {
          if (doc) {
            setCompanyData(doc);
          }
        });
    }

    // componentLoaderReducer (redux) state listens for any state
    // from any action dispatched by the user to update the company data.
  }, [currentUserData, firebase, componentLoaderReducer]);

  return { companyData };
};

/**
 * Gets the company's coupon list.
 */
export const useGetCoupons = () => {
  const firebase = useFirebase();
  const { componentLoaderReducer } = useSelector((state) => state);
  const { COLLECTIONS } = useConstants();
  const { currentUser, currentUserData } = useGetCurrentUser();
  const [couponsList, setCouponsList] = useState([]);
  const [couponsPath, setCouponsPath] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentUser !== null && currentUserData !== null) {
      const { companyRefPath } = currentUserData;
      setCouponsPath(companyRefPath + "/" + COLLECTIONS.COUPONS);
      let couponsListData = [];
      firebase
        .getDb()
        .doGetSubCollectionFromDoc(companyRefPath, COLLECTIONS.COUPONS)
        .then((couponsList) => {
          couponsList.forEach((coupon) => {
            couponsListData.push({ ...coupon, key: coupon.id });
          });
          setCouponsList([...couponsListData]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [
    currentUser,
    currentUserData,
    componentLoaderReducer,
    firebase,
    COLLECTIONS,
  ]);

  return { couponsList, couponsPath, loading };
};

/**
 *
 * @param {Object} currentBusiness
 * Admin hook relies on the current business to be passed on from usually a businesses index page.
 */
export const useAdminGetCoupons = (currentBusiness) => {
  const firebase = useFirebase();
  const { componentLoaderReducer } = useSelector((state) => state);
  const { COLLECTIONS } = useConstants();
  const { currentAdmin, currentAdminData } = useGetCurrentAdmin();
  const [couponsList, setCouponsList] = useState([]);
  const [couponsPath, setCouponsPath] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      currentAdmin !== null &&
      currentAdminData !== null &&
      typeof currentBusiness !== "undefined"
    ) {
      setCouponsPath(`/companies/${currentBusiness.id}/${COLLECTIONS.COUPONS}`);
      let couponsListData = [];
      firebase
        .getDb()
        .doGetSubCollectionFromDoc(
          `/companies/${currentBusiness.id}`,
          COLLECTIONS.COUPONS
        )
        .then((couponsList) => {
          couponsList.forEach((coupon) => {
            couponsListData.push({ ...coupon, key: coupon.id });
          });
          setCouponsList([...couponsListData]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [
    currentAdmin,
    currentAdminData,
    componentLoaderReducer,
    firebase,
    COLLECTIONS,
  ]);

  return { couponsList, couponsPath, loading };
};

/**
 * Gets a coupon document given the doc id.
 * @param {*} docId
 */
export const useGetCoupon = (docId) => {
  const firebase = useFirebase();
  const { currentUserData } = useGetCurrentUser();
  const [coupon, setCoupon] = useState(null);

  useEffect(() => {
    if (currentUserData !== null) {
      const { companyRefPath } = currentUserData;
      const couponRefPath =
        companyRefPath + "/" + COLLECTIONS.COUPONS + "/" + docId;
      firebase
        .getDb()
        .doGetDocByRefPath(couponRefPath)
        .then((data) => {
          if (data) {
            setCoupon(data);
          }
        });
    }
  }, [currentUserData, firebase, docId]);

  return { coupon };
};

/**
 * Gets a coupon document given the doc id.
 * @param {*} docId
 */
export const useAdminGetCoupon = (couponDocId, currentBusiness) => {
  const firebase = useFirebase();
  const { currentAdminData } = useGetCurrentAdmin();
  const [coupon] = useState(null);

  useEffect(() => {
    if (currentAdminData !== null) {
      const couponRefPath = `/companies/${currentBusiness.id}/${COLLECTIONS.COUPONS}/${couponDocId}`;
      console.log(currentBusiness, couponDocId, couponRefPath);
    }
  }, [currentAdminData, firebase, couponDocId]);

  return { coupon };
};

export const useToolTipText = () => {
  const TOOL_TIP_TEXT = {
    imagePromoTip:
      "Image ads are a great place to display on-going promotions such as a student special with a valid student ID card.",
    aspectRatio:
      "The aspect ratio of an image is the ratio of its width to its height i.e. 1.34.)",
    headline: "This is the main part of the offer. i.e Buy one Get one Free",
    subline:
      "This is in smaller text but also immediately viewable to the user. This is also a good place to put any important restrictions i.e Only available on weekdays.",
    fineprint:
      "This is where legal stuff goes. It is not immediately visible to the user.",
    startDate:
      "Set when the coupon is going to start. Coupon will not be visible on app until it has started",
    expirationDate: "Set when the coupon is going to expire.",
    locationLabel:
      "If you have multiple locations, the label will be visible to users to help them decipher locations.",
  };

  return { TOOL_TIP_TEXT };
};

export const useGetCouponsStatistics = () => {
  const fetchedCoupons = useGetCoupons();
  const { couponsList } = fetchedCoupons;
  const firebase = useFirebase();
  const [couponsStatsList, setCouponsStatsList] = useState([]);
  const [users, setUsers] = useState(null);
  const [setRedeemedList] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let usersDocList = [];
    firebase
      .getDb()
      .doFirestoreGetCollection(COLLECTIONS.USERS)
      .then((collectionList) => {
        collectionList.forEach((userData) => {
          if (userData !== null || typeof userData !== "undefined") {
            const { docData } = userData;
            usersDocList.push(docData);
          }
        });
        setUsers(usersDocList);
      });
  }, [firebase]);

  useEffect(() => {
    if (couponsList.length !== 0 && users !== null) {
      let redeemedCouponList = getTotalRedeemedCoupon(couponsList, users);
      setRedeemedList(redeemedCouponList);
      calculateRedeemedCount(redeemedCouponList);
    } else if (
      couponsList.length === 0 &&
      !fetchedCoupons.loading &&
      users !== null
    ) {
      setLoading(false);
    }
  }, [couponsList, fetchedCoupons.loading, users]);

  const getTotalRedeemedCoupon = (couponsList, users) => {
    let redeemedCouponList = [];
    users.forEach((user) => {
      if (user.redeemed !== undefined) {
        couponsList.forEach((coupon) => {
          user.redeemed.forEach((redeemedCoupon) => {
            if (coupon.id === redeemedCoupon.id) {
              let matchedCoupon = coupon;
              redeemedCouponList.push({ ...matchedCoupon });
            }
          });
        });
      }
    });
    return redeemedCouponList;
    // setRedeemedList(redeemedCouponList);
    // calculateRedeemedCount(redeemedCouponList);
  };

  const calculateRedeemedCount = (redeemedCouponList) => {
    let coupons = couponsList;
    let couponsStats = [];
    let redeemedCount = 0;

    // Add a property to the coupon to indicate the redeemed count.
    coupons.forEach((coupon) => {
      let daysActive = calculateDaysActive(coupon);
      couponsStats.push({ ...coupon, redeemedCount, daysActive });
    });

    // Iterate over the redeemed company's coupons list and increment the
    // redeemedCount property everytime we find a coupon that has been redeemed.
    redeemedCouponList.forEach((redeemedCoupon) => {
      couponsStats.forEach((coupon) => {
        if (redeemedCoupon.id === coupon.id) {
          coupon.redeemedCount += 1;
        }
      });
    });

    setCouponsStatsList(couponsStats);
    setLoading(false);
  };

  return { couponsStatsList, loading };
};

/**
 * TODO: Dynamically render breadcrumbs
 */
export const useGetBreadcrumbs = () => {
  const { pathname } = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let breadcrumbsList = [];
    let pathnamesList = pathname.split("/");
    pathnamesList.forEach((pathname) => {
      breadcrumbsList.push({ path: pathname, breadcrumbName: pathname });
    });

    setBreadcrumbs(breadcrumbsList);
    setLoading(false);
  }, [loading]);

  return { breadcrumbs };
};

/**
 * Fetches all tickets collection
 */
export const useGetTickets = () => {
  const firebase = useFirebase();
  const [ticketsData, setTicketsData] = useState(null);

  useEffect(() => {
    firebase
      .getDb()
      .doFirestoreGetCollection("tickets")
      .then((data) => {
        if (data) {
          setTicketsData(data);
        }
      });
  }, [firebase]);

  return { ticketsData };
};

/**
 * Returns the window size with height and width.
 * {
 * xs: '480px',
 * sm: '576px',
 * md: '768px',
 * lg: '992px',
 * xl: '1200px',
 * xxl: '1600px',
 * }
 */
export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export const useGetImpressionsCount = () => {
  const { companyData } = useGetCompanyData();
  const firebase = useFirebase();
  const { couponsStatsList } = useGetCouponsStatistics();

  const [totalImpressionsList, setTotalImpressionsList] = useState(null);
  const [redeemableCoupons, setRedeemableCoupons] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (couponsStatsList) {
      const onlyRedeemablesList = couponsStatsList.filter(
        ({ type }) => type !== "image"
      );
      setRedeemableCoupons(onlyRedeemablesList);
    }
  }, [couponsStatsList]);

  useEffect(() => {
    if (redeemableCoupons && companyData) {
      let promises = [];
      redeemableCoupons.forEach((coupon, idx) => {
        const { headline, id } = coupon;
        const companyId = companyData.id;
        let refPath = `/companies/${companyId}/coupons/${id}`;
        promises.push(
          firebase
            .getDb()
            .doGetSubCollectionFromDoc(refPath, "impressions")
            .then((data) => {
              if (data) {
                let impressionsCount = data.length;
                let statObj = { type: headline, value: impressionsCount };
                return statObj;
              }
            })
            .catch((error) => {
              console.log(error);
            })
        );
      });

      Promise.all(promises).then((data) => {
        setTotalImpressionsList(data);
        setLoading(false);
      });
    }
  }, [companyData, redeemableCoupons, firebase]);

  if (totalImpressionsList) {
    return { totalImpressionsList, loading };
  } else {
    return { totalImpressionsList, loading };
  }
};
