import React, { useState } from "react";
import useReactRouter from "use-react-router";
import { Form, Input, Button, Checkbox } from "antd";
import { useFirebase, useRoutes } from "../../../hooks";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

export default function SignUpForm() {
  const { routes } = useRoutes();
  const firebase = useFirebase();
  const { history } = useReactRouter();
  const [setErrorsExist] = useState(false);
  const [setErrorMessage] = useState("");

  const onFinish = (values) => {
    const { name, email, password } = values;

    firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        // Sign in
        firebase.doSignInWithEmailAndPassword(email, password).then((data) => {
          const authUser = data.user;
          const doc = authUser.uid;
          const setObj = {
            uid: authUser.uid,
            name,
            email,
          };

          // Add user to database and redirect to admin dashboard
          firebase.getDb().doFirestoreSet("admins", doc, setObj, () => {
            history.push(routes.admin.index);
          });
        });
      })
      .catch((error) => {
        if (error) {
          const { message } = error;
          setErrorsExist(true);
          setErrorMessage(message);
        }
      });
  };

  const onFinishFailed = (error) => {
    console.log("error submitting form: ", error);
  };

  return (
    <>
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        scrollToFirstError={true}
        size="large"
      >
        <Form.Item
          label={<b>Name</b>}
          name="name"
          rules={[{ required: true, message: "Please input your name" }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>

        <Form.Item
          label={<b>Email</b>}
          name="email"
          rules={[{ required: true, message: "Please input your username" }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <br />

        <Form.Item
          label={<b>Password</b>}
          name="password"
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>

        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" block>
            Create Account
          </Button>{" "}
        </Form.Item>
      </Form>
    </>
  );
}
