import React from "react";
import { Row, Col, PageHeader, Button, Typography } from "antd";
import { ShopFilled, RedoOutlined } from "@ant-design/icons";
import styled from 'styled-components'

const { Title, Paragraph } = Typography;

export default function InsightsPageHeader({
  currentBusiness,
  couponsList,
  history,
  routes,
  navigateToBusiness,
  fetchImpressionsCount,
}) {
  const { extraButtons } = useExtraButtons({
    currentBusiness,
    couponsList,
    navigateToBusiness,
    fetchImpressionsCount,
  });
  const { logo, name } = currentBusiness;

  return (
    <PageHeader
      className="box-shadow round"
      ghost={false}
      subTitle="back to all businesses"
      onBack={() => history.push(routes.admin.businesses.index)}
      extra={extraButtons}
    >
      <Row gutter={[20, 0]}>
        <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={24}>
          <Avatar
            draggable={false}
            src={logo}
          />
        </Col>
        <Col xxl={19} xl={19} lg={19} md={19} sm={19} xs={24}>
          <Row>
            <Col span={24}>
              <Title level={1}> {name} Insights</Title>
            </Col>
            <Col span={24}>
              <Paragraph>
                See how your image ads and coupons are performing.
              </Paragraph>
              <Paragraph>
                Click the{" "}
                <b>
                  <RedoOutlined /> Refresh Button
                </b>{" "}
                to refresh the data.
              </Paragraph>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  );
}

const useExtraButtons = ({
  currentBusiness,
  couponsList,
  navigateToBusiness,
  fetchImpressionsCount,
}) => {
  return {
    extraButtons: [
      <Button
        shape="round"
        icon={<RedoOutlined />}
        onClick={() => fetchImpressionsCount(currentBusiness, couponsList)}
        type="default"
        size="large"
      >
        Update Data
      </Button>,
      <Button
        shape="round"
        icon={<ShopFilled />}
        onClick={navigateToBusiness}
        type="default"
        size="large"
      >
        View Business
      </Button>,
    ],
  };
};

const Avatar = styled.img`
  max-height: 100%;
  max-width: 100%;
`