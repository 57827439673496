export default class Helpers {
  static cleanCanvas(nodeId) {
    let lineGraphNode = document.getElementById(nodeId);
    if (lineGraphNode.childNodes.length > 1) {
      lineGraphNode.removeChild(lineGraphNode.childNodes[0]);
    }
  }

  static getMaxValue(data) {
    if (data.length !== 0) {
      let max = data[0].value;
      data.forEach((item, idx) => {
        if (item.value > max) {
          max = item.value;
        }
      });
      return max;
    } else {
      return 0;
    }
  }
}
