import React, { useEffect, useState } from "react";
import {
  Typography,
  message,
  Select,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
} from "antd";
import { useFirebase } from "../../../hooks";
import { useHistory } from "react-router-dom";
import { routes } from "../../../constants/routes";

const { Paragraph } = Typography;
const { Option } = Select;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default function NewBusinessModal({
  showNewBusinessModal,
  handleCloseModal,
}) {
  const firebase = useFirebase();
  const history = useHistory();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {}, [confirmLoading]);

  const addBusiness = (newBusiness, name) => {
    firebase.getDb().addToCollection("companies", newBusiness, () => {
      setConfirmLoading(false);
      handleCloseModal();
      message.success(`Successfully created ${name}`);
      history.push(routes.admin.businesses.index);
    });
  };

  const onFinish = (values) => {
    console.log("values: ", values);
    setConfirmLoading(true);
    const { name } = values;
    const newBusiness = {
      ...values,
      description: "",
      logo: "",
      locations: [],
      spotlight: [],
    };
    console.log("newBusiness: ", newBusiness);
    addBusiness(newBusiness, name);
  };

  const onFinishFailed = (e) => {
    console.log("error submitting form: ", e);
  };

  return (
    <Modal
      title="Create a new business"
      visible={showNewBusinessModal}
      onCancel={handleCloseModal}
      footer={false}
    >
      <Paragraph>
        If the business you added is not displayed on the businesses table, try
        refreshing the browser.
      </Paragraph>
      <Form
        {...layout}
        name="newBusinessForm"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Business Name"
          name="name"
          rules={[{ required: true, message: "Business name required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Description">
          <Input />
        </Form.Item>

        <Row span={24} gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              label="Active"
              name="active"
              rules={[{ required: true, message: "Active status required" }]}
            >
              <Select defaultValue="">
                <Option value={true}>Active</Option>
                <Option value={false}>Inactive</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ required: true, message: "Category is required" }]}
            >
              <Select defaultValue="">
                <Option value="AUTO">AUTO</Option>
                <Option value="EATS">EATS</Option>
                <Option value="GLAM">GLAM</Option>
                <Option value="HOUSING">HOUSING</Option>
                <Option value="APARTMENTS">APARTMENTS</Option>
                <Option value="MED">MED</Option>
                <Option value="UT">UT</Option>
                <Option value="MORE">MORE</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Form.Item>
          <Row gutter={10} justify="end">
            <Col>
              <Button shape="round" type="default" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Col>
            <Col>
              <Button shape="round" type="primary" htmlType="submit">
                Create business
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}
