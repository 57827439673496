import React from "react";
import NewSubscription from "./NewSubscription";

const SubscriptionsPage = () => (
  <div style={{ margin: "0 auto", maxWidth: "1080px" }}>
    <NewSubscription />
  </div>
);

export default SubscriptionsPage;
