import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useRoutes } from "../../../hooks.js";
import { TEXT } from "../../../constants/constants.js";

const SignInNav = () => {
  const history = useHistory();
  const { routes } = useRoutes();

  return <BrandLogo onClick={() => history.push(routes.landing.index)}>{TEXT.appTitle}</BrandLogo>;
};

export default SignInNav;

const BrandLogo = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #000;

  &:hover {
    cursor: pointer !important;
    color: #323232;
  }
`;
