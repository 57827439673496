import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Modal, Row, Col, message } from "antd";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks.js";
import { useDispatch } from "react-redux";
import {
  disableFetch,
  enableFetch,
} from "../../../redux/actions/componentLoaderActions.js";

const DeleteLocationModal = ({ currentBusiness, location, idx }) => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (location) {
  //     console.log("location => ", location);
  //   }
  // }, [location]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (currentBusiness) {
      setLoading(true);
      let locationToDelete = location;
      let companyLocations = currentBusiness.locations;
      let docId = currentBusiness.id;
      let updatedLocations = companyLocations.filter(
        (location, index) => index !== idx
      );
      let businessToUpdate = currentBusiness;
      businessToUpdate.locations = updatedLocations;

      // Write to Firestore
      firebase
        .getDb()
        .doUpdateDoc("companies", docId, businessToUpdate)
        .then(() => {
          setLoading(false);
          dispatch(enableFetch());
          handleCancel();
          message.success(`Successfully removed ${locationToDelete.label}`);
          dispatch(disableFetch());
        });
    }

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (!location) {
    return <LoadingOutlined />;
  }

  if (location) {
    return (
      <>
        <span onClick={showModal}>
          <StyledEditOutlined />Delete location
        </span>
        <Modal
          title="Delete location"
          visible={visible}
          footer={false}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Row
            style={{
              paddingBottom: "6px",
              marginBottom: "6px",
            }}
          >
            <Col span={24}>
              <p>
                Delete <b>{location.label}</b> location?
              </p>
            </Col>
          </Row>
          <Row gutter={10} justify="end">
            {loading ? (
              <Col>
                <Button
                  block
                  type="disabled"
                  className="sm-box-shadow"
                  shape="round"
                  icon={<LoadingOutlined />}
                ></Button>{" "}
              </Col>
            ) : (
              <>
                <Col>
                  <Button
                    block
                    onClick={handleCancel}
                    className="sm-box-shadow"
                    shape="round"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    block
                    type="danger"
                    onClick={handleOk}
                    className="sm-box-shadow"
                    shape="round"
                  >
                    Confirm Delete
                  </Button>{" "}
                </Col>
              </>
            )}
          </Row>
        </Modal>
      </>
    );
  }
};

const StyledEditOutlined = styled(DeleteOutlined)`
  font-size: 14px;
  &:hover {
    color: #1890ff !important;
  }
`;

export default DeleteLocationModal;
