import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import Firestore from "./Firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCQL98JihwvUisCsV-R4njsy8NEOsS8xg0",
  authDomain: "student-discounts.firebaseapp.com",
  databaseURL: "https://student-discounts.firebaseio.com",
  projectId: "student-discounts",
  storageBucket: "student-discounts.appspot.com",
  messagingSenderId: "337611053836",
  appId: "1:337611053836:web:ec138df3bce8b532e44729",
};

export default class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.app = app;
    this.auth = app.auth();
    this.db = new Firestore(app);
    this.storage = app.storage();
    this.functions = app.functions();
  }

  // GETTERS
  getApp = () => this.app;
  getAuth = () => this.auth;
  getDb = () => this.db;
  getStorage = () => this.storage;

  // AUTH API

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doCreateRefFromHTTPSURL = (httpsUrl) => {
    return new Promise((resolve, reject) => {
      let httpsReference = this.getStorage().refFromURL(httpsUrl);
      resolve(httpsReference);
    });
  };

  doSendPasswordResetEmail = (emailAddress) => {
    this.auth
      .sendPasswordResetEmail(emailAddress)
      .then(() => {
        // Email sent.
        console.log("Email sent.");
      })
      .catch((error) => {
        // An error happened.
        console.log(error);
      });
  };

  doUpdateEmail = (currentUser, email) => {
    return currentUser
      .updateEmail(email)
      .then(() => {
        // Update successful.
        console.log("Successfully updated email.");
      })
      .catch((error) => {
        // An error happened.
      });
  };

  doDownloadDataViaUrl = (httpsReference) => {
    console.log(httpsReference);
    const { fullPath } = httpsReference;
    console.log(fullPath);

    this.getStorage()
      .ref()
      .child(fullPath)
      .getDownloadURL()
      .then((url) => {
        console.log("URL => ", url);
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        var xhr = new XMLHttpRequest();
        console.log("xhr: ", xhr);
        // xhr.onload = (event) => {
        //   var blob = xhr.response;
        // };
        xhr.open("GET", url);
        xhr.send();

        // Or inserted into an <img> element:
        var img = document.getElementById("myimg");
        img.src = url;
      })
      .catch((error) => {
        // Handle any errors
        console.log(error);
      });
  };

  /**
   * Uploads a file (image) to Firebase Storage.
   *
   * @param {Object} fileToUpload
   *
   * Returns a promise with downloadURL.
   */
  doUploadFile = (fileToUpload, folder) => {
    return new Promise((resolve, reject) => {
      let progress = 0;
      let downloadURL = null;

      // File or Blob named nameOfFile.jpg
      const { originFileObj } = fileToUpload;
      let file = originFileObj;

      // Create the file metadata with the given file type
      const { type } = file;
      let metadata = { contentType: type };

      // Create a reference to 'images/nameOfFile.jpg'
      let storageRef = this.getStorage().ref();

      // Upload file and metadata to the object 'images/nameOfFile.jpg'
      const path = folder + "/";
      storageRef
        .child(path + file.name)
        .put(file, metadata)
        .then((uploadTaskSnapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          progress =
            (uploadTaskSnapshot.bytesTransferred /
              uploadTaskSnapshot.totalBytes) *
            100;
          console.log("Upload is " + progress + "% done");

          // console.log('uploadTaskSnapshot: ', uploadTaskSnapshot);
          // Upload completed successfully, now we can get the download URL
          uploadTaskSnapshot.ref.getDownloadURL().then((downloadUrl) => {
            downloadURL = downloadUrl;
            resolve({ downloadURL, progress });
          });
        });
    });
  };

  /**
   * Firebase Functions
   */
  doCalculateImpressionsCount = async ({ businessId, couponId }) => {
    const calculateImpressionsCount = this.functions.httpsCallable("calculateImpressionsCount");
    return await calculateImpressionsCount({ businessId, couponId });
  };
}
