import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Modal, Row, Col, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useFirebase, useGetCompanyData } from "../../../hooks.js";
import { useDispatch } from "react-redux";
import { enableFetch } from "../../../redux/actions/componentLoaderActions.js";

export default function DeleteLocationModal({ location, idx }) {
  const { companyData } = useGetCompanyData();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (location) {
      console.log("location => ", location);
    }
  }, [location]);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (companyData) {
      let locationToDelete = location;
      let companyLocations = companyData.data.locations;
      let companyId = companyData.id;
      let updatedLocations = companyLocations.filter(
        (location, index) => index !== idx
      );
      let businessToUpdate = companyData.data;
      businessToUpdate.locations = updatedLocations;
      console.log("business to update: ", businessToUpdate);

      // Write to Firestore
      firebase
        .getDb()
        .doUpdateDoc("companies", companyId, businessToUpdate)
        .then(() => {
          message.success(`Successfully removed ${locationToDelete.label}`);
          dispatch(enableFetch()); // update the UI
        });
    }

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  if (!location) {
    return "Loading";
  }

  if (location) {
    return (
      <>
        <span onClick={showModal}>
          <StyledEditOutlined />
        </span>
        <Modal
          visible={visible}
          footer={false}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Row
            style={{
              paddingBottom: "6px",
              marginBottom: "6px",
            }}
          >
            <Col span={24}>
              <p>
                Delete <b>{location.label}</b> location?
              </p>
            </Col>
          </Row>
          <Row gutter={10} justify="end">
            <Col>
              <Button
                block
                onClick={handleCancel}
                className="sm-box-shadow"
                shape="round"
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                block
                type="danger"
                onClick={handleOk}
                className="sm-box-shadow"
                shape="round"
              >
                Confirm Delete
              </Button>{" "}
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

const StyledEditOutlined = styled(DeleteOutlined)`
  font-size: 14px;
  &:hover {
    color: #1890ff !important;
  }
`;
