import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import NewCouponForm from "./NewCouponForm";

const NewCouponModal = ({ currentBusiness, modalAction }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <a onClick={showModal}>{modalAction}</a>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
        title="Create a new coupon"
      >
        <NewCouponForm
          currentBusiness={currentBusiness}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
};

export default NewCouponModal;
