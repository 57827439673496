import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./assets/css/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { FirebaseContext } from "./hooks";
import Firebase from "./Firebase/Firebase";
import { Provider } from "react-redux";
import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { userStateReducer } from "./redux/reducers/userStateReducer.js";
import { fullPageLoaderReducer } from "./redux/reducers/fullPageLoaderReducer.js";
import { componentLoaderReducer } from "./redux/reducers/componentLoaderReducer.js";
import { businessReducer } from "./redux/reducers/businessReducer";
import { sidebarCollapseReducer } from "./redux/reducers/sidebarCollapseReducer";

const persistConfig = {
  key: "root",
  storage,
};

const combinedReducers = combineReducers({
  businessReducer,
  userStateReducer,
  fullPageLoaderReducer,
  componentLoaderReducer,
  sidebarCollapseReducer,
});
const persistedReducer = persistReducer(persistConfig, combinedReducers);
const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const persistor = persistStore(store);

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </PersistGate>
    </Provider>
  </FirebaseContext.Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
