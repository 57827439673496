import React, { useState } from "react";
import styled from "styled-components";
import { Alert, Card, Form, Input, Button } from "antd";
import SignInNav from "./SignIn/SignInNav.js";
import { useFirebase } from "../../hooks.js";

const { Item } = Form;

const ForgotMyPasswordPage = () => {
  const firebase = useFirebase();
  const [success, setSuccess] = useState(false);

  const onFinish = (values) => {
    const { email } = values;
    firebase.doSendPasswordResetEmail(email);
    setSuccess(true);
  };

  const onFinishFailed = () => { };

  return (
    <RPWrapper>
      <SignInNav />
      <RPContainer>
        <Card>
          <CardContainer>
            {success ? (
              <StyledAlert
                message="We have emailed you a link to reset your password"
                type="success"
                showIcon
              />
            ) : (
              <></>
            )}
            <h2>Reset password</h2>
            <RPMessage>
              Enter the email address associated with your account and we will
              send you a link to reset your password.
            </RPMessage>
            <Form
              {...layout}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <FormItem
                label={<b>Email Address</b>}
                name="email"
                rules={[
                  { required: true, message: "Email Address is required" },
                ]}
              >
                <Input size="large" />
              </FormItem>
              <Button block htmlType="submit" type="primary" size="large">
                Request Password Reset
              </Button>
            </Form>
          </CardContainer>
        </Card>
      </RPContainer>
    </RPWrapper>
  );
};

export default ForgotMyPasswordPage;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const RPWrapper = styled.div`
  min-height: 100vh;
  margin: 0 auto;
  padding: 60px 10px 0 10px;
`;

const RPContainer = styled.div`
  margin: 0 auto;
  max-width: 480px;
  padding-top: 140px;
`;

const CardContainer = styled.div``;

const RPMessage = styled.p`
  margin-bottom: 16px;
`;

const FormItem = styled(Item)`
  margin-bottom: 28px;
`;

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;
