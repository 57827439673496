import React from "react";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../StyledComponents/styledComponents";
import { Dropdown, Button } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import EditLocationModal from "./EditLocationModal";
import DeleteLocationModal from "./DeleteLocationModal";

const LocationsTableActions = ({ location, currentBusiness, idx }) => {
  const menu = (
    <StyledMenu>
      <StyledMenuItem>
        <EditLocationModal
          currentBusiness={currentBusiness}
          location={location}
          idx={idx}
        />
      </StyledMenuItem>
      <StyledMenuItem danger>
        <DeleteLocationModal
          currentBusiness={currentBusiness}
          location={location}
          idx={idx}
        />
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
      <Button icon={<EllipsisOutlined />} shape="round" size="small"></Button>
    </Dropdown>
  );
};

export default LocationsTableActions;
