import React, { useState } from "react";
import { Modal, Row, Col } from "antd";
import EditImageAdForm from "./EditImageAdForm";

const EditImageAdModal = (props) => {
  const { currentBusiness, modalAction, imageAd } = props;
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <a onClick={showModal}>{modalAction}</a>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
        title="Edit image ad"
      >
        <Row
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "6px",
            marginBottom: "6px",
          }}
        >
          <Col span={24}>
            <EditImageAdForm
              currentBusiness={currentBusiness}
              handleCancel={handleCancel}
              imageAd={imageAd}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default EditImageAdModal;
