import React from "react";
import styled from "styled-components";
import { Drawer, Row, Col, Button, Divider } from "antd";
import {
  LogoutOutlined,
  PlusOutlined,
  SettingOutlined,
  CloseOutlined,
} from "@ant-design/icons";

export const HeaderNavDrawer = (props) => {
  const { onClose, visible, setVisible, handleRedirect, handleSignOut } = props;

  return (
    <NavDrawer
      title={<DrawerTitle visible={visible} setVisible={setVisible} />}
      placement="top"
      closable={false}
      onClose={onClose}
      visible={visible}
      height={1000}
    >
      <MenuHeader>
        <h1>
          <PlusOutlined /> <b>Create</b>
        </h1>
      </MenuHeader>
      <SubMenu>
        <SubMenuItem onClick={() => handleRedirect("coupons")}>
          <h1>Coupon</h1>
        </SubMenuItem>
        <SubMenuItem onClick={() => handleRedirect("ads")}>
          <h1>Image Ad</h1>
        </SubMenuItem>
      </SubMenu>
      <NavDrawerDivider />
      <MenuItem onClick={() => handleRedirect("account")}>
        <h1>
          <SettingOutlined /> Account Settings
        </h1>
      </MenuItem>
      <MenuItem>
        <h1>
          <LogoutOutlined onClick={() => handleSignOut()} /> Sign Out
        </h1>
      </MenuItem>
    </NavDrawer>
  );
};

const DrawerTitle = ({ visible, setVisible }) => {
  return (
    <Row span={24}>
      <Col span={12}>
        <h1>Student Life 101</h1>
      </Col>
      <Col span={12} style={{ textAlign: "right" }}>
        <Button
          icon={<CloseOutlined />}
          size="large"
          onClick={() => setVisible(!visible)}
        />
      </Col>
    </Row>
  );
};

/**
 * Styled components
 */

const MenuHeader = styled.div`
  padding: 30px 0 0 30px;
`;

const SubMenu = styled.div``;

const SubMenuItem = styled.div`
  padding: 14px 0 10px 100px;
  &:hover {
    background-color: #000000 !important;
    cursor: pointer !important;
  }
`;

const MenuItem = styled.div`
  padding: 14px 0 10px 30px;

  &:hover {
    background-color: #000000 !important;
    cursor: pointer !important;
  }
`;

const NavDrawerDivider = styled(Divider)`
  border-color: #000;
`;

const NavDrawer = styled(Drawer)`
  h1 {
    color: #fff !important;
  }

  button {
    background-color: #001529 !important;
    color: #fff !important;
  }

  .ant-drawer-header {
    background-color: #001529 !important;
    color: #fff !important;
    border-bottom: 1px solid #000000;
  }

  .ant-drawer-body {
    padding: 0 !important;
    background-color: #041729 !important;
    color: #fff !important;
  }
`;
