import React, { useState } from "react";
import styled from "styled-components";
import { Form, Input, Button, Modal, Row, Col, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks.js";
import { useDispatch, useSelector } from "react-redux";
import {
  disableFetch,
  enableFetch,
} from "../../../redux/actions/componentLoaderActions.js";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const EditLocationModal = (props) => {
  const { currentBusiness, location, idx } = props;
  // const { companyData } = useGetCompanyData();
  const firebase = useFirebase();
  const { componentLoaderReducer } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (value) => {
    if (currentBusiness) {
      const { id } = currentBusiness;

      if (typeof value.label === "undefined") {
        value.label = "";
      }

      // Get updated Location
      let updatedLocation = { ...value };
      // let businessToUpdate = data;
      let businessToUpdate = currentBusiness;

      // Filter out the location we want to update
      let updatedLocations = businessToUpdate.locations.filter(
        (location, index) => index !== idx
      );

      // Add the updated location back
      updatedLocations.push(updatedLocation);
      businessToUpdate.locations = updatedLocations;

      // Write it to Firestore
      firebase
        .getDb()
        .doUpdateDoc("companies", id, businessToUpdate)
        .then(() => {
          setVisible(false);
          dispatch(enableFetch());
          dispatch(disableFetch());
          message.success("Successfully updated Location.");
        });
    }
  };

  const onFinishFailed = () => {};

  if (!location) {
    return "Loading";
  }

  if (location) {
    return (
      <>
        <span onClick={showModal}>
          <StyledEditOutlined /> Edit location
        </span>
        <Modal
          title="Edit location"
          closable={false}
          closeIcon={false}
          visible={visible}
          footer={false}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Label"
              initialValue={location.label}
              name="label"
              // rules={[{ required: true, message: "Please input your label!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Address"
              initialValue={location.address}
              name="address"
              rules={[
                { required: true, message: "Please input your address!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Phone" initialValue={location.phone} name="phone">
              <Input />
            </Form.Item>

            <Form.Item>
              <Row gutter={10} justify="end">
                <Col>
                  <Button
                    disabled={componentLoaderReducer ? false : true}
                    type="secondary"
                    onClick={handleCancel}
                    shape="round"
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit" shape="round">
                    Update location
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
};

const StyledEditOutlined = styled(EditOutlined)`
  font-size: 14px;
  &:hover {
    color: #1890ff !important;
  }
`;

export default EditLocationModal;
