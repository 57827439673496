import React, { useState, useEffect } from "react";
import { Modal, Row, Col, message } from "antd";
import { useFirebase, useGetCompanyData } from "../../../hooks.js";
import { enableFetch } from "../../../redux/actions/componentLoaderActions";
import { useDispatch } from "react-redux";
import EditCouponPage from "../../Coupons/EditCoupon/EditCouponPage";
// import EditImageAdPage from "../../ImageAds/EditImageAd/EditImageAdPage.js";

export default function EditCouponModal(props) {
  const { modalAction, couponParams } = props;
  const { companyData } = useGetCompanyData();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const handleCancel = () => {
    setVisible(false);
  };

  // const onFinish = (value) => {
  //   if (companyData) {
  //     const { id, data } = companyData;
  //     console.log(id, data);
  //     console.log(value);
  //     let businessToUpdate = data;
  //     businessToUpdate.locations.push({ ...value });
  //     console.log("business to update: ", businessToUpdate);
  //     firebase
  //       .getDb()
  //       .doUpdateDoc("companies", id, businessToUpdate)
  //       .then(() => {
  //         dispatch(enableFetch());
  //         handleCancel();
  //         message.success(`Successfully added ${value.label}`);
  //       });
  //   }
  // };

  // const onFinishFailed = () => {};

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <a onClick={showModal}>{modalAction}</a>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
      >
        <Row
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "6px",
            marginBottom: "6px",
          }}
        >
          <Col
            span={24}
            align="center"
            style={{ fontSize: "22px", fontWeight: "600" }}
          >
            Edit Coupon
          </Col>
          <Col span={24}>
            {/* <EditImageAdPage handleCancel={handleCancel} imageAd={imageAd} /> */}
            <EditCouponPage couponParams={couponParams} handleCancel={handleCancel} />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
