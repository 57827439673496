import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, Row, Col, message } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useFirebase, useGetCompanyData } from "../../../hooks.js";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";
import { useDispatch } from "react-redux";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export default function NewLocationForm(props) {
  const { modalAction } = props;
  const { companyData } = useGetCompanyData();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [loading]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async (value) => {
    setLoading(true);
    if (companyData) {
      const { id, data } = companyData;
      console.log(id, data);

      if (typeof value.label === "undefined") {
        value.label = "";
      }

      let businessToUpdate = data;

      // If the user has never added a location the locations array will be undefined.
      if (typeof businessToUpdate.locations === "undefined") {
        businessToUpdate.locations = [];
      }

      businessToUpdate.locations.push({ ...value });
      console.log("business to update: ", businessToUpdate);
      await firebase
        .getDb()
        .doUpdateDoc("companies", id, businessToUpdate)
        .then(() => {
          dispatch(enableFetch());
          dispatch(disableFetch());
          handleCancel();
          setLoading(false);
          message.success(`Successfully added ${value.label}`);
        });
    }
  };

  const onFinishFailed = () => {};

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <span onClick={showModal}>{modalAction}</span>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
      >
        <Row
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "6px",
            marginBottom: "6px",
          }}
        >
          <Col
            span={24}
            align="center"
            style={{ fontSize: "22px", fontWeight: "600" }}
          >
            Create a new location
          </Col>
        </Row>
        <Form
          {...layout}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="Label" name="label">
            <Input />
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: "Please input your phone!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Row gutter={10} justify="end">
              {loading ? (
                <Col>
                  <Button
                    disabled
                    block
                    type="primary"
                    htmlType="submit"
                    icon={<Loading3QuartersOutlined spin />}
                    shape="round"
                    className="sm-box-shadow"
                  ></Button>
                </Col>
              ) : (
                <>
                  <Col>
                    <Button
                      block
                      type="secondary"
                      onClick={handleCancel}
                      className="sm-box-shadow"
                      shape="round"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      className="sm-box-shadow"
                      shape="round"
                    >
                      Add location
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
