import React from 'react';
import styled from 'styled-components';
import { Row, Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';

export const AuthLoader = (props) => {
  return (
    <SignInWrapper>
      <SignInContainer>
        <Row justify='center'>
          <Spin
            indicator={
              <Loading3QuartersOutlined spin style={{ fontSize: '64px' }} />
            }
          />
        </Row>
      </SignInContainer>
    </SignInWrapper>
  );
};

const SignInWrapper = styled.div`
  background-color: #f6f7f9 !important;
  min-height: 100vh !important;
  padding: 20px 10px 0 10px;
`;

const SignInContainer = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  padding: 60px 0 100px 0;
`;