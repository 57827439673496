import React from "react";
import { Button, Form, Input, DatePicker, message, Row, Col } from "antd";
import {
  useFirebase,
  useGetCurrentUser,
  useConstants,
  useGetCoupon,
  useToolTipText,
  useRoutes,
} from "../../../hooks.js";
import { useHistory } from "react-router-dom";
import { PlaceholderForm } from "../SharedComponents/Placeholders.js";
import FormLabelTooltip from "../../../components/FormLabelTooltip";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";

const EditCouponPage = ({ couponParams, handleCancel }) => {
  // const { location } = props;
  // const { state } = location;
  // const { couponParams } = state;
  const { id } = couponParams;
  const dispatch = useDispatch();
  const { coupon } = useGetCoupon(id);
  const firebase = useFirebase();
  const { TOOL_TIP_TEXT } = useToolTipText();
  const { currentUserData } = useGetCurrentUser();
  const { COLLECTIONS } = useConstants();
  const history = useHistory();
  const { routes } = useRoutes();

  const onFinish = (values) => {
    // Convert properties to types respective to existing firebase data types.
    const startDate = values.startDate.toDate();
    const expires = values.expires.toDate();

    // Coupon to be updated.
    const couponToUpdate = { ...values, startDate, expires };

    // Get subcollection path.
    const fullPath =
      currentUserData !== null
        ? currentUserData.companyRefPath + "/" + COLLECTIONS.COUPONS
        : "";

    // Update the coupon.
    firebase
      .getDb()
      .doUpdateSubcollectionDoc(fullPath, id, couponToUpdate)
      .then(() => {
        dispatch(enableFetch());
        message.success(`Successfully updated ${coupon.data.headline} coupon.`);
        dispatch(disableFetch());
        // history.push({
        // pathname:
        // routes.dashboard.coupons.show +
        // "/" +
        // encodeURIComponent(coupon.data.headline),
        // state: { couponParams: coupon },
        // });
      });
  };

  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      {coupon === null ? (
        <PlaceholderForm />
      ) : (
        <Form
          {...formLayout}
          name="basic"
          layout="horizontal"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <FormLabelTooltip
                name="Headline"
                tooltipText={TOOL_TIP_TEXT.headline}
              />
            }
            name="headline"
            initialValue={coupon.data.headline}
            rules={[{ required: true, message: "Please input a headline." }]}
          >
            <Input.TextArea autoSize />
          </Form.Item>

          <Form.Item
            label={
              <FormLabelTooltip
                name="Subline"
                optional="optional"
                tooltipText={TOOL_TIP_TEXT.subline}
              />
            }
            name="subline"
            initialValue={
              typeof coupon.data.subline === "undefined"
                ? ""
                : coupon.data.subline
            }
          >
            <Input.TextArea autoSize />
          </Form.Item>

          <Form.Item
            label={
              <FormLabelTooltip
                name="Fineprint"
                optional="optional"
                tooltipText={TOOL_TIP_TEXT.fineprint}
              />
            }
            name="finePrint"
            initialValue={
              typeof coupon.data.finePrint === "undefined"
                ? ""
                : coupon.data.finePrint
            }
          >
            <Input size="large" />
          </Form.Item>

          <Row gutter={[20, 0]}>
            <Col span={12}>
              <Form.Item
                label={
                  <FormLabelTooltip
                    name="Start Date"
                    tooltipText={TOOL_TIP_TEXT.startDate}
                  />
                }
                name="startDate"
                initialValue={
                  typeof coupon.data.startDate === "undefined"
                    ? ""
                    : moment(coupon.data.startDate.toDate())
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter a start date.",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <FormLabelTooltip
                    name="Expiration Date"
                    tooltipText={TOOL_TIP_TEXT.expirationDate}
                  />
                }
                name="expires"
                initialValue={moment(coupon.data.expires.toDate())}
                rules={[
                  {
                    required: true,
                    message: "Please enter an expiration date.",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row gutter={10} justify="end">
              <Col>
                <Button
                  className="sm-box-shadow"
                  shape="round"
                  block
                  onClick={() => handleCancel()}
                  // onClick={() => history.push(routes.dashboard.coupons.index)}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="sm-box-shadow"
                  shape="round"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Coupon
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default EditCouponPage;
