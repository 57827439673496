/**
 * Cloud Firestore API
 */
export default class Firestore {
  constructor(app) {
    this.app = app;
    this.db = app.firestore();
  }

  /**
   * Adds a new document to a collection with an auto-id.
   * @param {String} collection
   * @param {Object} newObject
   */
  addToCollection = async (collection, newObject, callback) => {
    const newDocument = {
      ...newObject,
      createdAt: this.app.firestore.Timestamp.now(),
    };
    await this.db
      .collection(collection)
      .add(newDocument)
      .then((data) => {
        console.log("Document successfully written!", data);
        callback();
        return data;
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  /**
   *
   * @param {string} collection
   * @param {string} doc
   * @param {Object} setObj
   * @param {Function} callback
   */
  doFirestoreSet = (collectionName, docId, setObj, callback) => {
    this.db
      .collection(collectionName)
      .doc(docId)
      .set(setObj)
      .then(() => {
        console.log("Document successfully written!");
        callback();
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  };

  /**
   * Gets a collection from firestore.
   * @param {String} collectionName
   *
   * Provided a collection name, retrieve the collection.
   */
  doFirestoreGetCollection = (collectionName) => {
    let collectionList = [];
    return this.db
      .collection(collectionName)
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          const docRefPath = doc.ref.path;
          const docId = doc.id;
          const docData = doc.data();
          collectionList.push({ docRefPath, docId, docData });
        });
        return collectionList;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  };

  /**
   * Gets current user data from firestore.
   * @param {*} collectionName
   * @param {*} currentUserUid
   *
   * Provided the collection and current user uid, retrieve the current user's data.
   */
  doFirestoreGetCurrentUser = (collectionName, currentUserUid) => {
    let currentUserData = {};
    return this.db
      .collection(collectionName)
      .get()
      .then((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          const docData = doc.data();
          if (docData.uid === currentUserUid) {
            currentUserData = docData;
          }
        });
        return currentUserData;
      });
  };

  /**
   * Gets a document by a reference path.
   * @param {String} refPath
   * @param {Function} callback
   *
   * Provided the ref path, retrieves the document.
   */
  doGetDocByRefPath = (refPath, callback) => {
    return this.db
      .doc(refPath)
      .get()
      .then((doc) => {
        const id = doc.id;
        const data = doc.data();
        if (callback) {
          callback({ id, data });
        }
        return { id, data };
      });
  };

  /**
   * Gets a subcollection from a document.
   * @param {String} refPath
   * @param {String} subCollectionName
   *
   * Provided the ref path, retrieves a subcollection the document.
   * Returns the document id with data.
   */
  doGetSubCollectionFromDoc = (refPath, subCollectionName) => {
    const fullPathName = refPath + "/" + subCollectionName;
    const companyId = refPath.split("/")[1];
    let subcollectionList = [];
    return this.db
      .collection(fullPathName)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const { id } = doc;
          const docData = doc.data();
          subcollectionList.push({ id, ...docData, companyId });
        });
        return subcollectionList;
      });
  };

  /**
   * Adds data to a subcollection.
   * @param {String} refPath
   * @param {String} subCollectionName
   * @param {Object} data
   *
   * But sometimes there isn't a meaningful ID for the document, and it's more
   * convenient to let Cloud Firestore auto-generate an ID for you. You can do
   * this by calling add():
   */
  addDataToSubCollection = (refPath, subCollectionName, data) => {
    const fullRefPath = refPath + "/" + subCollectionName;
    return this.db
      .collection(fullRefPath)
      .add(data)
      .then((ref) => {
        return ref;
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  /**
   * Deletes a document from a collection.
   * @param {*} collectionName
   * @param {*} doc
   *
   * Provided the collection name and docment, delete the document.
   */
  deleteDocFromCollection = (collectionName, doc, callback) => {
    return this.db
      .collection(collectionName)
      .doc(doc)
      .delete()
      .then(() => {
        console.log("Successfully deleted document.");
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  /**
   *
   * @param {string} collectionName
   * @param {string} doc
   * @param {Object} data
   * @param {Function} callback
   */
  doUpdateDoc = (collectionName, doc, data, callback) => {
    // remove all undefined values
    const cleanedData = { ...data };
    Object.keys(data).forEach((key) => {
      if (!Boolean(cleanedData[key])) cleanedData[key] = "";
    });
    return this.db
      .collection(collectionName)
      .doc(doc)
      .update(cleanedData)
      .then(() => {
        if (callback) {
          callback();
        }
        console.log("Successfully updated documnet.");
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };

  /**
   *
   * @param {string} refPath
   * @param {Object} data
   * @param {Function} callback
   */
  doUpdateByRefPath = (refPath, data, callback) => {
    return this.db
      .doc(refPath)
      .update(data)
      .then(() => {
        console.log("Updated doc successfully.");
        callback();
      })
      .catch((error) => {
        if (error) {
          console.log("error", error);
        }
      });
  };

  /**
   * Updates document.
   * @param {*} collectionName
   * @param {*} doc
   * @param {*} data
   */
  doUpdateSubcollectionDoc = (collectionName, doc, data) => {
    return this.db
      .collection(collectionName)
      .doc(doc)
      .update(data)
      .then(() => {
        console.log("Successfully updated documnet.");
      })
      .catch((error) => {
        if (error) {
          console.log(error);
        }
      });
  };
}
