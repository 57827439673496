import React, { useState } from "react";
import { Typography, Modal, Button, Row, Col } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const { Text } = Typography;

const SpotlightDetailsModal = ({ spotlight }) => {
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div onClick={() => setVisible(true)}><EyeOutlined /> View spotlight</div>
      <Modal
        visible={visible}
        title="Spotlight details"
        footer={false}
        onCancel={handleCancel}
        handleCancel={handleCancel}
      >
        <Row span={24} gutter={[10, 10]}>
          <Col span={6} style={{ textAlign: "right" }}>
            <Text strong>Active:</Text>
          </Col>
          <Col span={18}>{spotlight.active ? "Active" : "Inactive"}</Col>

          <Col span={6} style={{ textAlign: "right" }}>
            <Text strong>Label:</Text>
          </Col>
          <Col span={18}>{spotlight.label}</Col>

          <Col span={6} style={{ textAlign: "right" }}>
            <Text strong>Value:</Text>
          </Col>
          <Col span={18}>{spotlight.value}</Col>

          <Col span={6} style={{ textAlign: "right" }}>
            <Text strong>Color:</Text>
          </Col>
          <Col span={18}>
            {spotlight.color === "" ? "No Color" : spotlight.color}
          </Col>

          <Col span={6} style={{ textAlign: "right" }}>
            <Text strong>Type:</Text>
          </Col>
          <Col span={18}>
            <div>{spotlight.type !== undefined ? spotlight.type : ""}</div>
          </Col>

          <Col span={6} style={{ textAlign: "right" }}>
            <Text strong>Icon Type:</Text>
          </Col>
          <Col span={18}>
            {spotlight.iconType !== undefined ? spotlight.iconType : ""}
          </Col>

          <Col span={6} style={{ textAlign: "right" }}>
            <Text strong>Font Weight:</Text>
          </Col>
          <Col span={18}>
            {spotlight.fontWeight !== undefined ? spotlight.fontWeight : ""}
          </Col>
        </Row>

        <Row justify="end">
          <Col>
            <Button
              shape="round"
              className="sm-box-shadow"
              onClick={handleCancel}
            >
              Close
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SpotlightDetailsModal;
