import React from  "react"
import { Tooltip } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"

const FormLabelTooltip = ({ name, optional, tooltipText }) => (
  <>
    <span style={{ marginRight: "4px" }}>
      {name} {optional !== undefined ? "(optional)" : ""}
    </span>
    <span>
      <Tooltip placement="top" title={tooltipText}>
        <QuestionCircleOutlined />
      </Tooltip>
    </span>
  </>
);

export default FormLabelTooltip
