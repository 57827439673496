import React from "react";
import {
  StyledMenuItem,
  StyledMenu,
} from "../../../StyledComponents/styledComponents";
import { Button, Dropdown } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import CouponDetailsModal from "../../Coupons/components/CouponDetailsModal";
import EditCouponModal from "../../Coupons/components/EditCouponModal";
import DeleteCouponModal from "../../Coupons/components/DeleteCouponModal";

const CouponsListActions = ({ coupon }) => {
  const menu = (
    <StyledMenu>
      <StyledMenuItem>
        <CouponDetailsModal
          modalAction={<>View Coupon</>}
          couponParams={coupon}
        />
      </StyledMenuItem>
      <StyledMenuItem>
        <EditCouponModal modalAction={<>Edit Coupon</>} couponParams={coupon} />
      </StyledMenuItem>
      <StyledMenuItem danger>
        <DeleteCouponModal coupon={coupon} />
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <Button
          shape="round"
          type="ghost"
          className="sm-box-shadow"
          size="small"
        >
          <EllipsisOutlined />
        </Button>
      </a>
    </Dropdown>
  );
};

export default CouponsListActions;
