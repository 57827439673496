import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import VerticalNav from "./components/VerticalNav";
import { Divider, Row, Layout } from "antd";
import { useRoutes } from "../../hooks.js";
import DashboardHome from "./DashboardHome/DashboardHome.js";
import CouponsPage from "../Coupons/CouponsPage.js";
import NotFound from "../ErrorResultPages/NotFound.js";
import Account from "../Account/Account.js";
import NewCouponPage from "../Coupons/NewCoupon/NewCouponPage.js";
import ShowCouponPage from "../Coupons/ShowCoupon/ShowCouponPage.js";
import EditCouponPage from "../Coupons/EditCoupon/EditCouponPage.js";
import Insights from "../Insights/Insights.js";
import NewImageAdPage from "../ImageAds/NewImageAd/NewImageAdPage.js";
import ImageAdsPage from "../ImageAds/ImageAdsPage.js";
import EditImageAdPage from "../ImageAds/EditImageAd/EditImageAdPage.js";
import { HeaderNav } from "./components/HeaderNav.js";
import { withAuth } from "../../components/hocs/hocs.js";
import SubscriptionsPage from "../Subscriptions/SubscriptionsPage";
import ConfirmSubscription from "../Subscriptions/ConfirmSubscription.js";
import Home from "../Home/Home";

const { Sider, Content } = Layout;

const DashboardPage = () => {
  const { routes } = useRoutes();
  const [collapsed, setCollapsed] = useState(false);

  /**
   * Handle dashboard transformation when toggling collapsible sidebar.
   * Dependent on collapsed state changing.
   */
  useEffect(() => {
    vertNavCollapseStyles(collapsed);
  });

  return (
    <Layout>
      <DashboardSider
        trigger={null}
        collapsible
        collapsed={collapsed}
        id="globalSidebar">
        <VerticalNav />
      </DashboardSider>
      <Layout id="siteLayout">
        <DashboardContent className="site-layout-background">
          <HeaderNav collapsed={collapsed} setCollapsed={setCollapsed} />
          <DashboardWrapper>
            <Route
              render={({ location }) => (
                <DashboardContainer>
                  <Switch location={location}>
                     <Route exact path={routes.dashboard.home} component={Home} />
                     <Route exact path={routes.dashboard.business.index} component={DashboardHome} />
                     <Route exact path={routes.dashboard.coupons.index} component={CouponsPage} />
                     <Route exact path={routes.dashboard.ads.index} component={ImageAdsPage} />
                     <Route exact path={routes.dashboard.insights.index} component={Insights} />
                     <Route exact path={routes.dashboard.account.index} component={Account} />
                     <Route exact path={routes.dashboard.coupons.new} component={NewCouponPage} />
                     <Route exact path={routes.dashboard.ads.new} component={NewImageAdPage} />

                     <Route path={routes.dashboard.coupons.edit} component={EditCouponPage} />
                     <Route path={routes.dashboard.coupons.show} component={ShowCouponPage} />
                     <Route path={routes.dashboard.ads.edit} component={EditImageAdPage} />
                     <Route path={routes.dashboard.subscriptions.new} component={SubscriptionsPage} />
                     <Route path={routes.dashboard.subscriptions.checkout} component={ConfirmSubscription} />

                     <Route render={() => <NotFound />} />
                  </Switch>
                </DashboardContainer>
              )}
            />
            <Divider />
            <Row justify="end">
              <div style={{ padding: "20px" }}>
                Developed and maintained by <b>Alligator Applications LLC &copy; 2020</b>
              </div>
            </Row>
          </DashboardWrapper>
        </DashboardContent>
      </Layout>
    </Layout>
  );
};

export default withAuth(DashboardPage);

const vertNavCollapseStyles = (collapsed) => {
  let dashboardHeader = document.getElementById("dashboardHeader");
  let dashboardNav = document.getElementById("dashboardNav");
  let siteLayout = document.getElementById("siteLayout");
  let dashboardMenuRight = document.getElementById("dashboardMenuRight");

  const easeInTimer = 0.2;
  const easeOutTimer = 0.1;
  const nodesExists = siteLayout !== null || dashboardHeader !== null || dashboardNav !== null;

  if (nodesExists) {
    if (collapsed) {
      siteLayout.style.marginLeft = "80px";
      siteLayout.style.transition = `all ease-in-out ${easeInTimer}s`;
      dashboardMenuRight.style.marginRight = "80px";
      dashboardMenuRight.style.transition = `all ease-in-out ${easeOutTimer}s`;
    } else {
      siteLayout.style.marginLeft = "250px";
      siteLayout.style.transition = `all ease-in-out ${0.1}s`;
      dashboardMenuRight.style.marginRight = "250px";
    }
  }
};

/**
 * Styles
 */
const DashboardContent = styled(Content)`
`;

const DashboardSider = styled(Sider)`
  /* background-color: #f7fafc !important; */
  background-color: #fff;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  width: 300px !important;
  max-width: 0;
  min-width: 0;
`;

const DashboardWrapper = styled.div`
  margin: 0 auto;
  /* background-color: #f7fafc !important; */
  background-color: #fff;
`;

const DashboardContainer = styled.div`
  padding-top: 120px;
  min-height: 100vh;
  max-width: 1470px;
  margin: 0 auto;
`;