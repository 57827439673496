import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import {
  ControlFilled,
  LoadingOutlined,
  ShopFilled,
  SettingFilled,
  HomeFilled,
  LineChartOutlined,
  TagsFilled,
  PictureFilled,
} from "@ant-design/icons";
import { useRoutes } from "../../../hooks.js";
import { useGetCompanyData } from "../../../hooks.js";

const VerticalNav = () => {
  const { routes } = useRoutes();
  const history = useHistory();
  const { companyData } = useGetCompanyData();
  const [businessName, setBuisnessName] = useState("");

  useEffect(() => {
    if (companyData !== null) {
      console.log(companyData);
      const { data } = companyData;
      const { name } = data;
      setBuisnessName(name);
    }
  }, [companyData]);

  return (
    <Menu
      theme="light"
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
        // backgroundColor: "#f7fafc",
      }}
      mode="inline"
    >
      <Menu.Item
        id="appTitle"
        key="0"
        onClick={() => history.push(routes.dashboard.home)}
        style={{
          // color: "#000000A6",
          fontSize: "16px",
          fontWeight: "600",
        }}
        icon={<ShopFilled style={{ fontSize: "16px" }} />}
      >
        {businessName !== "" ? (
          <span style={{ fontWeight: "600" }}>{businessName}</span>
        ) : (
          <LoadingOutlined />
        )}
      </Menu.Item>
      <br />
      <br />
      <Menu.Item
        aria-expanded={true}
        key="1"
        icon={<HomeFilled />}
        onClick={() => history.push(routes.dashboard.home)}
      >
        Advertiser Dashboard
      </Menu.Item>
      <Menu.Item
        aria-expanded={true}
        key="2"
        icon={<ControlFilled />}
        onClick={() => history.push(routes.dashboard.business.index)}
      >
        Business Profile
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<LineChartOutlined />}
        onClick={() => history.push(routes.dashboard.insights.index)}
      >
        Insights
      </Menu.Item>
      <Menu.Item
        aria-expanded={true}
        key="4"
        icon={<TagsFilled />}
        onClick={() => history.push(routes.dashboard.coupons.index)}
      >
        Coupons
      </Menu.Item>
      <Menu.Item
        aria-expanded={true}
        key="5"
        icon={<PictureFilled />}
        onClick={() => history.push(routes.dashboard.ads.index)}
      >
        Image Ads
      </Menu.Item>
      <Menu.Item
        key="6"
        icon={<SettingFilled />}
        onClick={() => history.push(routes.dashboard.account.index)}
      >
        Settings
      </Menu.Item>
    </Menu>
  );
};

export default VerticalNav;
