import React, { useEffect, useState } from "react";
import { Row, Col, Button, Dropdown } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LoadingOutlined,
  UserOutlined,
  MenuOutlined,
  SoundOutlined,
  ControlOutlined,
  SettingOutlined,
  LogoutOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  useFirebase,
  useRoutes,
  useGetCurrentUser,
  useWindowSize,
} from "../../../hooks.js";
import { enableLoader } from "../../../redux/actions/fullPageLoaderActions.js";
import styled from "styled-components";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../StyledComponents/styledComponents";
import { HeaderNavDrawer } from "./HeaderNavDrawer.js";
import FeedbackMenu from "./FeedbackMenu.js";

export const HeaderNav = ({ collapsed, setCollapsed }) => {
  const { routes } = useRoutes();
  const history = useHistory();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const size = useWindowSize();
  const { currentUser } = useGetCurrentUser();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (size.width > 800) {
      setVisible(false);
      setCollapsed(false);
    }

    if (size.width <= 800) {
      setCollapsed(true);
    }
  }, [size]);

  const handleSignOut = () => {
    firebase.doSignOut().then(() => {
      dispatch(enableLoader("Signing out..."));
      history.push(routes.auth.signIn);
    });
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleRedirect = (routeName) => {
    if (routeName === "coupons") {
      history.push(routes.dashboard.coupons.index);
      setVisible(false);
    } else if (routeName === "ads") {
      history.push(routes.dashboard.ads.index);
      setVisible(false);
    } else if (routeName === "account") {
      history.push(routes.dashboard.account.index);
      setVisible(false);
    }
  };

  return (
    <>
      <HeaderNavDrawer
        onClose={onClose}
        visible={visible}
        setVisible={setVisible}
        handleRedirect={handleRedirect}
      />
      <DashboardHeader id="adminDashboardHeader">
        <DashboardNav id="adminDashboardNav">
          <LeftNav id="dashboardMenuLeft">
            {collapsed ? (
              <Button
                type="text"
                size="large"
                onClick={() => setCollapsed(!collapsed)}
                icon={<MenuUnfoldOutlined />}
              />
            ) : (
              <Button
                type="text"
                size="large"
                onClick={() => setCollapsed(!collapsed)}
                icon={<MenuFoldOutlined />}
              />
            )}
          </LeftNav>
          <RightNav id="dashboardMenuRight">
            {size.width <= 800 ? (
              <Button
                icon={<MenuOutlined />}
                size="large"
                onClick={() => setVisible(!visible)}
              />
            ) : (
              <NonMobileRightNav
                history={history}
                routes={routes}
                handleSignOut={handleSignOut}
                currentUser={currentUser}
              />
            )}
          </RightNav>
        </DashboardNav>
      </DashboardHeader>
    </>
  );
};

const NonMobileRightNav = ({ history, routes, handleSignOut, currentUser }) => (
  <>
    <Dropdown overlay={<FeedbackMenu />} placement="topRight" arrow>
      <StyledSoundOutlined style={{ marginRight: "20px", fontSize: "16px" }} />
    </Dropdown>
    <Dropdown
      getPopupContainer={(trigger) => trigger.parentNode}
      overlay={accountMenuItems({
        currentUser,
        history,
        routes,
        handleSignOut,
      })}
      placement="bottomRight"
      arrow
    >
      {currentUser !== null ? (
        <Button
          icon={<StyledUserOutlined style={{ fontSize: "16px" }} />}
          shape="round"
          size="large"
        ></Button>
      ) : (
        <Button icon={<LoadingOutlined />} shape="round" />
      )}
    </Dropdown>
  </>
);

const accountMenuItems = ({ currentUser, history, routes, handleSignOut }) => (
  <StyledMenu>
    <StyledMenuItem>
      <Row gutter={10}>
        <Col>
          <UserOutlined />
        </Col>
        <Col>
          <b>{currentUser !== null ? currentUser.email : "loading"}</b>
        </Col>
      </Row>
    </StyledMenuItem>
    <StyledMenuItem onClick={() => history.push(routes.dashboard.home)}>
      <HomeOutlined /> Advertiser Dashboard
    </StyledMenuItem>
    <StyledMenuItem
      onClick={() => history.push(routes.dashboard.business.index)}
    >
      <ControlOutlined /> Business Profile
    </StyledMenuItem>
    <StyledMenuItem
      onClick={() => history.push(routes.dashboard.account.index)}
    >
      <SettingOutlined /> Account Settings
    </StyledMenuItem>
    <StyledMenuItem danger onClick={() => handleSignOut()}>
      <LogoutOutlined /> Log Out
    </StyledMenuItem>
  </StyledMenu>
);

/**
 * Styled Components
 */
const DashboardNav = styled.div`
  display: grid;
  grid-template-columns: 1fr 9fr;
  background-color: #fff;
  /* background-color: #f7fafc !important; */
`;

const DashboardHeader = styled.div`
  background-color: #fff;
  /* background-color: #f7fafc !important; */
  border-bottom: 1px solid #d6d6d6;
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 10px 20px;
`;

const LeftNav = styled.div``;

const RightNav = styled.div`
  text-align: right;
  margin-right: 200px;
`;

const CreateSubline = styled.p`
  font-size: 14px;
  color: #676767;
`;

const StyledUserOutlined = styled(UserOutlined)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledSoundOutlined = styled(SoundOutlined)`
  &:hover {
    cursor: pointer;
  }
`;
