import React, { useState } from "react";
import {
  DatePicker,
  Row,
  Col,
  Form,
  Input,
  Modal,
  message,
  Button,
} from "antd";
// import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  useFirebase,
  useGetCurrentUser,
  useConstants,
  useGetCoupon,
  useRoutes,
} from "../../../hooks.js";
import { useHistory } from "react-router-dom";
// import { humanizeDate } from "../../../utility/dateUtility.js";
import { Loader } from "../../../components/Loader.js";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";
import moment from "moment";

const ShowCouponPage = (props) => {
  // const { location } = props;
  // const { state } = location;
  // const { couponParams } = state;
  const { couponParams, handleCancel } = props;
  const { id } = couponParams;
  const dispatch = useDispatch();
  const history = useHistory();
  const { COLLECTIONS } = useConstants();
  const { routes } = useRoutes();
  const firebase = useFirebase();
  const { currentUser, currentUserData } = useGetCurrentUser();
  const { coupon } = useGetCoupon(id);
  const [visible, setVisible] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(true);

  // Delete redeemed because router can't serialize refs
  if (coupon) {
    delete coupon.data.redeemed;
  }

  const confirmDeleteLocation = () => {
    setVisible(true);
  };

  const handleOk = () => {
    if (currentUser !== null && currentUserData !== null) {
      const { id } = couponParams;
      const { companyRefPath } = currentUserData;
      const couponsRefPath = companyRefPath + "/" + COLLECTIONS.COUPONS;

      firebase
        .getDb()
        .deleteDocFromCollection(couponsRefPath, id)
        .then(() => {
          dispatch(enableFetch());
          handleCancel();
          dispatch(disableFetch());
          // history.push(routes.dashboard.coupons.index);
        })
        .then(() => {
          message.success("Successfully deleted coupon.");
        });
    }
  };

  const onFinish = () => {
    handleOk();
  };

  const handleCancelDeleteModal = () => {
    setVisible(false);
  };

  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      <Modal
        title="Confirm Delete"
        visible={visible}
        handleCancel={handleCancelDeleteModal}
        footer={
          <ModalFooterActions actions={{ handleCancelDeleteModal, handleOk }} />
        }
      >
        <p>Delete this coupon?</p>
      </Modal>
      <br />
      {coupon === null ? (
        <Loader />
      ) : (
        <>
          <Form
            {...formLayout}
            name="basic"
            layout="horizontal"
            onFinish={onFinish}
          >
            <Form.Item
              disabled={toggleEdit ? true : false}
              label="Headline"
              name="headline"
              initialValue={coupon.data.headline}
              rules={[{ required: true, message: "Please input a headline." }]}
            >
              <Input.TextArea
                disabled={toggleEdit ? true : false}
                autoSize
              />
            </Form.Item>

            <Form.Item
              label="Subline"
              name="subline"
              initialValue={
                typeof coupon.data.subline === "undefined"
                  ? ""
                  : coupon.data.subline
              }
            >
              <Input.TextArea disabled={toggleEdit ? true : false} autoSize />
            </Form.Item>

            <Form.Item
              label="Fineprint"
              name="finePrint"
              initialValue={
                typeof coupon.data.finePrint === "undefined"
                  ? ""
                  : coupon.data.finePrint
              }
            >
              <Input disabled={toggleEdit ? true : false} />
            </Form.Item>

            <Row gutter={[20, 0]}>
              <Col span={12}>
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  initialValue={
                    typeof coupon.data.startDate === "undefined"
                      ? ""
                      : moment(coupon.data.startDate.toDate())
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter a start date.",
                    },
                  ]}
                >
                  <DatePicker
                    disabled={toggleEdit ? true : false}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Expiration Date"
                  name="expires"
                  initialValue={moment(coupon.data.expires.toDate())}
                  rules={[
                    {
                      required: true,
                      message: "Please enter an expiration date.",
                    },
                  ]}
                >
                  <DatePicker disabled={toggleEdit ? true : false} />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Row gutter={10} justify="end">
                <Col>
                  <Button
                    className="sm-box-shadow"
                    shape="round"
                    block
                    onClick={() => handleCancel()}
                    // onClick={() => history.push(routes.dashboard.coupons.index)}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="sm-box-shadow"
                    shape="round"
                    block
                    type="danger"
                    htmlType="submit"
                  >
                    Delete Coupon
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};

const ModalFooterActions = ({ actions }) => {
  const { handleCancelDeleteModal, handleOk } = actions;
  return (
    <Row span={24} gutter={10}>
      <Col offset={11}>
        <Button
          block
          onClick={() => handleCancelDeleteModal()}
          shape="round"
          htmlType="submit"
          type="default"
          size="large"
        >
          Cancel
        </Button>
      </Col>
      <Col>
        <Button
          block
          onClick={() => handleOk()}
          danger
          shape="round"
          htmlType="submit"
          type="primary"
          size="large"
        >
          Delete Coupon
        </Button>
      </Col>
    </Row>
  );
};

export default ShowCouponPage;
