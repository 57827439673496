import React, { useState, useEffect } from "react";
import { Card, Row, Col, Steps, Button } from "antd";
import { CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useFirebase } from "../../hooks.js";

const stripePromise = loadStripe(
  "pk_test_51Hdo7KF5SQs16hknvqi1FlVVKra0y579wwofhHUuH70DwKZrEK3yw0f4XztReNE1EeyAUPz0gZcSa0KqP8t9BlTj00PbERqCB8"
);

// Don't call loadStripe in the render method
// don't want to load more than you have to

const { Step } = Steps;

const ConfirmSubscription = (props) => {
  const { history } = props;
  const { location } = history;
  const { state } = location;
  console.log(state);
  const firebase = useFirebase();
  // const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleFormSubmit = () => {
    // Create customer with stripe
    createCustomer();
    // Save to Firestore
    // Create payment intent
    // Charge
  };

  const createCustomer = async () => {
    await firebase.doCreateCustomer().then((data) => {
      console.log("data ==> ", data);
    });
  };

  // stripe.com/docs/js
  const cardElementOptions = {
    // a way to inject styles into that iframe
    style: {
      base: {
        fontSize: "16px",
      },
      invalid: {
        color: "#ff4d4f",
        fontSize: "16px",
      },
      complete: {
        color: "#52c41a",
        fontSize: "16px",
      },
    },
    hidePostalCode: true,
  };

  return (
    <div style={{ margin: "0 auto", maxWidth: "740px" }}>
      <form onSubmit={handleFormSubmit}>
        <Row span={24} gutter={[0, 30]}>
          <Col span={12}>
            <Steps size="small" current={1}>
              <Step title="Step 1" />
              <Step title="Step 2" />
            </Steps>
          </Col>
          <Col span={24}>
            <h1>Confirm Subscription</h1>
          </Col>
        </Row>

        <Card className="box-shadow">
          <Row style={{ padding: "20px 0 20px 0" }}>
            <Col span={20}>
              <b>AFTER 30 DAYS $199.99/mo.</b>
              <br />
              Basic Plan
            </Col>
            <Col span={4} align="right">
              <Button type="link" onClick={() => window.history.back()}>
                Change
              </Button>
            </Col>
          </Row>
          {/* <Row style={{ padding: "20px 0 20px 0" }}>
          <Col span={20}>
            <b>VISA ending in 0303</b>
            <br />
            Jon Doe
          </Col>
          <Col span={4} align="right">
            <Button type="link">Edit</Button>
          </Col>
        </Row> */}
        </Card>
        <Row style={{ padding: "20px 0 20px 0" }}>
          <Col span={24} className="box-shadow" style={{ padding: "20px" }}>
            <Elements stripe={stripePromise}>
              <CardElement options={cardElementOptions} />
            </Elements>
          </Col>
        </Row>
        <br />
        <Row span={24} style={{ marginTop: "40px" }}>
          <Col offset={7} span={10}>
            <Button
              type="primary"
              shape="round"
              block
              size="large"
              disabled={!isProcessing ? true : false}
            >
              START MEMBERSHIP
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default ConfirmSubscription;
