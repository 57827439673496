import React, { useState, useEffect } from "react";
import { H3 } from "../../StyledComponents/styledComponents.js";
import { Button } from "antd";
import { EditEmailForm } from "./EditEmailForm.js";
import { useGetCurrentUser } from "../../hooks.js";
import { Loader } from "../../components/Loader.js";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

export const EmailSection = () => {
  const { currentUser } = useGetCurrentUser();
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (currentUser !== null || currentUser !== undefined) {
      console.log("currentUser: ", currentUser);
      setUser(currentUser);
    }
  }, [currentUser]);

  if (!user) {
    return <Loader />;
  }

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
        <div>
          <H3>Email Address</H3>
          Your email address is <b>{user.email}</b>
        </div>
        <div style={{ textAlign: "right" }}>
          {showEditEmail ? (
            <Button
              className="sm-box-shadow"
              shape="round"
              size="medium"
              onClick={() => setShowEditEmail(false)}
              icon={<UpOutlined />}
            >
              Close
            </Button>
          ) : (
            <Button
              className="sm-box-shadow"
              shape="round"
              size="medium"
              onClick={() => setShowEditEmail(true)}
              icon={<DownOutlined />}
            >
              Expand
            </Button>
          )}
        </div>
      </div>
      <br />
      {showEditEmail ? <EditEmailForm /> : <></>}
    </div>
  );
};
