import React, { useState } from "react";
import { Button, Form, Input, DatePicker, message, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useFirebase, useConstants, useToolTipText } from "../../../hooks";
import FormLabelTooltip from "../../../components/FormLabelTooltip";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions.js";

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

/**
 *
 * @param {Object} currentBusiness
 * @param {Function} handleCancel
 * Creates a new coupon for the current business.
 */
const NewCouponForm = ({ currentBusiness, handleCancel }) => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const { COLLECTIONS } = useConstants();
  const { TOOL_TIP_TEXT } = useToolTipText();
  const [processing, setProcessing] = useState(false);

  const onFinish = (values) => {
    setProcessing(true);
    const startDate = values.startDate.toDate();
    const expires = values.expires.toDate();
    const newCoupon = { ...values, startDate, expires };
    const refPath = `/companies/${currentBusiness.id}`;

    firebase
      .getDb()
      .addDataToSubCollection(refPath, COLLECTIONS.COUPONS, newCoupon)
      .then(() => {
        dispatch(enableFetch());
        handleCancel();
        message.success(`Successfully added ${newCoupon.headline} coupon.`);
        dispatch(disableFetch());
        setProcessing(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Form
      {...formLayout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label={
          <FormLabelTooltip
            name="Headline"
            tooltipText={TOOL_TIP_TEXT.headline}
          />
        }
        name="headline"
        rules={[{ required: true, message: "Please input a headline" }]}
      >
        <Input.TextArea autoSize />
      </Form.Item>

      <Form.Item
        label={
          <FormLabelTooltip
            name="Subline"
            optional="optional"
            tooltipText={TOOL_TIP_TEXT.subline}
          />
        }
        name="subline"
        initialValue=""
      >
        <Input.TextArea autoSize />
      </Form.Item>

      <Form.Item
        label={
          <FormLabelTooltip
            name="Fineprint"
            optional="optional"
            tooltipText={TOOL_TIP_TEXT.fineprint}
          />
        }
        name="finePrint"
        initialValue="Coupon not to be used in combination with any other offer."
      >
        <Input />
      </Form.Item>

      <Row span={24}>
        <Col span={12}>
          <Form.Item
            label={
              <FormLabelTooltip
                name="Start Date"
                tooltipText={TOOL_TIP_TEXT.startDate}
              />
            }
            name="startDate"
            rules={[{ required: true, message: "Please enter a start date." }]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormLabelTooltip
                name="Expiration Date"
                tooltipText={TOOL_TIP_TEXT.expirationDate}
              />
            }
            name="expires"
            rules={[
              { required: true, message: "Please enter an expiration date." },
            ]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Row gutter={10} justify="end">
          {processing ? (
            <Col>
              <Button
                disabled={processing}
                type="primary"
                htmlType="submit"
                className="sm-box-shadow"
                shape="round"
                icon={<LoadingOutlined />}
              >
                Creating Coupon
              </Button>
            </Col>
          ) : (
            <>
              <Col>
                <Button
                  onClick={() => handleCancel()}
                  shape="round"
                  size="large"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  size="large"
                >
                  Add Coupon
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form.Item>
    </Form>
  );
};

export default NewCouponForm;
