import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  StyledMenuItem,
  StyledMenu,
} from "../../../StyledComponents/styledComponents";
import {
  Typography,
  Button,
  Menu,
  Dropdown,
  Tag,
  Row,
  Col,
  Card,
  Spin,
} from "antd";
import {
  LineChartOutlined,
  TagOutlined,
  DownOutlined,
  Loading3QuartersOutlined,
  PicLeftOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import EditBusinessModal from "../Business/EditBusinessModal.js";
import { useGetCompanyData, useRoutes } from "../../../hooks.js";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NewCouponModal from "../../Coupons/components/NewCouponModal";
import NewLocationModal from "../Locations/NewLocationModal";
import NewAdModal from "../../ImageAds/components/NewAdModal";

const { Title, Text, Paragraph } = Typography;
const { Item } = Menu;

export default function BusinessProfile() {
  const { routes } = useRoutes();
  const history = useHistory();
  const { companyData } = useGetCompanyData();
  const { componentLoaderReducer } = useSelector((state) => state);
  const [currentBusiness, setCurrentBusiness] = useState(null);

  useEffect(() => {
    if (companyData) {
      const { id, data } = companyData;
      setCurrentBusiness({ id, ...data });
    }
  }, [companyData, componentLoaderReducer]);

  if (!currentBusiness) {
    return (
      <>
        <Row span={24} style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Card
              style={{
                height: "200px",
                textAlign: "center",
                fontSize: "24px",
              }}
            >
              <Spin indicator={<Loading3QuartersOutlined spin />} />
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  if (currentBusiness) {
    return (
      <>
        <Card className="sm-box-shadow round">
          <Row justify="end" gutter={[10, 0]}>
            <Col>
              <EditBusinessModal currentBusiness={currentBusiness} />
            </Col>
            <Col>
              <Dropdown
                getPopupContainer={(trigger) => trigger.parentNode}
                overlay={createMenuItems({ history, routes })}
                placement="bottomRight"
                arrow
              >
                <Button
                  shape="round"
                  id="createDropdownBtn"
                  size="large"
                  icon={<DownOutlined />}
                >
                  Add
                </Button>
              </Dropdown>
            </Col>
            <Col>
              <Button
                icon={<LineChartOutlined />}
                shape="round"
                onClick={() => history.push(routes.dashboard.insights.index)}
                size="large"
              >
                View Insights
              </Button>
            </Col>
          </Row>
          <Row gutter={[40, 0]}>
            <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={24}>
              {currentBusiness.logo === undefined ? (
                <div
                  style={{
                    border: "1px solid #d3d3d3",
                    height: "140px",
                    width: "140px",
                    backgroundColor: "#eee",
                  }}
                ></div>
              ) : (
                <BusinessLogo
                  src={currentBusiness.logo}
                  draggable={false}
                  alt="logo"
                />
              )}
            </Col>

            <Col xxl={19} xl={19} lg={19} md={19} sm={19} xs={24}>
              <Row gutter={[0, 10]}>
                <Col span={24}>
                  <Title level={3}>{currentBusiness.name}</Title>
                </Col>
                <Col span={24}>
                  {currentBusiness.active ? (
                    <Tag color="green">active</Tag>
                  ) : (
                    <Tag color="red">inactive</Tag>
                  )}
                </Col>
                <Col span={24}>{currentBusiness.category}</Col>
                <Col span={24}>{currentBusiness.description}</Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}

const createMenuItems = () => (
  <StyledMenu>
    <StyledMenuItem>
      <NewLocationModal
        modalAction={
          <>
            <EnvironmentOutlined /> Add new location
          </>
        }
      />
    </StyledMenuItem>
    <StyledMenuItem>
      <NewAdModal
        modalAction={
          <>
            <PicLeftOutlined /> Create a new image ad
          </>
        }
      />
    </StyledMenuItem>
    <StyledMenuItem>
      <NewCouponModal
        modalAction={
          <>
            <TagOutlined /> Create a new coupon
          </>
        }
      />
    </StyledMenuItem>
  </StyledMenu>
);

const BusinessLogo = styled.img`
  height: 140px;
  width: 140px;
`;
