import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useGetTickets } from "../../hooks";
import { Row, Col, Table } from "antd";
import moment from "moment";

export default function Tickets() {
  const { ticketsData } = useGetTickets();
  const [tickets, setTickets] = useState(null);

  useEffect(() => {
    if (ticketsData) {
      let tmpData = [];
      ticketsData.forEach((ticket, idx) => {
        const { docData } = ticket;
        const { created } = docData;
        const createdAt = moment(created.toDate()).format(
          "dddd, MMMM Do YYYY, h:mm:ss a"
        );
        const ticketObj = { ...docData, key: idx, createdAt };
        tmpData.push(ticketObj);
      });
      setTickets(tmpData);
    }
  }, [ticketsData]);

  if (ticketsData === null) {
    return "Loading";
  }

  if (ticketsData) {
    if (ticketsData.length === 0) {
      return "No Tickets yet";
    }
  }

  if (ticketsData) {
    return (
      <Wrapper>
        <Container>
          <Row gutter={[30, 30]}>
            <Col lg={24}>
              <TicketsTitle>Tickets</TicketsTitle>
            </Col>
            <Col lg={24}>
              <Table
                columns={columns}
                dataSource={tickets}
                expandable={{
                  expandedRowRender: (record) => (
                    <p style={{ margin: 0 }}>{record.message}</p>
                  ),
                  rowExpandable: (record) => record.key !== "Not Expandable",
                }}
              />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  }
}

const columns = [
  { title: "Status", dataIndex: "status", key: "status" },
  { title: "Mood", dataIndex: "mood", key: "mood" },
  { title: "Business", dataIndex: "company", key: "company" },
  { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
  {
    title: "Action",
    dataIndex: "",
    key: "x",
    render: () => <span>Delete</span>,
  },
];

const Wrapper = styled.div`
  margin: 0 auto;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1080px;
`;

const TicketsTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
`;
