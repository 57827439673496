import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "antd";
// import { useFirebase, useGetCompanyData } from "../../../hooks.js";
// import { useDispatch } from "react-redux";
import NewCouponForm from "../../Coupons/NewCoupon/NewCouponForm.js";

// const layout = {
//   labelCol: { span: 24 },
//   wrapperCol: { span: 24 },
// };

export default function NewCouponModal(props) {
  const { modalAction } = props;
  // const { companyData } = useGetCompanyData();
  // const firebase = useFirebase();
  // const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const handleCancel = () => {
    setVisible(false);
  };

  // const onFinish = (value) => {
  // };

  // const onFinishFailed = () => {};

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <a onClick={showModal}>{modalAction}</a>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
        title="Create a new coupon"
      >
        <Row
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "6px",
            marginBottom: "6px",
          }}
        >
          <Col span={24}>
            <NewCouponForm handleCancel={handleCancel} />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
