import React from "react";
import { NewImageAdForm } from "./NewImageAdForm.js";
import { Container } from "../../../StyledComponents/styledComponents.js";
import { Typography } from "antd";
import AppPageHeader from "../../../components/AppPageHeader.js";

const { Title } = Typography;

const NewImageAdPage = () => {
  return (
    <Container>
      <AppPageHeader />
      <br />
      <Title level={3}>New Ad</Title>
      <p style={{ fontSize: "14px" }}>
        Image ads will be used as a form of advertisements. They are not redeemable through the
        mobile application.
      </p>
      <br />
      <NewImageAdForm />
    </Container>
  );
};

export default NewImageAdPage;
