import React, { useEffect, useState } from "react";
import { Input, Button, message, Upload, Form, DatePicker, Row, Col } from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useToolTipText } from "../../../hooks.js";
import {
  useFirebase,
  useGetCompanyData,
  useConstants,
} from "../../../hooks.js";
// import { useHistory } from "react-router-dom";
import FormLabelTooltip from "../../../components/FormLabelTooltip";
import { enableFetch } from "../../../redux/actions/componentLoaderActions.js";
import { useDispatch } from "react-redux";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const NewImageAdForm = (props) => {
  const { handleCancel } = props;
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const { companyData } = useGetCompanyData();
  const { COLLECTIONS } = useConstants();
  // const { routes } = useRoutes();
  const { TOOL_TIP_TEXT } = useToolTipText();
  // const history = useHistory();
  const [imageUrl, setImageUrl] = useState("");
  const [aspectRatio, setAspectRatio] = useState(1);
  const [loading, setLoading] = useState("");
  const [toggleRemember, setToggleRemember] = useState(true);

  useEffect(() => {}, [loading, toggleRemember]);

  // Handle form submit
  const onFinish = (values) => {
    setLoading(true);

    const startDate = values.startDate.toDate();
    const expires = values.expires.toDate();

    const { image } = values;
    const { file } = image;
    firebase.doUploadFile(file, "imageCoupons").then((data) => {
      const { downloadURL } = data;
      const url = downloadURL; // create the property to add to coupon
      delete values.image; // we don't need the image property anymore
      const type = "image";
      const couponToUpload = {
        ...values,
        startDate,
        expires,
        url,
        aspectRatio,
        type,
      };

      const refPath = COLLECTIONS.COMPANIES + "/" + companyData.id;

      // Save coupon to database and send a message
      firebase
        .getDb()
        .addDataToSubCollection(refPath, COLLECTIONS.COUPONS, couponToUpload)
        .then(() => {
          message.success(`Successfully added Ad.`);
        });
      setLoading(false);
      dispatch(enableFetch());
      handleCancel();
      setToggleRemember(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleOnUploadChange = (info) => {
    console.log("info: ", info);

    // We don't want multiple images uploaded.
    let { file } = info;
    let newFileList = [file];
    info.fileList = newFileList;

    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, async (imageUrl) => {
        const dimensions = await getImageDimensions(imageUrl);
        const ratio = (dimensions.w / dimensions.h).toFixed(2);
        setImageUrl(imageUrl);
        setAspectRatio(parseFloat(ratio));
        setLoading(false);
      });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  function getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.onload = function () {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  const handleRemoveImage = () => {
    setImageUrl("");
  };

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{ remember: toggleRemember }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item label="Link" name="link" initialValue="">
        <Input size="large" />
      </Form.Item>

      <Form.Item
        label={
          <FormLabelTooltip
            name="Image"
            tooltipText="Image ads are a great place to display a promotion, product shot, or bar code."
          />
        }
        name="image"
        rules={[{ required: true, message: "Please upload an image." }]}
      >
        <Upload.Dragger
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleOnUploadChange}
          multiple={false}
          disabled={imageUrl !== ""}
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess("ok");
            }, 0);
          }}
        >
          {imageUrl ? (
            <Row span={24}>
              <Col span={24}>
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              </Col>
              <Col span={24} style={{ paddingTop: "10px" }}>
                <Button type="link" onClick={handleRemoveImage}>
                  <DeleteOutlined
                    style={{ fontSize: "18px", position: "relative" }}
                  />
                  Remove Image
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              {uploadButton}
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Files must be smaller than 2mb</p>
            </>
          )}
        </Upload.Dragger>
      </Form.Item>

      <Row span={24} gutter={[30, 0]}>
        <Col span={12}>
          <Form.Item
            label={
              <FormLabelTooltip
                name="Start Date"
                tooltipText={TOOL_TIP_TEXT.startDate}
              />
            }
            name="startDate"
            rules={[{ required: true, message: "Please enter a start date." }]}
          >
            <DatePicker size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormLabelTooltip
                name="Expiration Date"
                tooltipText={TOOL_TIP_TEXT.expirationDate}
              />
            }
            name="expires"
            rules={[
              { required: true, message: "Please enter an expiration date." },
            ]}
          >
            <DatePicker size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Row gutter={10} justify="end">
          <Col>
            <Button
              shape="round"
              block
              onClick={() => handleCancel()}
              size="large"
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              shape="round"
              block
              type="primary"
              htmlType="submit"
              size="large"
            >
              Create
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};
