import React, { useEffect, useState } from "react";
import { Button, Card, Tabs, Typography, Tag, Row, Col, Table } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useFirebase } from "../../hooks";
import TableActions from "./components/TableActions.js";
import NewBusinessModal from "./components/NewBusinessModal";
import { useSelector } from "react-redux";
import SearchBusinessesInput from "./components/SearchBusinessesInput";
import {
  ActiveBusinessesTable,
  BusinessesTable,
  InactiveBusinessesTable,
} from "./components/BusinessesTables";

const { TabPane } = Tabs;
const { Title } = Typography;

export default function Businesses() {
  const { componentLoaderReducer } = useSelector((state) => state);
  const firebase = useFirebase();
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState(null);
  const [companiesMap, setCompaniesMap] = useState({});
  const [numRedeemed, setNumRedeemed] = useState(null);
  const [searchToggle, setSearchToggle] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showNewBusinessModal, setShowBusinessModal] = useState(false);

  const [searchResults, setSearchResults] = useState(null);
  const [activeSearchResults, setActiveSearchResults] = useState(null);
  const [inactiveSearchResults, setInactiveSearchResults] = useState(null);

  const [activeCompanies, setActiveCompanies] = useState(null);
  const [inactiveCompanies, setInactiveCompanies] = useState(null);

  // useEffect to get users
  useEffect(() => {
    firebase
      .getDb()
      .doFirestoreGetCollection("users")
      .then((collectionList) => {
        setUsers(collectionList);
      });
  }, [firebase, processing, componentLoaderReducer.loading]);

  // useEffect to get companies
  useEffect(() => {
    firebase
      .getDb()
      .doFirestoreGetCollection("companies")
      .then((collectionList) => {
        const tempCompanies = [];
        collectionList.forEach((comp) => {
          const { docData, docId } = comp;
          tempCompanies.push({
            ...docData,
            id: docId,
            key: docId,
            spotlightsCount: docData.spotlight ? docData.spotlight.length : 0,
            locationsCount: docData.locations ? docData.locations.length : 0,
          });
        });
        setCompanies(tempCompanies);
      });
  }, [firebase, processing, componentLoaderReducer.loading]);

  // useEffect for how many redeemed for each company
  useEffect(() => {
    let tempCompaniesMap = companiesMap;
    users.forEach((user) => {
      const { docData } = user;
      if (docData.redeemed) {
        docData.redeemed.forEach((coupRef) => {
          const pathArray = coupRef.path.split("/");
          const compId = pathArray[1];
          try {
            if (!tempCompaniesMap[compId]) {
              tempCompaniesMap[compId] = { numRedeemed: 1 };
            } else {
              tempCompaniesMap[compId].numRedeemed =
                tempCompaniesMap[compId].numRedeemed + 1;
            }
          } catch (error) {
            console.log("error:", error);
          }
        });
      }
    });
    setCompaniesMap({ ...tempCompaniesMap });
  }, [users, processing, componentLoaderReducer.loading]);

  useEffect(() => {
    if (companies) {
      setProcessing(true);
      const tempCompanies = [];
      let tempTotalRedeemed = 0;
      companies.forEach((company) => {
        const mapData = companiesMap[company.id];
        if (mapData) {
          tempCompanies.push({
            ...company,
            active: company.active,
            category: company.category,
            key: company.id,
            numRedeemed: mapData.numRedeemed,
          });
          tempTotalRedeemed += mapData.numRedeemed;
        } else {
          tempCompanies.push({ ...company, key: company.id, numRedeemed: 0 });
        }
      });

      setCompanies(tempCompanies);
      setNumRedeemed(tempTotalRedeemed);

      // Sort the search results by active
      setActiveCompanies(() =>
        tempCompanies.filter((company) => company.active === true)
      );
      setInactiveCompanies(() =>
        tempCompanies.filter((company) => company.active === false)
      );
      setProcessing(false);
    }
  }, [
    processing,
    searchToggle,
    searchResults,
    companiesMap,
    inactiveSearchResults,
    activeSearchResults,
    componentLoaderReducer.loading,
  ]);

  const handleCloseModal = () => {
    setShowBusinessModal(false);
  };

  const handleSearch = (value) => {
    setProcessing(true);
    if (companies) {
      const result = companies.filter(
        (company) =>
          company.name.toLowerCase().includes(value.toLowerCase().trim()) ||
          company.id.includes(value.trim()) ||
          company.category.includes(value.toUpperCase().trim())
      );

      setSearchResults(result);
      setActiveSearchResults(() =>
        result.filter((company) => company.active === true)
      );
      setInactiveSearchResults(() =>
        result.filter((company) => company.active === false)
      );
      setSearchToggle(true);
      setProcessing(false);
    }
  };

  if (processing) {
    return <LoadingOutlined />;
  }

  /**
   * If companies is null (purgatory between fetched and not fetched)
   * we load until result comes back with empty or not empty array.
   */
  if (!companies) {
    return (
      <Card>
        <Row gutter={[10, 0]} justify="center">
          <Col>
            <LoadingOutlined />
          </Col>
          <Col>Retrieving businesses...</Col>
        </Row>
      </Card>
    );
  }

  /**
   * dataSource props displays data based on search filter is toggled or not
   */
  return (
    <>
      <NewBusinessModal
        showNewBusinessModal={showNewBusinessModal}
        handleCloseModal={handleCloseModal}
      />
      <Card>
        <Row>
          <Col span={12}>
            <Title level={1}>Businesses</Title>
          </Col>
          <Col span={12}>
            <Row justify="end" style={{ paddingTop: "10px" }}>
              {processing && numRedeemed ? (
                <LoadingOutlined />
              ) : (
                <>
                  <Tag>Total Redeemed: {numRedeemed}</Tag>
                  <Tag>Total Businesses: {companies.length}</Tag>
                </>
              )}
            </Row>
          </Col>
        </Row>

        <Row gutter={[20, 0]} justify="end">
          <Col span={10}>
            <SearchBusinessesInput handleSearch={handleSearch} />
          </Col>
          <Col>
            <Button
              shape="round"
              icon={<PlusOutlined />}
              onClick={() => setShowBusinessModal(true)}
            >
              New
            </Button>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="All" key="1">
                <BusinessesTable
                  searchToggle={searchToggle}
                  searchResults={searchResults}
                  companies={companies}
                  columns={columns}
                />
              </TabPane>
              <TabPane tab="Active" key="2">
                <ActiveBusinessesTable
                  searchToggle={searchToggle}
                  activeSearchResults={activeSearchResults}
                  activeCompanies={activeCompanies}
                  columns={columns}
                />
              </TabPane>
              <TabPane tab="Inactive" key="3">
                <InactiveBusinessesTable
                  searchToggle={searchToggle}
                  inactiveSearchResults={inactiveSearchResults}
                  inactiveCompanies={inactiveCompanies}
                  columns={columns}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </Card>
    </>
  );
}

/**
 * Table Columns
 */
const columns = [
  {
    title: "Status",
    dataIndex: "active",
    key: "active",
    render: (active) => (
      <>
        {active ? (
          <Tag color="green">active</Tag>
        ) : (
          <Tag color="red">inactive</Tag>
        )}
      </>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: "Category",
    dataIndex: "category",
    key: "category",
    sorter: (a, b) => a.category.localeCompare(b.category),
  },
  {
    title: "Locations",
    dataIndex: "locationsCount",
    key: "locationsCount",
    sorter: (a, b) => b.locationsCount - a.locationsCount,
    render: (locationsCount) => (
      <div style={{ textAlign: "center" }}>{locationsCount}</div>
    ),
  },
  {
    title: "Spotlights",
    dataIndex: "spotlightsCount",
    key: "spotlightsCount",
    sorter: (a, b) => b.spotlightsCount - a.spotlightsCount,
    render: (spotlightsCount) => (
      <div style={{ textAlign: "center" }}>{spotlightsCount}</div>
    ),
  },
  {
    title: "Redeemed",
    dataIndex: "numRedeemed",
    key: "numRedeemed",
    sorter: (a, b) => b.numRedeemed - a.numRedeemed,
    render: (numRedeemed) => (
      <div style={{ textAlign: "center" }}>{numRedeemed}</div>
    ),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (actions, business) => <TableActions business={business} />,
  },
];
