import moment from "moment";

/**
 * DateFilter filters by days, the number of redeemed and impressions on coupons by count.
 */
export default class DataFilter {
  static LL = "LL";
  static YYYY_MM_DD = "YYYY-MM-DD";

  /**
   *
   * @param {Integer} filterByDays
   *
   * Receives the number of days to filter by. Calculates the new floor and date range.
   *
   * Returns an object with the new floor and ceiling.
   */
  static updateDateRange(filterByDays) {
    let newCeilingDate = moment().format(this.LL);
    let newFloorDate = moment().subtract(filterByDays, "days").format(this.LL);
    return { newFloorDate, newCeilingDate };
  }

  // Filters the data by day
  static filterByDaysAgo(day, data) {
    let currentDate = moment().format(this.YYYY_MM_DD);
    let last30Days = moment().subtract(day, "days").format(this.YYYY_MM_DD);

    let filteredData = [];
    let isDateBetween = false;
    let itemDate = null;
    data.forEach((item, idx) => {
      itemDate = moment(item.date).format(this.YYYY_MM_DD);

      // Set the inclusivity to "(]", so it includes the current date.
      // isDateBetween = moment(itemDate).isBetween(last30Days, currentDate);
      isDateBetween = moment(itemDate).isBetween(
        last30Days,
        currentDate,
        undefined,
        "[]"
      );
      if (isDateBetween) {
        filteredData.push(item);
      }
    });
    return filteredData;
  }

  /**
   *
   * @param {Integer} days
   * @param {Object} data
   *
   * Calculates the count of dates and returns an array of objects with 2 properties: { date, value }.
   *
   * Retruns the sorted array.
   *
   * This is format is required for AntV Chart object.
   */
  static normalizeData(days, data) {
    // Store dates into a new dates array
    let datesArr = [];
    data.forEach((item, idx) => {
      datesArr.push(item.date);
    });

    // Count the occurences for each of dates and store into an array
    const countOccurrences = (datesArr) =>
      datesArr.reduce(
        (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
        {}
      );
    const uniqueRedeemed = countOccurrences(datesArr);

    // Creates a normalized array with properties required for AntV G2 Chart.
    let normalizedData = [];
    for (const property in uniqueRedeemed) {
      let dateRedeemed = property;
      let maxRedeemedCount = uniqueRedeemed[property];
      normalizedData.push({ date: dateRedeemed, value: maxRedeemedCount });
    }

    // Filter the normalized data by given days
    const filteredNormalizedData = this.filterByDaysAgo(days, normalizedData);
    const sortedData = filteredNormalizedData.sort(
      (a, b) =>
        moment(a.date).format("YYYYMMDD") - moment(b.date).format("YYYYMMDD")
    );

    sortedData.forEach((item, idx) => {
      let shortenedDate = moment(item.date).format("MM/DD ddd");
      item.date = shortenedDate;
    });

    let currentDate = moment().format("MM/DD ddd");
    let lastItem = sortedData.slice(-1)[0];
    if (lastItem !== undefined) {
      if (lastItem.date !== currentDate) {
        sortedData.push({ date: currentDate, value: 0 });
      }
    }

    // console.log('sorted data: ', sortedData);

    // Return data
    return sortedData;
  }
}
