import React from "react";
import { Tabs, Card } from "antd";
import LocationsPage from "../../Locations/LocationsPage";
import ImageAdsList from "../../ImageAds/components/ImageAdsList";
import CouponsList from "../../Coupons/components/CouponsList";
import SpotlightsList from "../../Spotlights/components/SpotlightsList";

const { TabPane } = Tabs;

function callback(key) {} // Call back for tabs

const BusinessTabs = (props) => {
  const { currentBusiness } = props;

  return (
    <Card className="sm-box-shadow round">
      <Tabs defaultActiveKey="1" onChange={callback} type="card">
        <TabPane tab="Image Ads" key="1">
          <ImageAdsList currentBusiness={currentBusiness} />
        </TabPane>
        <TabPane tab="Coupons" key="2">
          <CouponsList currentBusiness={currentBusiness} />
        </TabPane>
        <TabPane tab="Locations" key="3">
          <LocationsPage currentBusiness={currentBusiness} />
        </TabPane>
        <TabPane tab="Spotlights" key="4">
          <SpotlightsList currentBusiness={currentBusiness} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default BusinessTabs;
