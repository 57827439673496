import React, { useEffect, useReducer, useState } from "react";
import DataFilter from "./DataFilter";
import { Typography, Row, Col } from "antd";
import {
  LoadingOutlined,
  LineChartOutlined,
  ArrowsAltOutlined,
  FilterFilled
} from "@ant-design/icons";
import moment from "moment";
import RedeemedOvertimeLineChart from "./RedeemedOvertimeLineChart";
import DateFilterDropdown from "./DateFilterDropdown";

const { Title, Paragraph } = Typography;

const RedeemedOvertimeLineChartSection = (props) => {
  const { currentBusiness, couponsList } = props;
  const [normalizedCouponsData, setNormalizedCouponsData] = useState(null);
  const [days, setDays] = useState(180);
  const [colSize, setColSize] = useState(8);

  useEffect(() => {
    if (currentBusiness) {
      let normalizedCouponsList = [];

      couponsList.forEach((coupon, idx) => {
        if (coupon.redeemed !== undefined) {
          let redeemed = coupon.redeemed;

          redeemed.forEach((item, idx) => {
            // Make sure to check whether the date has already been converted to astring
            const isString = Object.prototype.toString.call(item.date) === "[object String]";
            if (!isString) {
              item.date = moment(item.date.toDate()).format("MM/DD/YYYY");
              item.userRef = item.userRef.id;
            }
          });

          const normalizedData = DataFilter.normalizeData(days, redeemed);

          // Modify the data for presentation
          normalizedCouponsList.push({
            ...coupon,
            startDate: moment(coupon.startDate.toDate()).format("MM/DD/YYYY ddd"),
            expires: moment(coupon.expires.toDate()).format("MM/DD/YYYY ddd"),
            redeemed: normalizedData,
          });
        }
      });

      setNormalizedCouponsData(normalizedCouponsList);
    }
  }, [couponsList, currentBusiness, days, colSize]);

  const changeDayFilter = (filterDays) => {
    setDays(filterDays);
  };

  const maximizeChart = () => {
    if (colSize === 24) {
      setColSize(8);
    } else {
      setColSize(24);
    }
  };

  if (!normalizedCouponsData) {
    return <LoadingOutlined />;
  }

  if (normalizedCouponsData) {
    return (
      <>
        <Row>
          <Col span={24}>
            <Title level={4}>
              <LineChartOutlined /> Coupons Redeemed Over Time
            </Title>
            <Paragraph>
              Click the{" "}
              <b>
                <ArrowsAltOutlined /> expand
              </b>{" "}
              button to view more details. Use the{" "}
              <b>
                <FilterFilled /> Filter
              </b>{" "}
              button to filter by days.
            </Paragraph>
          </Col>
          <Col span={24} style={{ textAlign: "right" }}>
            <DateFilterDropdown days={days} changeDayFilter={changeDayFilter} />
          </Col>
        </Row>
        <br />
        <Row gutter={[20, 10]}>
          {normalizedCouponsData.map((coupon, idx) => (
            <Col
              xxl={colSize}
              xl={colSize}
              lg={12}
              md={24}
              sm={24}
              xs={24}
              key={idx}
            >
              <RedeemedOvertimeLineChart
                coupon={{ key: idx, ...coupon }}
                colSize={colSize}
                maximizeChart={maximizeChart}
              />
            </Col>
          ))}
        </Row>
      </>
    );
  }
};

export default RedeemedOvertimeLineChartSection;
