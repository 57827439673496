import React from "react";
import { Table, Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const indicator = <Loading3QuartersOutlined style={{ fontSize: 64 }} spin />;

export const TableSpinner = () => (
  <Spin indicator={indicator} tip="" size="large" style={{ top: 20 }}>
    <Table size="default" bordered={true} />
  </Spin>
);