import React from "react";
import styled from "styled-components";
import { Typography, Row, Col, Card } from "antd";
import { SettingOutlined, ShopOutlined } from "@ant-design/icons";
import { routes } from "../../constants/routes.js";
import { useHistory } from "react-router-dom";

const QuickActions = () => {
  const history = useHistory();
  const colSizes = {
    xxl: 6,
    xl: 6,
    lg: 12,
    md: 24,
    sm: 24,
    xs: 24,
  };

  return (
    <Row gutter={[30, 30]}>
      <Col {...colSizes}>
        <DashboardActionCard
          handleClick={() => history.push(routes.admin.businesses.index)}
          icon={<ShopOutlined />}
          label="View businesses"
          description="Find and manage a particular business."
        />
      </Col>
      <Col {...colSizes}>
        <DashboardActionCard
          icon={<SettingOutlined />}
          label="Account Settings"
          description="Change your email and password."
        />
      </Col>
    </Row>
  );
};

const DashboardActionCard = (props) => {
  const { icon, label, description, handleClick, disabled } = props;

  return (
    <ActionCard onClick={handleClick} className="sm-box-shadow round" disabled>
      <Row gutter={[0, 10]}>
        <Col span={24} align="center" style={{ fontSize: "32px" }}>
          {icon}
        </Col>
        <Col span={24} align="center">
          <h3>{label}</h3>
        </Col>
        {description ? (
          <Col
            span={24}
            align="center"
            style={{ borderTop: "1px solid #eee", paddingTop: "14px" }}
          >
            {description}
          </Col>
        ) : (
          <></>
        )}
      </Row>
    </ActionCard>
  );
};

const ActionCard = styled(Card)`
  background-color: #fff !important;
  height: 230px;
  border: 1px solid #d9d9d9;

  &:hover {
    cursor: pointer;
    box-shadow: 0 2px 3px 0 rgba(60, 64, 67, 0.3),
      0 2px 3px 0 rgba(60, 64, 67, 0.15) !important;
    background-color: #f6ffed;
  }

  &:active {
    cursor: pointer;
    box-shadow: none !important;
    background-color: #f6ffed;
    border: 1px solid #95de64;
  }
`;

export default QuickActions;
