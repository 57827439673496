import { fullPageLoaderInitState } from "../fullPageLoaderInitState.js";

export const fullPageLoaderReducer = (
  state = fullPageLoaderInitState,
  action
) => {
  const { message } = action;

  switch (action.type) {
    case "ENABLE":
      return { show: true, message };
    case "DISABLE":
      return { show: false, message };
    default:
      return state;
  }
};
