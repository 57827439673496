import React from "react";
import { Row, Col, PageHeader, Button, Typography } from "antd";
import { ShopFilled, RedoOutlined, LoadingOutlined } from "@ant-design/icons";
import { routes } from "../../../constants/routes";

const { Title, Paragraph } = Typography;

const InsightsPageHeader = (props) => {
  const { companyData, couponsList, history, fetchImpressionsCount } = props;
  const { data } = companyData;
  const currentBusiness = data;

  if (!currentBusiness) {
    return <LoadingOutlined />;
  }

  if (currentBusiness) {
    return (
      <PageHeader
        className="sm-box-shadow round"
        ghost={false}
        extra={[
          <Button
            shape="round"
            icon={<RedoOutlined />}
            onClick={() => fetchImpressionsCount(currentBusiness, couponsList)}
            type="default"
            size="large"
          >
            Refresh Data
          </Button>,
          <Button
            shape="round"
            icon={<ShopFilled />}
            onClick={() => history.push(routes.dashboard.business.index)}
            type="default"
            size="large"
          >
            View Business
          </Button>,
        ]}
      >
        <Row gutter={[0, 20]}>
          <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={24}>
            <img
              draggable={false}
              src={currentBusiness.logo}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </Col>
          <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
            <Title level={1}> {currentBusiness.name} Insights</Title>
            <Paragraph>
              See how your image ads and coupons are performing.
            </Paragraph>
            <Paragraph>
              Click the{" "}
              <b>
                <RedoOutlined /> Refresh Button
              </b>{" "}
              to refresh the data.
            </Paragraph>
          </Col>
        </Row>
      </PageHeader>
    );
  }
};

export default InsightsPageHeader;
