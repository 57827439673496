import React, { useState } from "react";
import { H3 } from "../../StyledComponents/styledComponents.js";
import { Button } from "antd";
import { EditPasswordForm } from "./EditPasswordForm.js";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

export const PasswordSection = () => {
  const [expand, setExpand] = useState(false);

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
        <div>
          <H3>Password</H3>
        </div>
        <div style={{ textAlign: "right" }}>
          {expand ? (
            <Button
              className="sm-box-shadow"
              shape="round"
              size="medium"
              onClick={() => setExpand(false)}
              icon={<UpOutlined />}
            >
              Close
            </Button>
          ) : (
            <Button
              className="sm-box-shadow"
              shape="round"
              size="medium"
              onClick={() => setExpand(true)}
              icon={<DownOutlined />}
            >
              Expand
            </Button>
          )}
        </div>
      </div>
      <br />
      {expand ? <EditPasswordForm /> : <></>}
    </div>
  );
};
