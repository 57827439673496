import React, { useState } from "react";
import { Modal, message } from "antd";
import { useFirebase } from "../../../hooks";
import { useDispatch } from "react-redux";
import { enableFetch } from "../../../redux/actions/componentLoaderActions";

const DeleteImageAdModal = (props) => {
  const { currentBusiness, currentAd, visible, setVisible } = props;
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [setProcessing] = useState(false);

  /**
   * @param {Object} currentAd
   * Deletes current image ad from company.
   */
  const handleOk = (currentAd) => {
    setProcessing(true);
    console.log("ref path: ", currentBusiness);
    const adRefPath = `/companies/${currentBusiness.id}/coupons`;

    firebase
      .getDb()
      .deleteDocFromCollection(adRefPath, currentAd.id)
      .then(() => {
        dispatch(enableFetch());
        setVisible(false);
        message.success("Successfully deleted Ad");
        setProcessing(true);
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Delete image ad"
      visible={visible}
      onOk={() => handleOk(currentAd)}
      onCancel={handleCancel}
      okText="Delete image ad"
      okButtonProps={{
        className: "sm-box-shadow",
        shape: "round",
        type: "danger",
      }}
      cancelButtonProps={{
        className: "sm-box-shadow",
        shape: "round",
        type: "default",
      }}
    >
      <p>This will delete the image ad. Are you sure?</p>
    </Modal>
  );
};

export default DeleteImageAdModal;
