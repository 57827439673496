import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Spin, Empty } from "antd";
import { EnvironmentOutlined, ShopOutlined, PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import { useGetCompanyData } from "../../../hooks.js";
import NewLocationModal from "./NewLocationModal.js";
import EditLocationModal from "./EditLocationModal.js";
import DeleteLocationModal from "./DeleteLocationModal.js";
import { useSelector } from "react-redux";

export default function Locations() {
  const { companyData } = useGetCompanyData();
  const { componentLoaderReducer } = useSelector((state) => state);
  // const [currentBusiness, setCurrentBusiness] = useState(null);
  const [currentLocations, setCurrentLocations] = useState([]);

  useEffect(() => {
    if (companyData) {
      const { id, data } = companyData;
      const { locations } = data;
      // setCurrentBusiness({ id, ...data });
      setCurrentLocations(locations);
    }
  }, [companyData]);

  useEffect(() => {}, [componentLoaderReducer]);

  if (!currentLocations) {
    return "There are no locations yet.";
  }

  if (currentLocations.length === 0) {
    return (
      <Empty description="" style={{ marginTop: "40px" }}>
        <Row align="center" gutter={[0, 20]}>
          <Col span={24} align="center" style={{ fontWeight: "600" }}>
            Create a location so students can find your stores.{" "}
          </Col>
          <Col span={24} align="center">
            <NewLocationModal
              modalAction={
                <Button shape="round" type="default" icon={<PlusOutlined />} className="sm-box-shadow">
                  Create a location
                </Button>
              }
            />
          </Col>
        </Row>
      </Empty>
    );
  }

  if (currentLocations) {
    return (
      <>
        <Row gutter={[40, 20]}>
          {currentLocations.map((location, idx) => (
            <Col xl={8} lg={12} md={12} sm={12} xs={12}>
              <Card
                className="box-shadow round"
                actions={[
                  <EditLocationModal location={location} idx={idx} />,
                  <DeleteLocationModal location={location} idx={idx} />,
                ]}
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      marginBottom: "10px",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    <ShopOutlined /> <b>{location.label}</b>
                  </Col>
                  <Col span={24}>
                    <EnvironmentOutlined /> {location.address}
                  </Col>
                  <Col span={24}>
                    <PhoneOutlined /> {location.phone}
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </>
    );
  }
}
