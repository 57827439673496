import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Spin, Row, Col, Steps, Button, message } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { CheckOutlined, RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useFirebase } from "../../hooks";
import { routes } from "../../constants/routes";

const { Step } = Steps;

/**
 * Step by step process to sign up for a new subscription
 * Fetch (Stripe Product) subscription object for the user to select
 */
const NewSubsctription = () => {
  const history = useHistory();
  const firebase = useFirebase();
  const [selected, setSelected] = useState(false);
  const [product, setProduct] = useState(null);
  const [price, setPrice] = useState(null);

  /**
   * When subscription page loads, fetch the product
   */
  useEffect(() => {
    // fetchProduct();
    // fetchPrice();
    console.log("firebase: ", firebase.app);
    firebase.app
      .firestore()
      .collection("products")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          console.log("doc: ", doc);
        });
      });
  }, []);

  useEffect(() => {
    if (product) {
      console.log("product: ", product);
    }
  }, []);

  useEffect(() => {}, [selected, setSelected]);

  const handleClick = () => {
    if (selected) {
      setSelected(false);
      message.success("Basic plan unselected.");
    } else {
      // Plan is selected
      // send over the subscription plan obj to step 2
      setSelected(true);
      message.success("Basic plan selected.");
    }
  };

  const fetchProduct = async () => {
    await firebase
      .doRetrieveProduct("prod_IEpvZQIUeLHgqk")
      .then((productData) => {
        if (productData) {
          console.log(productData);
          setProduct(productData);
        }
      });
  };

  const fetchPrice = async () => {
    await firebase
      .doRetrievePrice("price_1HeMFOF5SQs16hknkhWdzJv1")
      .then((priceData) => {
        if (priceData) {
          console.log(priceData);
          setPrice(priceData);
        }
      });
  };

  const handleSubmit = () => {
    // Plan is selected
    // send over the subscription plan obj to step 2
    history.push({
      pathname: routes.dashboard.subscriptions.checkout,
      state: { product },
    });
  };

  if (price && product) {
    return (
      <div style={{ margin: "0 auto", maxWidth: "740px" }}>
        <Row gutter={[0, 20]}>
          <Col span={14}>
            <Steps size="small" current={0}>
              <Step title="Step 1" />
              <Step title="Step 2" />
            </Steps>
          </Col>
          <Col span={24}>
            <h2>Choose the plan that’s right for you.</h2>
            <p>Pause or restart at any time.</p>
          </Col>
        </Row>
        <SubscriptionRowDetails span={24} gutter={[20, 0]}>
          <Col1 span={14}></Col1>
          {selected ? (
            <SelectCol
              span={4}
              align="center"
              onClick={handleClick}
              selected={false}
            >
              BASIC
            </SelectCol>
          ) : (
            <SelectCol span={4} align="center" onClick={handleClick} selected>
              BASIC
            </SelectCol>
          )}
        </SubscriptionRowDetails>
        <SubscriptionRowDetails span={24} gutter={[20, 0]}>
          <Col1 span={14}>
            <b>Monthly Price</b>
            <div>You will only be charged during active semesters.</div>
          </Col1>
          {selected ? (
            <Col2 span={4} align="center" selected={false}>
              ${(price.data.unit_amount / 100).toFixed(2)}
            </Col2>
          ) : (
            <Col2 span={4} align="center" selected>
              ${(price.data.unit_amount / 100).toFixed(2)}
            </Col2>
          )}
        </SubscriptionRowDetails>
        <SubscriptionRowDetails span={24} gutter={[20, 0]}>
          <Col1 span={14}>
            <b>Coupon deals</b>
            <div>Lorem ipsum</div>
          </Col1>
          {selected ? (
            <Col2 span={4} align="center" selected={false}>
              <CheckOutlined />
            </Col2>
          ) : (
            <Col2 span={4} align="center" selected>
              <CheckOutlined />
            </Col2>
          )}
        </SubscriptionRowDetails>
        <SubscriptionRowDetails span={24} gutter={[20, 0]}>
          <Col1 span={14}>
            <b>Market advertising</b>
            <div>Lorem ipsum</div>
          </Col1>
          {selected ? (
            <Col2 span={4} align="center" selected={false}>
              <CheckOutlined />
            </Col2>
          ) : (
            <Col2 span={4} align="center" selected>
              <CheckOutlined />
            </Col2>
          )}
        </SubscriptionRowDetails>
        <SubscriptionRowDetails span={24} gutter={[20, 0]}>
          <Col1 span={14}>
            <b>Marketing analytics</b>
            <div>
              Monitor how your coupons and advertisement are impacting the
              audience.
            </div>
          </Col1>
          {selected ? (
            <Col2 span={4} align="center" selected={false}>
              <CheckOutlined />
            </Col2>
          ) : (
            <Col2 span={4} align="center" selected>
              <CheckOutlined />
            </Col2>
          )}
        </SubscriptionRowDetails>
        <SubscriptionRowDetails span={24} gutter={[20, 0]}>
          <Col1 span={14}>
            <b>Cancel anytime</b>
          </Col1>
          {selected ? (
            <Col2 span={4} align="center" selected={false}>
              <CheckOutlined />
            </Col2>
          ) : (
            <Col2 span={4} align="center" selected>
              <CheckOutlined />
            </Col2>
          )}
        </SubscriptionRowDetails>
        <Row span={24} style={{ marginTop: "40px" }}>
          <Col offset={6} span={10}>
            <Button
              type="primary"
              shape="round"
              block
              size="large"
              disabled={!selected ? true : false}
              onClick={(subscriptionObj) => handleSubmit(subscriptionObj)}
            >
              Continue
              <RightOutlined />
            </Button>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <Spin indicator={<Loading3QuartersOutlined spin />} />;
  }
};

const SelectCol = styled(Col)`
  font-weight: 700;
  padding: 20px 0 20px 0;
  background-color: #e6f7ff;
  box-shadow: ${(props) =>
    props.selected
      ? "none"
      : "rgba(60, 66, 87, 0.14) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px !important;"};

  &:hover {
    cursor: pointer;
    box-shadow: ${(props) =>
      props.selected
        ? "none"
        : "rgba(60, 66, 87, 0.14) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px !important;"};
  }
`;

const SubscriptionRowDetails = styled(Row)``;

const Col1 = styled(Col)`
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #eeeeee;
`;

const Col2 = styled(Col)`
  border-bottom: 1px solid #eeeeee;
  padding: 20px 0 20px 0;
  background-color: #e6f7ff;
  box-shadow: ${(props) =>
    props.selected
      ? "none"
      : "rgba(60, 66, 87, 0.14) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px !important;"};
`;

export default NewSubsctription;
