import React from "react";
import {
  useGetCoupons,
  useGetCompanyData,
  useGetCurrentUser,
} from "../../hooks.js";
import * as CONSTANTS from "../../constants/constants";
import { Loader } from "../Loader.js";
import { SignIn } from "../../ClientApp/Auth/SignIn/SignIn";
import AdminSignIn from "../../Admin/Auth/SignIn/AdminSignIn";
import { useHistory } from "react-router-dom";
import { routes } from "../../constants/routes";
import { useGetCurrentAdmin } from "../../hooks";

export const withAdminAuth = (WrappedComponent) => {
  return () => {
    const { authState } = useGetCurrentAdmin();

    if (authState === CONSTANTS.USER_STATES.LOADING) {
      return <Loader />;
    } else if (authState === CONSTANTS.USER_STATES.SIGNED_OUT) {
      return <AdminSignIn />;
    } else if (authState === CONSTANTS.USER_STATES.SIGNED_IN) {
      return <WrappedComponent />;
    }
  };
};

export const withAuth = (WrappedComponent) => {
  return () => {
    const { authState } = useGetCurrentUser();
    const history = useHistory();

    if (authState === CONSTANTS.USER_STATES.LOADING) {
      return <Loader />;
    } else if (authState === CONSTANTS.USER_STATES.SIGNED_OUT) {
      history.push(routes.auth.signIn); // Change the url as well
      return <SignIn />;
    } else if (authState === CONSTANTS.USER_STATES.SIGNED_IN) {
      return <WrappedComponent />;
    }
  };
};

export const withFetchCoupons = (WrappedComponent) => {
  return () => {
    const { couponsList, loading } = useGetCoupons();
    return !loading ? (
      <WrappedComponent couponsList={couponsList} />
    ) : (
      <Loader />
    );
  };
};

export const withCompany = (WrappedComponent) => {
  return () => {
    const { companyData } = useGetCompanyData();
    if (companyData !== null) {
      console.log(companyData);
    }

    return <WrappedComponent companyData={companyData} />;
  };
};