import styled from "styled-components";
import { Menu } from "antd";

const { Item } = Menu;

export const StyledMenu = styled(Menu)`
  border-radius: 10px;
`

export const StyledMenuItem = styled(Item)`
  margin: 6px 8px 6px 8px;
  border-radius: 8px;
  font-size: 14px;
`;

export const Wrapper = styled.div`
  /* margin: 0 auto; */
`;

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 60px 0 60px;
`;

export const H1 = styled.h1`
  font-size: 36px;
  font-weight: 800;
`;

export const H2 = styled.h2`
  font-size: 24px;
  font-weight: 800;
  padding: 0;
  margin: 0;
`;

export const H3 = styled.h3`
  font-size: 20px;
  font-weight: 600;
`;

export const H4 = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;

export const Span1 = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

export const FooterText = styled.p`
  font-size: 14px;
`;
