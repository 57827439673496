import React from "react";
import styled from "styled-components";
import { Row, Divider, Card } from "antd";
import { Link } from "react-router-dom";
import { routes } from "../../../constants/routes";
import { SignInForm } from "./SignInForm";

export default function AdminSignIn() {
  return (
    <SignInWrapper>
      <SignInContainer>
        <Row align="center" style={{ marginBottom: "20px" }}>
          <div style={{ fontWeight: 700, fontSize: "24px", color: "#000" }}>
            Student Life 101
          </div>
        </Row>
        <SignInCard className="med-box-shadow round">
          <SignInTitle>Admin Sign In</SignInTitle>
          <SignInForm />
        </SignInCard>
        <br />
        <Row align="center">
          <p>
            Don't have an account? <Link to={routes.admin.auth.signUp}>Sign up</Link>
          </p>
        </Row>
      </SignInContainer>
      <SignInDivider />
    </SignInWrapper>
  );
}

/**
 * Styled Components
 */
const SignInWrapper = styled.div`
  background-color: #f6f7f9 !important;
  min-height: 100vh !important;
  padding: 20px 10px 0 10px;
`;

const SignInContainer = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  padding: 60px 0 100px 0;
`;

const SignInCard = styled(Card)`
  margin: 0 auto;
  width: 480px;
  padding: 20px 30px 20px 30px;
`;

const SignInTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 20px 0;
`;

const SignInDivider = styled(Divider)`
  border-color: #e3e3e3;
  padding: 0;
  margin: 0;
`;
