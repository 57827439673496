import React, { useEffect, useState } from "react";
import {
  Divider,
  Tooltip,
  Select,
  InputNumber,
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";
import _ from "lodash";

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const activeTip = `'Active' will show the icon, 'inactive' will hide the icon`;
const typeTip = `Type of icon (an icon text or just an icon)`;
const colorTip = `Color of text or icon`;
const sizeTip = `Size of text or icon`;
const fontWeightTip = `fontWeight of text (normal or bold)`;
const indexTip = `
  A number to denote the order of the icons and text (low numbers to left,
  high numbers to right). If one object in the spotlight array has a value
  for index then they all must.`;

/**
 * @param {Object} currentBusiness
 * @param {Function} handleCancel
 *
 * Form to create a new spotlight
 */
const NewSpotlightForm = ({ currentBusiness, handleCancel }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [typeValue, setTypeValue] = useState("");

  useEffect(() => {}, [typeValue]);

  const handleTypeSelect = (value) => {
    setTypeValue(value);
  };

  // const positionOnChange = (value) => {
  //   console.log('value: ', value)
  // }

  /**
   * @param {Object} values
   *
   * Handle form on submit
   */
  const onFinish = (values) => {
    console.log("values: ", values);
    // Only write if value and current business are valid.
    if (values && currentBusiness) {
      const docId = currentBusiness.id;
      let businessToUpdate = currentBusiness;

      // Create a spotlight array if there is none
      if (typeof businessToUpdate.spotlight === "undefined") {
        businessToUpdate.spotlight = [];
        businessToUpdate.spotlight.push({ ...values });

        // Find the new spotlight and attach the index as a property.
        businessToUpdate.spotlight.forEach((item, idx) => {
          if (_.isEqual(item, values)) {
            item.index = idx;
          }
        });
      } else {
        businessToUpdate.spotlight.push({ ...values });

        // Find the new spotlight and attach the index as a property.
        businessToUpdate.spotlight.forEach((item, idx) => {
          if (_.isEqual(item, values)) {
            item.index = idx;
          }
        });
      }

      console.log("spotlights: ", businessToUpdate.spotlight);

      firebase
        .getDb()
        .doFirestoreSet("companies", docId, businessToUpdate, () => {
          dispatch(enableFetch());
          dispatch(disableFetch());
          message.success("Successfully added a spotlight.");
          handleCancel();
        });
    } else {
      message.warn("Oops, something went wrong.");
    }
  };

  /**
   * @param {Object} errorInfo
   *
   * Handle form error on submit
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.warn("Missing required inputs");
  };

  /**
   * Render
   */
  return (
    <Form {...layout} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        label={<CustomLabel label="Active" tipText={activeTip} />}
        name="active"
        rules={[{ required: true }]}
        initialValue={false}
      >
        <Select>
          <Option value={true}>Active</Option>
          <Option value={false}>Inactive</Option>
        </Select>
      </Form.Item>

      <Divider />

      <Form.Item
        label={<CustomLabel label="Type" tipText={typeTip} />}
        name="type"
        rules={[{ required: true }]}
      >
        <Select onSelect={handleTypeSelect}>
          <Option value="string">string</Option>
          <Option value="icon">icon</Option>
          <Option value="stringIcon">string icon</Option>
        </Select>
      </Form.Item>

      {typeValue === "stringIcon" || typeValue === "string" ? (
        <Form.Item
          label="Value"
          name="value"
          rules={[{ required: true }]}
          initialValue=""
        >
          <Input placeholder="Flash Deal (exmaple)" />
        </Form.Item>
      ) : (
        <></>
      )}

      {typeValue === "icon" || typeValue === "stringIcon" ? (
        <Form.Item
          label="Icon Type"
          name="iconType"
          rules={[{ required: true }]}
        >
          <Select>
            <Option value="MaterialCommunityIcons">
              Material Community Icons
            </Option>
            <Option value="MaterialIcons">Material Icons</Option>
            <Option value="Emoji">Emoji</Option>
          </Select>
        </Form.Item>
      ) : (
        <></>
      )}

      <Divider style={{ color: "#d6d6d6" }}>Optionals</Divider>

      <Form.Item
        label={<CustomLabel label="font weight" tipText={fontWeightTip} />}
        name="weight"
        initialValue="normal"
      >
        <Select>
          <Option value="normal">normal</Option>
          <Option value="bold">bold</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={<CustomLabel label="color" tipText={colorTip} />}
        name="color"
        initialValue=""
      >
        <Input placeholder="Color of text or icon" />
      </Form.Item>

      <Form.Item
        label={<CustomLabel label="size" tipText={sizeTip} />}
        name="size"
        initialValue={null}
      >
        <InputNumber min={0} max={20} />
      </Form.Item>

      {/* <Form.Item
        label={<CustomLabel label="position" tipText={indexTip} />}
        name="index"
        onSelect={positionOnChange}
      >
        <InputNumber min={0} max={6} />
      </Form.Item> */}

      <Row justify="end" gutter={[10, 0]}>
        <Col>
          <Button
            className="sm-box-shadow"
            shape="round"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            className="sm-box-shadow"
            shape="round"
            type="primary"
            htmlType="submit"
          >
            Create spotlight
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

/**
 *
 * @param {string} label
 * @param {string} tipText
 *
 * Displays a custom label with a tool tip text.
 */
const CustomLabel = ({ label, tipText }) => (
  <Row gutter={[2, 0]}>
    <Col>{label}</Col>
    <Col>
      <Tooltip placement="top" title={tipText}>
        <QuestionCircleOutlined />
      </Tooltip>
    </Col>
  </Row>
);

export default NewSpotlightForm;
