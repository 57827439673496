import React, { useState, useEffect } from "react";
import { Modal, Row, Col, message } from "antd";
import { useFirebase, useGetCompanyData } from "../../../hooks.js";
import { enableFetch } from "../../../redux/actions/componentLoaderActions";
import { useDispatch } from "react-redux";
import { NewImageAdForm } from "../NewImageAd/NewImageAdForm.js";

export default function NewAdModal(props) {
  const { modalAction } = props;
  const { companyData } = useGetCompanyData();
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = (value) => {
    if (companyData) {
      const { id, data } = companyData;
      console.log(id, data);
      console.log(value);
      let businessToUpdate = data;
      businessToUpdate.locations.push({ ...value });
      console.log("business to update: ", businessToUpdate);
      firebase
        .getDb()
        .doUpdateDoc("companies", id, businessToUpdate)
        .then(() => {
          dispatch(enableFetch());
          handleCancel();
          message.success(`Successfully added ${value.label}`);
        });
    }
  };

  const onFinishFailed = () => {};

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <a onClick={showModal}>{modalAction}</a>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
        title="Create a new image ad"
      >
        <Row
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "6px",
            marginBottom: "6px",
          }}
        >
          <Col span={24}>
            <NewImageAdForm handleCancel={handleCancel} />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
