import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useRoutes } from "../../../hooks";
import { TEXT } from "../../../constants/constants";

const SignUpNav = () => {
  const history = useHistory();
  const { routes } = useRoutes();

  return (
    <Row>
      <Col style={{ margin: "auto", maxWidth: "500px", marginRight: "120px" }}>
        <Col>
          <BrandLogo onClick={() => history.push(routes.landing.index)}>
            {TEXT.appTitle}
          </BrandLogo>
        </Col>
        <br />
        <Col span={24}>
          <h3><CheckCircleTwoTone /> Quick and free sign‑up</h3>
          Enter your name and email address to create an account.
        </Col>
        <br />
        <Col span={24}>
          <h3><CheckCircleTwoTone /> Manage your businesses</h3>
          Analyze your coupons and growth.
        </Col>
        <br />
        <Col span={24}>
          <h3><CheckCircleTwoTone /> Monitor your users</h3>
          Manage your users
        </Col>
      </Col>
    </Row>
  );
};

export default SignUpNav;

const BrandLogo = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: #000;

  &:hover {
    cursor: pointer !important;
    color: #323232;
  }
`;
