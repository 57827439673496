import { userStateInitState } from "../userStateInitState.js";

export const userStateReducer = (state = userStateInitState, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return { signedIn: true };
    case "SIGN_OUT":
      return { signedIn: false };
    default:
      return { ...state };
  }
};
