import React from "react";
import styled from "styled-components";
import { Container } from "../StyledComponents/styledComponents.js";
import { Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";

const loader = <Loading3QuartersOutlined style={{ fontSize: 64 }} spin />;

export const Loader = (props) => {
  return (
    <Container>
      <StyledLoader>
        <Spin indicator={loader} {...props} />
      </StyledLoader>
    </Container>
  );
};

const StyledLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
`;
