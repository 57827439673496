import React, { useState } from "react";
import { message, Modal, Button, Row, Col } from "antd";
import { ExclamationCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import { useFirebase, useGetCompanyData } from "../../../hooks.js";
import { enableFetch } from "../../../redux/actions/componentLoaderActions.js";
import { useDispatch } from "react-redux";

export default function DeleteCouponModal(props) {
  const { coupon } = props;
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const { companyData } = useGetCompanyData();
  const [visible, setVisible] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleDelete = () => {
    setProcessing(true);
    if (coupon) {
      const { id } = coupon;
      const companyId = companyData.id;
      const couponsRefPath = `/companies/${companyId}/coupons`;

      firebase
        .getDb()
        .deleteDocFromCollection(couponsRefPath, id)
        .then(() => {
          dispatch(enableFetch());
          setVisible(false);
          message.success("Successfully deleted coupon.");
          setProcessing(false);
        });
    }
  };

  return (
    <>
      <span style={{ height: "10px" }} onClick={() => setVisible(true)}>
        Delete Coupon
      </span>
      <Modal
        title=""
        visible={visible}
        footer={false}
        onCancel={() => setVisible(false)}
      >
        <Row span={24} style={{ marginBottom: "40px" }}>
          <Col span={2}>
            <ExclamationCircleTwoTone
              style={{ fontSize: "32px" }}
              twoToneColor=" #fadb14"
            />
          </Col>
          <Col span={22} style={{ fontSize: "16px", paddingTop: "4px" }}>
            Remove <b>{coupon.headline}</b>?
          </Col>
        </Row>
        <Row gutter={10} justify="end">
          {processing ? (
            <Col>
              <Button
                disabled={processing}
                shape="round"
                className="box-shadow"
                icon={<LoadingOutlined />}
              >
                Deleting coupon
              </Button>
            </Col>
          ) : (
            <>
              <Col>
                <Button
                  shape="round"
                  type="ghost"
                  onClick={() => setVisible(false)}
                  className="box-shadow"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  shape="round"
                  type="danger"
                  onClick={handleDelete}
                  className="box-shadow"
                >
                  Delete Coupon
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Modal>
    </>
  );
}
