import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StyledMenuItem } from "../../StyledComponents/styledComponents";
import {
  Divider,
  Typography,
  PageHeader,
  Button,
  Menu,
  Dropdown,
  Tag,
  Row,
  Col,
  Card,
} from "antd";
import moment from "moment";
import {
  DownOutlined,
  LoadingOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import { routes } from "../../constants/routes";
import { useFirebase } from "../../hooks";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import EditBusinessModal from "./components/EditBusinessModal";
import BusinessTabs from "./components/BusinessTabs";
import DeleteBusinessModal from "./components/DeleteBusinessModal";
import NewLocationModal from "../Locations/components/NewLocationModal";
import NewImageAdModal from "../ImageAds/components/NewImageAdModal";
import NewCouponModal from "../Coupons/components/NewCouponModal";
import NewSpotlightModal from "../Spotlights/components/NewSpotlightModal";

const { Title, Paragraph } = Typography;

export default function Business({ location }) {
  const { state } = location;
  const { componentLoaderReducer } = useSelector((state) => state);
  const firebase = useFirebase();
  const history = useHistory();
  const [currentBusiness, setCurrentBusienss] = useState(null);

  /**
   * When a dispatch is sent to fetch new data. Update the company.
   */
  useEffect(() => {
    if (state) {
      const { id } = state;
      const companyRefPath = `/companies/${id}`;
      firebase.getDb().doGetDocByRefPath(companyRefPath, (docRef) => {
        if (docRef) {
          const { id, data } = docRef;
          setCurrentBusienss({ ...data, id });
        }
      });
    }
  }, [state, componentLoaderReducer, firebase]);

  if (!currentBusiness) {
    return (
      <Row span={24} style={{ marginBottom: "20px" }}>
        <Col span={24}>
          <Card
            style={{ height: "200px", textAlign: "center", fontSize: "24px" }}
          >
            <LoadingOutlined />
          </Card>
        </Col>
      </Row>
    );
  }

  if (currentBusiness) {
    return (
      <>
        <PageHeader
          className="sm-box-shadow round"
          ghost={false}
          subTitle="back to all businesses"
          onBack={() => history.push(routes.admin.businesses.index)}
          extra={[
            <Button
              type="default"
              shape="round"
              size="large"
              icon={<LineChartOutlined />}
              onClick={() =>
                history.push({
                  pathname: `${routes.admin.insights.show}/${currentBusiness.name}`,
                  state: { ...currentBusiness },
                })
              }
            >
              View Insights
            </Button>,
            <EditBusinessModal currentBusiness={currentBusiness} />,
            <Dropdown
              getPopupContainer={(trigger) => trigger.parentNode}
              overlay={createMenuItems({ history, routes, currentBusiness })}
              placement="bottomRight"
              arrow
            >
              <Button
                shape="round"
                id="createDropdownBtn"
                type="primary"
                size="large"
                icon={<DownOutlined />}
                style={{ marginLeft: "10px" }}
              >
                Add
              </Button>
            </Dropdown>,
          ]}
        >
          <Row gutter={[20, 0]}>
            <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={24}>
              {currentBusiness.logo === undefined ||
              currentBusiness.logo === "" ? (
                <StyledAddLogo>
                  Click <b>Edit</b> to add a logo
                </StyledAddLogo>
              ) : (
                <BusinessLogo
                  src={currentBusiness.logo}
                  draggable={false}
                  alt="logo"
                />
              )}
            </Col>
            <Col xxl={19} xl={19} lg={19} md={19} sm={19} xs={24}>
              <Col span={24}>
                <Title level={2}>{currentBusiness.name}</Title>
              </Col>
              <Col span={24}>
                {currentBusiness.id !== "" ||
                currentBusiness.id !== undefined ? (
                  <Paragraph copyable>ID: {currentBusiness.id}</Paragraph>
                ) : (
                  <Paragraph copyable>ID: {currentBusiness.compId}</Paragraph>
                )}
              </Col>
              <Col span={24}>
                {currentBusiness.createdAt === undefined ||
                currentBusiness.createdAt === "" ? (
                  <>No date</>
                ) : (
                  <>
                    Created at:{" "}
                    {moment(currentBusiness.createdAt.toDate()).format(
                      "MM/DD/YYYY ddd"
                    )}
                  </>
                )}
              </Col>
              <Col span={24}>
                Status:{" "}
                {currentBusiness.active ? (
                  <Tag color="green">active</Tag>
                ) : (
                  <Tag color="red">inactive</Tag>
                )}
              </Col>
              <Col span={24}>Category: {currentBusiness.category}</Col>
              <Col span={24}>Description: {currentBusiness.description}</Col>
            </Col>
          </Row>
        </PageHeader>
        <br />
        <BusinessTabs currentBusiness={currentBusiness} />
        <Divider style={{ marginTop: "100px" }} />
        <Row align="center" style={{ marginTop: "100px" }}>
          <DeleteBusinessModal currentBusiness={currentBusiness} />
        </Row>
      </>
    );
  }
}

const createMenuItems = ({ currentBusiness }) => (
  <StyledMenu>
    <StyledMenuItem>
      <NewLocationModal
        currentBusiness={currentBusiness}
        modalAction={<>Location</>}
      />
    </StyledMenuItem>
    <StyledMenuItem>
      <NewImageAdModal
        currentBusiness={currentBusiness}
        modalAction={<>Image ad</>}
      />
    </StyledMenuItem>
    <StyledMenuItem>
      <NewCouponModal
        currentBusiness={currentBusiness}
        modalAction={<>Coupon</>}
      />
    </StyledMenuItem>
    <StyledMenuItem>
      <NewSpotlightModal
        currentBusiness={currentBusiness}
        modalAction={<>Spotlight</>}
      />
    </StyledMenuItem>
  </StyledMenu>
);

const StyledMenu = styled(Menu)`
  width: 240px;
  border-radius: 10px;
`;

const BusinessLogo = styled.img`
  max-width: 100%;
  max-width: 100%;
`;

const StyledAddLogo = styled.div`
  border: 1px solid #d3d3d3;
  height: 120px;
  width: 120px;
  background-color: #eee;
  padding: 40px 20px 20px 20px;
`;
