import React from "react";
import styled from "styled-components";
import { Row, Col, Button } from "antd";
import redeemScreenshot from "../../assets/images/redeemScreenshot.jpeg";
import mobileScreenshot from "../../assets/images/mobileScreenshot.jpeg";
import { EmailBusinessCard } from "./EmailBusinessCard.js";

export const WhySection = () => (
  <SectionWrapper id="why">
    <SectionContainer>
      <WhyHeader>Why?</WhyHeader>
      <br />
      <Row gutter={40} style={{ marginBottom: "60px" }}>
        <Col lg={12} md={14}>
          <SubTitle>
            Directly reach your local college market!
            The Student Life 101 app will put your
            business in the palm of their hand.
          </SubTitle>
          <br />
          <br />
          <Row gutter={10}>
            <Col>
              <EmailBusinessCard />
            </Col>
          </Row>
        </Col>
        <Col lg={5} md={5}>
          <MobileImg
            src={mobileScreenshot}
            alt="mobile coupons list"
            draggable={false}
          />
        </Col>
        <Col lg={5} md={5}>
          <RedeemImg
            src={redeemScreenshot}
            alt="mobile coupons list"
            draggable={false}
          />
        </Col>
      </Row>
    </SectionContainer>
  </SectionWrapper>
);

const SectionWrapper = styled.div`
  background-color: #f6f7f9;
  height: 700px;
  border-top: 1px solid #eee;
`;

const SectionContainer = styled.div`
  margin: 0 auto;
  max-width: 980px;
  padding: 60px 10px 0 10px;
`;

const WhyHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #096dd9;
`;

const MobileImg = styled.img`
  height: 464px;
  width: 264px;
  border-radius: 20px;
  z-index: 2;
  position: absolute;
  background-color: #f6f9fc;
  padding: 12px;
  box-shadow: 0 20px 30px -20px rgba(50, 50, 93, 0.25),
    0 10px 30px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35) !important;
`;

const RedeemImg = styled.img`
  margin: 60px 0 0 0;
  height: 464px;
  width: 264px;
  border-radius: 20px;
  z-index: 3;
  position: absolute;
  background-color: #f6f9fc;
  padding: 12px;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
    0 30px 60px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35) !important;
`;

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: #000;
`;

const P = styled.p`
  font-size: 18px;
`;

const JoinButton = styled(Button)`
  box-shadow: none !important;
  border-radius: 20px !important;
`;
