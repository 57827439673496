import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditCouponForm from "./EditCouponForm";

const EditCouponModal = ({ modalAction, couponParams, currentBusiness }) => {
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <a onClick={showModal}>
        <EditOutlined /> {modalAction}
      </a>
      <Modal
        title="Edit coupon"
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
      >
        <EditCouponForm
          currentBusiness={currentBusiness}
          couponParams={couponParams}
          handleCancel={handleCancel}
        />
      </Modal>
    </>
  );
};

export default EditCouponModal;
