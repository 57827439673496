export const TEXT = {
  appTitle: "Student Life 101",
};

export const collectionNames = {
  companies: "companies",
  users: "users",
  companyUsers: "companyUsers",
  coupons: "coupons",
};

export const USER_STATES = {
  LOADING: 'loading',
  SIGNED_IN: 'signed in',
  SIGNED_OUT: 'signed out'
}