import React from "react";
import { useHistory } from "react-router-dom";
import { Menu } from "antd";
import {
  DashboardFilled,
  ShopFilled,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useRoutes } from "../../../hooks";

export default function SideNavMenu() {
  const { routes } = useRoutes();
  const history = useHistory();

  return (
    <Menu
      theme="light"
      defaultSelectedKeys={["2"]}
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
      mode="inline"
    >
      <Menu.Item
        aria-expanded={true}
        key="1"
        icon={<DashboardFilled />}
        onClick={() => history.push(routes.admin.dashboard.index)}
      >
        Overview
      </Menu.Item>
      <Menu.Item
        key="2"
        icon={<ShopFilled />}
        onClick={() => history.push(routes.admin.businesses.index)}
      >
        Businesses
      </Menu.Item>
      <Menu.Item
        key="3"
        icon={<UnorderedListOutlined />}
        onClick={() => history.push(routes.admin.businesses.index)}
        disabled
      >
        Categories
      </Menu.Item>
    </Menu>
  );
}
