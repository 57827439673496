import React from "react";
import { Button, Form, Input, DatePicker, message, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";
import { useFirebase, useConstants, useToolTipText } from "../../../hooks";
import FormLabelTooltip from "../../../components/FormLabelTooltip";

/**
 *
 * @param {Object} currentBusiness
 * @param {Object} couponParams
 * @param {Object} handleCancel
 *
 * Form receives the current business, current coupon, and function
 * to update the current business' coupon.
 *
 */
const EditCouponForm = ({ currentBusiness, couponParams, handleCancel }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const { TOOL_TIP_TEXT } = useToolTipText();

  const { COLLECTIONS } = useConstants();

  const onFinish = (values) => {
    const startDate = values.startDate.toDate();
    const expires = values.expires.toDate();
    const couponToUpdate = { ...values, startDate, expires };
    const refPath = `/companies/${currentBusiness.id}/${COLLECTIONS.COUPONS}`;

    // Update the coupon.
    firebase
      .getDb()
      .doUpdateSubcollectionDoc(refPath, couponParams.id, couponToUpdate)
      .then(() => {
        dispatch(enableFetch());
        message.success(
          `Successfully updated ${couponParams.headline} coupon.`
        );
        dispatch(disableFetch());
        handleCancel();
      });
  };

  const formLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  return (
    <>
      {couponParams === null ? (
        <LoadingOutlined />
      ) : (
        <Form
          {...formLayout}
          name="basic"
          layout="horizontal"
          onFinish={onFinish}
        >
          <Form.Item
            label={
              <FormLabelTooltip
                name="Headline"
                tooltipText={TOOL_TIP_TEXT.headline}
              />
            }
            name="headline"
            initialValue={couponParams.headline}
            rules={[{ required: true, message: "Please input a headline." }]}
          >
            <Input.TextArea autoSize />
          </Form.Item>

          <Form.Item
            label={
              <FormLabelTooltip
                name="Subline"
                optional="optional"
                tooltipText={TOOL_TIP_TEXT.subline}
              />
            }
            name="subline"
            initialValue={
              typeof couponParams.subline === "undefined"
                ? ""
                : couponParams.subline
            }
          >
            <Input.TextArea autoSize />
          </Form.Item>

          <Form.Item
            label={
              <FormLabelTooltip
                name="Fineprint"
                optional="optional"
                tooltipText={TOOL_TIP_TEXT.fineprint}
              />
            }
            name="finePrint"
            initialValue={
              typeof couponParams.finePrint === "undefined"
                ? ""
                : couponParams.finePrint
            }
          >
            <Input size="large" />
          </Form.Item>

          <Row gutter={[20, 0]}>
            <Col span={12}>
              <Form.Item
                label={
                  <FormLabelTooltip
                    name="Start Date"
                    tooltipText={TOOL_TIP_TEXT.startDate}
                  />
                }
                name="startDate"
                initialValue={
                  typeof couponParams.startDate === "undefined"
                    ? ""
                    : moment(couponParams.startDate.toDate())
                }
                rules={[
                  {
                    required: true,
                    message: "Please enter a start date.",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <FormLabelTooltip
                    name="Expiration Date"
                    tooltipText={TOOL_TIP_TEXT.expirationDate}
                  />
                }
                name="expires"
                initialValue={moment(couponParams.expires.toDate())}
                rules={[
                  {
                    required: true,
                    message: "Please enter an expiration date.",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Row gutter={10} justify="end">
              <Col>
                <Button
                  className="sm-box-shadow"
                  shape="round"
                  block
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  className="sm-box-shadow"
                  shape="round"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Update Coupon
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default EditCouponForm;
