import React, { useState, useEffect } from "react";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { Empty, Row, Col, Button, Table, message } from "antd";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { MenuOutlined } from "@ant-design/icons";
import arrayMove from "array-move";
import "./DragDrop.css";
import { useGetCoupons, useFirebase } from "../../hooks";
import { useSelector } from "react-redux";
import NewCouponModal from "../../ClientApp/Coupons/components/NewCouponModal";

export const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const SortableTable = ({ columns }) => {
  const firebase = useFirebase();
  const { componentLoaderReducer } = useSelector((state) => state);
  const { loading } = componentLoaderReducer;
  const { couponsList } = useGetCoupons();
  const [coupons, setCoupons] = useState(null);
  const [isLoading] = useState(false);
  // const [dataSource, setDataSource] = useState(data);

  useEffect(() => {
    if (couponsList) {
      console.log("couponsList: ", couponsList);
      let onlyCoupons = couponsList.filter(
        (coupon, idx) => coupon.type !== "image"
      );
      // Sort by index
      onlyCoupons.sort((a, b) => (a.index > b.index ? 1 : -1));
      // add index if not arleady pressent
      for (let i = 0; i < onlyCoupons.length; i++) {
        const coup = onlyCoupons[i];
        // Remove ref becuase router can't serialize it
        delete coup.redeemed;
        onlyCoupons[i] = { ...coup, index: i };
      }
      setCoupons(onlyCoupons);
    }
  }, [couponsList, loading, isLoading]);

  const updateFirestoreNewOrder = (newData) => {
    const batch = firebase.app.firestore().batch();
    newData.forEach((item, index) => {
      const { companyId, id } = item;
      const coupRef = firebase.app
        .firestore()
        .collection("companies")
        .doc(companyId)
        .collection("coupons")
        .doc(id);
      batch.update(coupRef, { index });
    });
    batch
      .commit()
      .then(() => {
        // console.log("batch committed!");
        message.success("Order Updated!");
      })
      .catch(() => {
        message.error("Order Failed to Update.");
      });
  };

  // Event happens a row has been dragged
  // We need to update the data
  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (couponsList) {
      if (oldIndex !== newIndex) {
        // Use couponsList instead of the dataSource
        const newData = arrayMove(
          [].concat(coupons),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        console.log("NEW DATA: ", newData);
        // setDataSource(newData);
        setCoupons(newData);
        updateFirestoreNewOrder(newData);
      }
    }
  };

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    if (couponsList) {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = couponsList.findIndex(
        (x) => x.index === restProps["data-row-key"]
      );
      return <SortableItem index={index} {...restProps} />;
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  if (couponsList === null || coupons === null) {
    return <LoadingOutlined />;
  }

  if (coupons.length === 0) {
    return (
      <Empty description="" style={{ paddingTop: "40px" }}>
        <Row align="center" gutter={[0, 20]}>
          <Col span={24} align="center">
            <b>Create a your first coupon</b>
          </Col>
          <Col span={24} align="center">
            <NewCouponModal
              modalAction={
                <Button
                  shape="round"
                  type="default"
                  icon={<PlusOutlined />}
                  className="box-shadow"
                >
                  Create a coupon
                </Button>
              }
            />
          </Col>
        </Row>
      </Empty>
    );
  }

  if (coupons) {
    return (
      <Table
        size="small"
        pagination={true}
        dataSource={coupons}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
      />
    );
  }
};

export default SortableTable;
