import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Input, Button } from "antd";
import { useFirebase, useGetCurrentUser, useConstants } from "../../hooks.js";
import { useDispatch } from "react-redux";
import { enableFetch } from "../../redux/actions/componentLoaderActions.js";
import { Loader } from "../../components/Loader.js";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const EditEmailForm = () => {
  const dispatch = useDispatch();
  const { currentUser, currentUserData } = useGetCurrentUser();
  const { COLLECTIONS } = useConstants();
  const firebase = useFirebase();
  const [user, setUser] = useState(null);

  const onFinish = async (values) => {
    dispatch(enableFetch());
    const { email } = values;

    const { uid } = currentUserData;
    const updatedUser = { ...currentUserData, email };

    firebase.doUpdateEmail(currentUser, email).then((status) => {
      firebase
        .getDb()
        .doUpdateDoc(COLLECTIONS.COMPANY_USERS, uid, updatedUser)
        .then(() => {
          window.location.reload();
        });
    });
  };

  const onFinishFailed = (error) => {
    console.log(error);
  };

  useEffect(() => {
    if (currentUser !== null || currentUser !== undefined) {
      console.log("currentUser: ", currentUser);
      setUser(currentUser);
    }
  }, [currentUser]);

  if (!user) {
    return <Loader />;
  }

  return (
    <FormWrapper>
      <Form
        {...layout}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, message: "Email address required." }]}
          initialValue={user.email}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            className="box-shadow"
            shape="round"
            type="primary"
            size="medium"
            htmlType="submit"
          >
            Update Email
          </Button>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  margin: 0 0 60px 0;
`;
