import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, Row, Col, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks.js";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";
import { useDispatch } from "react-redux";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

/**
 * Admin receives the current business from it's parent not hook
 */
const NewLocationModal = ({ modalAction, currentBusiness }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [loading]);

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async (value) => {
    setLoading(true);
    if (currentBusiness) {
      const { id } = currentBusiness;

      if (typeof value.label === "undefined") {
        value.label = "";
      }

      if (typeof value.phone === "undefined") {
        value.phone = "";
      }

      let businessToUpdate = currentBusiness;

      // If the user has never added a location the locations array will be undefined.
      if (typeof businessToUpdate.locations === "undefined") {
        businessToUpdate.locations = [];
      }

      businessToUpdate.locations.push({ ...value });
      await firebase
        .getDb()
        .doUpdateDoc("companies", id, businessToUpdate)
        .then(() => {
          dispatch(enableFetch());
          handleCancel();
          setLoading(false);
          message.success(`Successfully added ${value.label}`);
          dispatch(disableFetch()); // Dispatch to update data
        });
    }
  };

  const onFinishFailed = () => {};

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <span onClick={showModal}>{modalAction}</span>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onCancel={handleCancel}
        title="Add a new location"
      >
        <Form
          {...layout}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item label="Label" name="label">
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input your address!" }]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item>
            <Row gutter={10} justify="end">
              {loading ? (
                <Col>
                  <Button
                    disabled
                    block
                    type="primary"
                    htmlType="submit"
                    icon={<LoadingOutlined spin />}
                    shape="round"
                  ></Button>
                </Col>
              ) : (
                <>
                  <Col>
                    <Button
                      block
                      type="secondary"
                      onClick={handleCancel}
                      shape="round"
                      size="large"
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      block
                      type="primary"
                      htmlType="submit"
                      shape="round"
                      size="large"
                    >
                      Add location
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default NewLocationModal;
