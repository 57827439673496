import React, { useState } from "react";
import styled from "styled-components";
import { Row, Col, Button, Modal, Typography, Divider } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { routes } from "../../constants/routes.js";

const { Title } = Typography;

export default function HeaderNav() {
  const history = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOk = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  return (
    <>
      <Modal title="" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} destroyOnClose>
        <div style={{ padding: '2rem 1rem' }}>
          <div>
            <Title level={4}>
              Returning users
            </Title>
            <Button type="primary" size="large" onClick={() => history.push('/sign-in')} style={{ width: '200px' }}>Sign in</Button>
          </div>
          <Divider />
          <div style={{ marginTop: '20px' }}>
            <Title level={4}>
              First time users
            </Title>
            <Button size="large" onClick={() => history.push(routes.auth.signUp)} style={{ width: '200px' }}>Create your account</Button>
          </div>
        </div>
      </Modal>
      <NavWrapper xl={{ offset: 4 }}>
        <NavBar gutter={30}>
          <NavCol span={12} style={{ padding: "0" }}>
            <BrandLogo>
              Student Life 101
            </BrandLogo>
          </NavCol>
          <NavCol span={12} style={{ textAlign: "right" }}>
            <Row justify="end">
              <SigninButton size="large" shape="round" onClick={handleOk} type="primary">
                Sign in <RightOutlined />
              </SigninButton>
            </Row>
          </NavCol>
        </NavBar>
      </NavWrapper>
    </>

  );
}

const SigninButton = styled(Button)`
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  font-weight: 500;

  &:hover {
    cursor: pointer;
    box-shadow: 0;
  }
`

const BrandLogo = styled.div`
  font-size: 22px;
  font-weight: 700;
  color: #fff;

  &:hover {
    cursor: pointer;
    color: #eee;
  }
`;

const NavWrapper = styled.div`
  margin: 0 auto;
  max-width: 980px;
  padding: 10px 10px 0 10px;
`;

const NavBar = styled(Row)`
  margin: 0 !important;
  width: 100%;
  padding: 10px 0 0 0;
`;

const NavCol = styled(Col)``;