import React from 'react';
import { Card } from "antd";
import styled from 'styled-components';

export const EmailBusinessCard = () => (
  <StyledCard>
    <Heading>
      Interested in posting an ad?
    </Heading>
    <div style={{ fontSize: '18px' }}>
      Send email to:{' '}
      <a href="mailto:studentlife101@hotmail.com">
        studentlife101@hotmail.com
      </a>
    </div>
  </StyledCard>
);

const Heading = styled.div`
  font-weight: 700;
  font-size: 21px;
  line-height: 1.2em;
  opacity: 0.9;
  margin: 0 0 1rem 0;
`;

const StyledCard = styled(Card)`
  padding: 1rem 0 1rem 0;
  margin: 0 0 2rem 0;
  box-shadow: 10px 10px 10px #006747;
  border: 3px solid #000;

  &:hover {
    cursor: pointer;
    box-shadow: 0;
    animation: shake 0.5s;
  }

  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
`