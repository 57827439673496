import React from "react";
import { PageHeader, Card, Form, Input, Select, DatePicker, Spin } from "antd";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

export const PlaceholderPageHeader = () => {
  return (
    <Spin size="large">
      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title={""}
        subTitle=""
      />
    </Spin>
  );
};

export const PlaceholderForm = () => {
  return (
    <Spin size="large">
      <Card>
        <Form {...layout} name="basic" layout="vertical">
          <Form.Item label="Headline" name="headline">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Subline" name="subline">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Fineprint" name="fineprint">
            <Input disabled />
          </Form.Item>

          <Form.Item label="Expiration Date" name="expires">
            <DatePicker disabled />
          </Form.Item>

          <Form.Item label="Is Killer" name="isKiller">
            <Select disabled>
              <Select.Option value="true">True</Select.Option>
              <Select.Option value="false">False</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};
