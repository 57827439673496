import React from "react";
import { Dropdown, Button } from "antd";
import { FilterFilled } from "@ant-design/icons";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../StyledComponents/styledComponents";

const DateFilterDropdown = (props) => {
  const { days, changeDayFilter } = props;

  const menu = (
    <StyledMenu>
      <StyledMenuItem onClick={() => changeDayFilter(7)}>
        Last 7 days
      </StyledMenuItem>
      <StyledMenuItem onClick={() => changeDayFilter(14)}>
        Last 14 days
      </StyledMenuItem>
      <StyledMenuItem onClick={() => changeDayFilter(30)}>
        Last 30 days
      </StyledMenuItem>
      <StyledMenuItem onClick={() => changeDayFilter(90)}>
        Last 90 days
      </StyledMenuItem>
      <StyledMenuItem onClick={() => changeDayFilter(180)}>
        Last 180 days
      </StyledMenuItem>
      <StyledMenuItem onClick={() => changeDayFilter(360)}>
        Last 360 days
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" arrow>
      <Button
        icon={<FilterFilled />}
        className="sm-box-shadow"
        shape="round"
      >
        Filter by {days} days
      </Button>
    </Dropdown>
  );
};

export default DateFilterDropdown;
