import React from "react";
import { useRoutes } from "../../hooks.js";
import useReactRouter from "use-react-router";
import styled from "styled-components";
import { Row } from "antd";

const ForgotMyPassword = () => {
  const { history } = useReactRouter();
  const { routes } = useRoutes();

  return (
    <Row align="center">
      <Link onClick={() => history.push(routes.auth.forgotMyPassword)}>
        Reset my password
      </Link>
    </Row>
  );
};

export default ForgotMyPassword;

// Styled components
const Link = styled.a``;
