import React, { useState, useEffect } from "react";
import {
  Wrapper,
  Container,
  StyledMenu,
  StyledMenuItem,
} from "../../StyledComponents/styledComponents";
import {
  Tag,
  Card,
  Typography,
  Menu,
  Row,
  Col,
  Table,
  Button,
  Dropdown,
  Collapse,
} from "antd";
import {
  EllipsisOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useGetAllUsers, useFirebase } from "../../hooks";
import { Link, useHistory } from "react-router-dom";
import { routes } from "../../constants/routes.js";
// import { menu } from "./ActionMenuItems.js";

const { Title } = Typography;
const { Panel } = Collapse;

export default function Users() {
  const firebase = useFirebase();
  const history = useHistory();
  const { usersData } = useGetAllUsers();
  const [loading, setLoading] = useState(true);
  const [usersDocData, setUsersDocData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState(null);
  const [totalUsers, setTotalUsers] = useState(null);

  useEffect(() => {
    let usersList = [];
    if (usersData) {
      setTotalUsers(usersData.length);

      usersData.forEach((item, idx) => {
        const { docData } = item;
        const { redeemed } = docData;
        const { favorites } = docData;
        const validProps = docData && redeemed && favorites;

        let redeemedCoupons = [];
        let favoritedCoupons = [];

        if (validProps) {
          if (redeemed.length !== 0) {
            redeemed.forEach((redeemedItem, idx) => {
              const { id, path } = redeemedItem;
              firebase.getDb().doGetDocByRefPath(path, (data) => {
                if (data) {
                  redeemedCoupons.push({ id: data.id, ...data.data });
                }
              });
            });
          }

          if (favorites.length !== 0) {
            favorites.forEach((favoritedItem, idx) => {
              const { id, path } = favoritedItem;
              firebase
                .getDb()
                .doGetDocByRefPath(path)
                .then((data) => {
                  if (data) {
                    favoritedCoupons.push({ id: data.id, ...data.data });
                  }
                });
            });
          }

          let key = idx;
          let newDocData = {
            key,
            totalRedeemed: redeemed.length,
            totalFavorited: favorites.length,
            ...docData,
            redeemedCoupons,
            favoritedCoupons,
          };
          usersList.push(newDocData);
        }
      });
      setUsersDocData(usersList);
      setLoading(false);
    }
  }, [usersData]);

  const onChange = (pagination, filters, sorter, extra) => {};

  const TableActions = ({ user }) => {
    const redirectToDetails = () => {
      history.push({
        pathname: routes.admin.users.show + "/" + user.uid,
        state: { userId: user.uid },
      });
    };

    const menu = (
      <StyledMenu>
        <StyledMenuItem onClick={redirectToDetails}>
          <EyeOutlined /> View
        </StyledMenuItem>
        <StyledMenuItem disabled>
          <EditOutlined /> Edit
        </StyledMenuItem>
        <Menu.Divider />
        <StyledMenuItem disabled>
          <DeleteOutlined /> Delete
        </StyledMenuItem>
      </StyledMenu>
    );

    return (
      <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <Button shape="round" size="small">
            <EllipsisOutlined />
          </Button>
        </a>
      </Dropdown>
    );
  };

  const columns = [
    { title: "Email", dataIndex: "email", key: "email" },
    {
      title: "Favorited",
      dataIndex: "totalFavorited",
      key: "totalFavorited",
      sorter: {
        compare: (a, b) => a.totalFavorited > b.totalFavorited,
      },
    },
    {
      title: "Redeemed",
      dataIndex: "totalRedeemed",
      key: "totalRedeemed",
      sorter: {
        compare: (a, b) => a.totalRedeemed > b.totalRedeemed,
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (actions, user) => <TableActions user={user} />,
    },
  ];

  /**
   * Renders expandable section for each user row.
   * @param {Object} record
   */
  const renderRedeemedCoupons = (record) => {
    // Render an empty message when user has not redeemed a coupon yet.
    // Render the coupons redeemed by the user.
    return (
      <div>
        <div style={{ padding: "0 50px 0 50px" }}>
          <Row align="left">
            <Col lg={12}>
              <b>UID</b>
            </Col>
            <Col lg={12}>
              <b>Name</b>
            </Col>
          </Row>
          <Row align="left">
            <Col lg={12}>{record.uid}</Col>
            <Col lg={12}>
              {record.name === undefined || record.name === "" ? (
                <p>{record.email}</p>
              ) : (
                <p>{record.name}</p>
              )}
            </Col>
          </Row>
        </div>
        <Collapse bordered={false} style={{ padding: "0 30px 0 30px" }}>
          <Panel header={<b>Coupons Redeemed</b>} key="1">
            {record.redeemed.length === 0 ? (
              <div>{record.name} hasn't redeemed any coupons yet.</div>
            ) : (
              <div
                style={
                  record.redeemedCoupons.length >= 5
                    ? { height: "280px", overflow: "scroll" }
                    : {}
                }
              >
                <Row>
                  <Col lg={8}>
                    <b>Unique Identifier</b>
                  </Col>
                  <Col lg={12}>
                    <b>HEADLINE</b>
                  </Col>
                </Row>
                {record.redeemedCoupons.map((coupon, idx) => (
                  <Row style={{ marginBottom: "4px" }}>
                    <Col lg={8}>{coupon.id}</Col>
                    <Col lg={12}>{coupon.headline}</Col>
                  </Row>
                ))}
              </div>
            )}
          </Panel>
          <Panel header={<b>Favorited Coupons</b>} key="2">
            {record.favoritedCoupons.length === 0 ? (
              <div>{record.name} hasn't favorited any coupons yet.</div>
            ) : (
              <div
                style={
                  record.favoritedCoupons.length >= 5
                    ? { height: "280px", overflow: "scroll" }
                    : {}
                }
              >
                <Row>
                  <Col lg={6}>
                    <b>Unique Identifier</b>
                  </Col>
                  <Col lg={14}>
                    <b>HEADLINE</b>
                  </Col>
                </Row>
                {record.favoritedCoupons.map((coupon, idx) => (
                  <Row style={{ marginBottom: "4px" }}>
                    <Col lg={6}>{coupon.id}</Col>
                    <Col lg={14}>{coupon.headline}</Col>
                  </Row>
                ))}
              </div>
            )}
          </Panel>
        </Collapse>
      </div>
    );
  };

  if (loading) {
    return (
      <Wrapper>
        <Container>
          <Row style={{ marginTop: "20px" }}>
            <Col lg={24} md={24} sm={24}>
              <Title level={3}>Users</Title>
            </Col>
            <Col lg={24} md={24} sm={24}>
              <Row gutter={[10, 0]}>
                <Col>
                  <LoadingOutlined />
                </Col>
                <Col>Loading...</Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  } else {
    return (
      <Wrapper>
        <Container>
          <Row style={{ marginTop: "20px" }}>
            <Col lg={24} md={24} sm={24}>
              <Title level={3}>Users</Title>
            </Col>
            <Col lg={24} md={24} sm={24}>
              <Card className="sm-box-shadow round">
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <AdditionalInfo totalUsers={totalUsers} />
                  </Col>
                  <Col span={24}>
                    <Table
                      size="small"
                      columns={columns}
                      expandable={{
                        expandedRowRender: (record) =>
                          renderRedeemedCoupons(record),
                      }}
                      dataSource={usersDocData}
                      onChange={onChange}
                      pagination={{
                        defaultPageSize: 20,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  }
}

const AdditionalInfo = ({ totalUsers }) => {
  return (
    <>
      {totalUsers === null ? (
        <LoadingOutlined />
      ) : (
        <Tag>Total users: {totalUsers}</Tag>
      )}
    </>
  );
};
