import React, { useEffect, useState } from "react";
import { useFirebase, useGetCoupons, useGetCompanyData } from "../../hooks";
import { useHistory } from "react-router-dom";
import { Divider, Spin, Row, Col, Typography } from "antd";
import {
  BarChartOutlined,
  LineChartOutlined,
  Loading3QuartersOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { routes } from "../../constants/routes";
import InsightsLayout from "./components/InsightsLayout";
import InsightsPageHeader from "./components/InsightsPageHeader";
import CouponsImpressionsBarChart from "../../components/Insights/CouponsImpressionsBarChart";
import CouponsRedeemedBarChart from "../../components/Insights/CouponsRedeemedBarChart";
import AdsImpressionsCounts from "../../components/Insights/AdsImpressionsCounts";
import RedeemedOvertimeLineChartSection from "../../components/Insights/RedeemedOverTimeLineChartsSection";
import RedeemedCardsTotalStatsSection from "../../components/Insights/RedeemedCardsTotalStatsSection";
import CouponsImpressionsStats from '../../components/Insights/CouponsImpressionsStats';

const { Title } = Typography;

/**
 * Insight's children component depends on currentBusiness object.
 * @param {Object} props
 */
const Insights = () => {
  const history = useHistory();
  const { couponsList } = useGetCoupons();
  const { companyData } = useGetCompanyData();
  const firebase = useFirebase();
  const [currentBusiness, setCurrentBusiness] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [loading]);

  useEffect(() => {
    if (companyData) {
      console.log("coupons list: ", couponsList);
      console.log("company data: ", companyData.data);
      setCurrentBusiness(companyData);
    }
  }, [companyData, couponsList]);

  /**
   * If we have impressionsCount already in coupons, we don't need to write to Firestore.
   */
  useEffect(() => {
    if (couponsList && currentBusiness) {
      couponsList.forEach((coupon, idx) => {
        if (coupon.impressionsCount === undefined) {
          fetchImpressionsCount(currentBusiness, couponsList);
        } else {
          console.log("impressionsCount already exists.");
        }
      });
    }
  }, [couponsList]);

  /**
   *
   * @param {Object} currentBusiness
   * @param {Array} coupons
   *
   * Receives the current business and it's coupons.
   *
   * Calculates the impressions count for each coupon and image ad.
   *
   * Updates the company coupons with the impressions count property.
   *
   * Writes to Firestore.
   */
  const fetchImpressionsCount = (currentBusiness, coupons) => {
    setLoading(true);

    let promises = [];
    let couponsList = [];
    let couponToUpdate = null;

    coupons.forEach((coupon, idx) => {
      couponToUpdate = coupon;
      const data = { businessId: currentBusiness.id, couponId: coupon.id };

      promises.push(
        firebase.doCalculateImpressionsCount(data).then((data) => {
          couponsList.push({ ...coupon, impressionsCount: data.data });
          return couponsList;
        })
      );
    });

    const batch = firebase.getApp().firestore().batch();

    Promise.all(promises).then((data) => {
      console.log("data[0]: ", data[0]);
      data[0].forEach((coupon, idx) => {
        const couponRef = firebase
          .getApp()
          .firestore()
          .collection(`/companies/${currentBusiness.id}/coupons`)
          .doc(coupon.id);
        batch.update(couponRef, { ...coupon });
      });
      batch.commit();
      setLoading(false);
    });
  };

  if (loading) {
    return (
      <>
        <LoadingOutlined /> Updating data...
      </>
    );
  }

  return (
    <InsightsLayout>
      {companyData ? (
        <InsightsPageHeader
          companyData={companyData}
          couponsList={couponsList}
          history={history}
          routes={routes}
          fetchImpressionsCount={fetchImpressionsCount}
        />
      ) : (
        <Spin indicator={<Loading3QuartersOutlined spin />} />
      )}

      <Divider />

      <RedeemedCardsTotalStatsSection couponsList={couponsList} />

      <Divider />

      <CouponsImpressionsStats couponsList={couponsList} />

      <Divider />

      <Row>
        <Col span={24}>
          {currentBusiness && couponsList ? (
            <AdsImpressionsCounts couponsList={couponsList} />
          ) : (
            <div style={{ marginTop: "40px" }}>
              <h3>Image Ads Impressions</h3>
              <Spin indicator={<Loading3QuartersOutlined spin />} />
            </div>
          )}
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col span={12}>
          <Title level={4}>
            <BarChartOutlined /> Compare Aggregate
          </Title>
          Aggregate impressions and redemptions of your coupons. Hover over bar for
          more details.
        </Col>
        <Col span={12} style={{ textAlign: "right" }}></Col>
      </Row>
      <br />
      <Row gutter={[40, 20]}>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          {currentBusiness ? (
            <CouponsImpressionsBarChart couponsList={couponsList} />
          ) : (
            <div style={{ marginTop: "40px" }}>
              <h3>Coupons Impressions</h3>
              <Spin indicator={<Loading3QuartersOutlined spin />} />
            </div>
          )}
        </Col>
        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
          {currentBusiness ? (
            <CouponsRedeemedBarChart couponsList={couponsList} />
          ) : (
            <div style={{ marginTop: "40px" }}>
              <h3>Coupons Redeemed</h3>
              <Spin indicator={<Loading3QuartersOutlined spin />} />
            </div>
          )}
        </Col>
      </Row>

      <Divider />

      {currentBusiness && couponsList ? (
        <RedeemedOvertimeLineChartSection
          currentBusiness={currentBusiness}
          couponsList={couponsList}
        />
      ) : (
        <div style={{ marginTop: "40px" }}>
          <h3>
            <LineChartOutlined /> Coupons Redeemed Over Time
          </h3>
          <Spin indicator={<Loading3QuartersOutlined spin />} />
        </div>
      )}
    </InsightsLayout>
  );
};

export default Insights;
