import React from "react";
import { Table } from "antd";

export const BusinessesTable = ({
  searchToggle,
  searchResults,
  companies,
  columns,
}) => (
  <Table
    size="small"
    dataSource={searchToggle ? searchResults : companies}
    columns={columns}
    pagination={{ defaultPageSize: 20 }}
  />
);

export const ActiveBusinessesTable = ({
  searchToggle,
  activeSearchResults,
  activeCompanies,
  columns,
}) => (
  <Table
    size="small"
    dataSource={searchToggle ? activeSearchResults : activeCompanies}
    columns={columns}
    pagination={{ defaultPageSize: 20 }}
  />
);

export const InactiveBusinessesTable = ({
  searchToggle,
  inactiveSearchResults,
  inactiveCompanies,
  columns,
}) => (
  <Table
    size="small"
    dataSource={searchToggle ? inactiveSearchResults : inactiveCompanies}
    columns={columns}
    pagination={{ defaultPageSize: 20 }}
  />
);
