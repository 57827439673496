import React from "react";
import { StyledMenuItem } from "../../../StyledComponents/styledComponents";
import { Button, Menu, Dropdown } from "antd";
import { EllipsisOutlined, LineChartOutlined } from "@ant-design/icons";
import CouponDetailsModal from "./CouponDetailsModal";
import EditCouponModal from "./EditCouponModal";
import DeleteCouponModal from "./DeleteCouponModal";

const CouponsListActions = ({ currentBusiness, coupon }) => {
  const menu = (
    <Menu className="round">
      <StyledMenuItem disabled>
        <LineChartOutlined />
        View analytics
      </StyledMenuItem>
      <StyledMenuItem>
        <CouponDetailsModal
          modalAction={<>View Coupon</>}
          couponParams={coupon}
        />
      </StyledMenuItem>
      <StyledMenuItem>
        <EditCouponModal
          modalAction={<>Edit Coupon</>}
          couponParams={coupon}
          currentBusiness={currentBusiness}
        />
      </StyledMenuItem>
      <StyledMenuItem danger>
        <DeleteCouponModal currentBusiness={currentBusiness} coupon={coupon} />
      </StyledMenuItem>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <Button shape="round" type="ghost" size="small">
          <EllipsisOutlined />
        </Button>
      </a>
    </Dropdown>
  );
};

export default CouponsListActions;
