import { businessInitState } from '../businessInitState';

export const businessReducer = (state = businessInitState, action) => {
  // console.log('state => ', state);
  // console.log('action => ', action);

  switch (action.type) {
    case 'ADD':
      const { payload } = action;
      console.log('payload: ', payload)
      state = payload;
      return state;
    case 'DELETE':
      return state;
    default:
      return state;
  }
};
