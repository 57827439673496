import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Typography, Spin } from "antd";
import { Loading3QuartersOutlined, LoadingOutlined } from "@ant-design/icons";
import { useGetCurrentAdmin } from "../../hooks";
import QuickActions from "../components/QuickActions";
import FaqPage from "../Faq/FaqPage";

const { Title } = Typography;

const Loader = () => <Spin indicator={<Loading3QuartersOutlined spin />} />;

const DashboardHome = () => {
  const { currentAdmin, currentAdminData } = useGetCurrentAdmin();
  const [adminAuthUser, setAdminAuthUser] = useState(null);

  useEffect(() => {
    if (currentAdmin) {
      setAdminAuthUser(currentAdmin);
    }
  }, [currentAdmin]);

  if (adminAuthUser === null) {
    return <Loader />;
  }

  if (adminAuthUser) {
    return (
      <Row gutter={[0, 20]} style={{ paddingTop: "40px" }}>
        <Col span={24}>
          <Title level={1}>Overview</Title>
          {currentAdminData ? (
            <>Signed in as {currentAdminData.email}</>
          ) : (
            <LoadingOutlined />
          )}
        </Col>
        <Divider />
        <Col span={24}>
          <QuickActions />
        </Col>
        <Divider />
        <Col span={24}>
          <FaqPage />
        </Col>
      </Row>
    );
  }
};

export default DashboardHome;
