import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../constants/routes.js";
import { Wrapper, Container } from "./styledComponents.js";
import { Card, Row, Col, Steps, Button, Spin } from "antd";
import {
  Loading3QuartersOutlined,
  RightOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";

const { Step } = Steps;

export default function CreateAccount() {
  const history = useHistory();
  const [next, setNext] = useState(false);
  const colSizes = {
    xl: 24,
    lg: 24,
    md: 24,
    sm: 24,
    xs: 24,
  };

  function proceed() {
    setNext(true);
    history.push(routes.onboarding.createAccount);
  }

  return (
    <Wrapper>
      <Container>
        <Row lg={24} gutter={[0, 40]}>
          <Col {...colSizes}>
            <div
              style={{
                textAlign: "center",
                fontWeight: "600",
                fontSize: "26px",
              }}
            >
              Student Life 101
            </div>
          </Col>
          <Col {...colSizes}>
            <Steps>
              <Step status="wait" title="Account" icon={<LoadingOutlined />} />
              <Step
                status="wait"
                title="Business"
                icon={<SolutionOutlined />}
              />
              <Step status="wait" title="Coupons" icon={<LoadingOutlined />} />
              <Step status="wait" title="Done" icon={<SmileOutlined />} />
            </Steps>
          </Col>
          <Col {...colSizes} style={{ margin: "0 auto", maxWidth: "720px" }}>
            <Card
              style={{
                padding: "0 20px 0 20px",
                boxShadow:
                  "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
              }}
            >
              <Row lg={24} gutter={[0, 30]}>
                <Col
                  {...colSizes}
                  align="center"
                  style={{ fontSize: "30px", fontWeight: "700" }}
                >
                  Find your business
                </Col>
                <Col
                  xl={24}
                  lg={24}
                  md={24}
                  sm={24}
                  xs={24}
                  style={{ fontSize: "22px" }}
                >
                  We'll walk you through a few steps to get started.
                </Col>
              </Row>
              <Row align="center">
                {next ? (
                  <Button size="large" type="disabled">
                    <Spin
                      indicator={
                        <Loading3QuartersOutlined
                          spin
                          style={{ fontSize: "26px" }}
                        />
                      }
                    />
                  </Button>
                ) : (
                  <Button size="large" type="primary" onClick={proceed}>
                    Continue
                    <RightOutlined />
                  </Button>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
