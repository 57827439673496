import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";
import { useRoutes } from "../../hooks.js";
import { Link } from "react-router-dom";

export const AppFooter = () => {
  const { routes } = useRoutes();

  return (
    <Wrapper>
      <Container>
        <Row span={24}>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Row gutter={20}>
              <Col>
                <Link to={routes.legal.termsAndConditions} target="_blank">
                  Terms
                </Link>
              </Col>
              <Col>
                <Link to={routes.legal.privacyPolicy} target="_blank">
                  Privacy
                </Link>
              </Col>
            </Row>
          </Col>
          <Col lg={12} md={12} sm={12} xs={12} style={{ textAlign: "right" }}>
            Made by <b>Ideas Worth Coding LLC.</b>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  bottom: 0px;
  padding: 40px 10px 40px 10px;
  background-color: #fff;
  border-top: 1px solid #eee;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1080px;
`;
