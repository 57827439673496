import React from "react";
import { Steps, Collapse, Typography } from "antd";
import { useHistory } from "react-router-dom";
import "./styles.scss";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const text = (
  <p style={{ paddingLeft: 24 }}>
    A dog is a type of domesticated animal. Known for its loyalty and
    faithfulness, it can be found as a welcome guest in many households across
    the world.
  </p>
);

const FaqPage = () => {
  const history = useHistory();

  return (
    <>
      <Title level={2}>FAQ</Title>
      <Paragraph>A few common questions and answers</Paragraph>
      <Collapse bordered={true} className="sm-box-shadow round">
        <Panel header="Why don't I see updated data right away?" key="0">
          <ul>
            <li>
              Try refreshing the browser and if the information added isn't showing, contact support.
            </li>
          </ul>
        </Panel>
        <Panel header="How do I view all businesses?" key="1">
          <ul>
            <li>
              Click <b>View Businesses</b>.
            </li>
            <li>
              Click <b>Businesses</b> on the side bar navigation.
            </li>
          </ul>
        </Panel>
        <Panel header="How do I create coupons?" key="2">
          <ul>
            <li>
              <b>Search</b> for the business on the businesses index page.
            </li>
            <li>
              Click the <b>View business</b> from the actions dropdown menu.
            </li>
            <li>
              Click the <b>View business</b> from the actions dropdown menu.
            </li>
            <li>
              On the <b>Add Dropdown</b>, click <b>Coupon</b>
            </li>
            <li>Fill out the necessary fields and submit.</li>
          </ul>
        </Panel>
        <Panel header="How do I create image advertisements?" key="3">
          {text}
        </Panel>
        <Panel
          header="How are my coupons and image advertisements performing?"
          key="4"
        >
          {text}
        </Panel>
        <Panel
          header="How do I add, remove, and update a business' spotlight?"
          key="5"
        >
          {text}
        </Panel>
        <Panel header="How do I change my email address?" key="6">
          {text}
        </Panel>
        <Panel header="How do I reset my password?" key="7">
          {text}
        </Panel>
        <Panel header="How do I sign out of my account?" key="8">
          {text}
        </Panel>
      </Collapse>
    </>
  );
};

export default FaqPage;
