import React from "react";
import { Alert } from "antd";

const SignInError = ({ value }) => {
  const { errorExist, errorMessage } = value;
  const onClose = (e) => {
    console.log(e, "I was closed.");
  };

  return (
    <>
      {errorExist ? (
        <Alert
          message="Error:"
          description={errorMessage}
          type="error"
          closable
          onClose={onClose}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default SignInError;
