import React, { useEffect, useState } from "react";
import { Tag, Spin } from "antd";
import { LoadingOutlined, WarningOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import { useConstants } from "../../../hooks.js";
import { humanizeDate } from "../../../utility/dateUtility.js";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const ExpirationTags = ({ coupon, startDate, expires }) => {
  const { DATES } = useConstants();
  const humanizedDate = humanizeDate(expires);
  // const humanizedDate = moment(expires.toDate()).format(DATES.DATE_FORMAT);
  // const daysLeft = moment().startOf("day").diff(new Date(expires.seconds * 1000), "days") * -1;
  const [daysLeft, setDaysLeft] = useState("");
  const [loading, setLoading] = useState(true);

  // Calculates the days left by differencing the endDate from the startDate.
  useEffect(() => {
    setLoading(true);
    if (typeof startDate !== 'undefined') {
      // const formattedStartDate = moment(expires.toDate())
      // const formatedEndDate = moment(startDate.toDate())
      // const diffDays = formatedEndDate.diff(new Date(), "days") * -1;
      const numberOfdaysLeft = moment().startOf("day").diff(new Date(expires.seconds * 1000), "days") * -1;
      // console.log('numberOfdaysLeft:', numberOfdaysLeft)
      setDaysLeft(numberOfdaysLeft);
    }
    setLoading(false);
  }, [startDate, expires, DATES.DATE_FORMAT, loading])

  if (loading) {
    return <Spin indicator={antIcon} />
  }

  if (daysLeft < 0) {
    return (
      <>
        <Tag>{humanizedDate}</Tag>
        <Tag color="red">
          <WarningOutlined /> expired
        </Tag>
      </>
    );
  } else if (daysLeft === 0) {
    return (
      <>
        <Tag>{humanizedDate}</Tag>
        <Tag color="gold">
          <WarningOutlined /> expires today
        </Tag>
      </>
    );
  } else if (daysLeft <= 7) {
    return (
      <>
        <Tag>{humanizedDate}</Tag>
        <Tag color="gold">
          <CalendarOutlined /> {daysLeft} days left
        </Tag>
      </>
    );
  } else {
    return (
      <>
        <Tag>{humanizedDate}</Tag>
        <Tag color="green">
          <CalendarOutlined /> {daysLeft} days left
        </Tag>
      </>
    );
  }
};

export default ExpirationTags;
