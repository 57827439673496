import React, { useEffect, useState } from "react";
import {
  Divider,
  Tooltip,
  Select,
  InputNumber,
  Form,
  Input,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { QuestionCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";

const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const activeTip = `'Active' will show the icon, 'inactive' will hide the icon`;
const typeTip = `Type of icon (an icon text or just an icon)`;
const colorTip = `Color of text or icon`;
const sizeTip = `Size of text or icon`;
const fontWeightTip = `fontWeight of text (normal or bold)`;
const indexTip = `
  A number to denote the order of the icons and text (low numbers to left,
  high numbers to right). If one object in the spotlight array has a value
  for index then they all must.`;

/**
 *
 * @param {Function} handleCancel
 * @param {Object} spotlight
 * @param {Object} currentBusiness
 *
 * Form to create a edit spotlight
 */
const EditSpotlightForm = ({ handleCancel, spotlight, currentBusiness }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [typeValue, setTypeValue] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // setTypeValue(spotlight.type);
    console.log("current spotlight: ", spotlight);
  }, [spotlight.type, typeValue, loading, spotlight]);

  const handleTypeSelect = (value) => {
    setLoading(true);
    setTypeValue(value);
    setLoading(false);
  };

  /**
   * @param {Object} values
   *
   * Handle form on submit
   */
  const onFinish = (values) => {
    // Only write if value and current business are valid.
    if (values && currentBusiness) {
      const spotlightToUpdate = {
        ...spotlight,
        ...values,
      };

      const { id } = currentBusiness;
      let businessToUpdate = currentBusiness;

      businessToUpdate.spotlight.forEach((item, idx) => {
        if (idx === spotlight.index) {
          businessToUpdate.spotlight[idx] = spotlightToUpdate;
        }
      });

      // console.log("business to update: ", businessToUpdate.spotlight);
      const refPath = `/companies/${id}`;
      firebase.getDb().doUpdateByRefPath(refPath, businessToUpdate, () => {
        dispatch(enableFetch());
        dispatch(disableFetch());
        handleCancel();
        message.success("Successfully updated spotlight.");
      });
    } else {
      message.warn("Oops, something went wrong.");
    }
  };

  /**
   * @param {Object} errorInfo
   *
   * Handle form error on submit
   */
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    message.warn("Missing required inputs");
  };

  /**
   * Render
   */
  return (
    <Form {...layout} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Form.Item
        label={<CustomLabel label="Active" tipText={activeTip} />}
        name="active"
        rules={[{ required: true }]}
        initialValue={spotlight.active}
      >
        <Select>
          <Option value={true}>Active</Option>
          <Option value={false}>Inactive</Option>
        </Select>
      </Form.Item>

      <Divider />

      <Form.Item
        label={<CustomLabel label="Type" tipText={typeTip} />}
        name="label"
        rules={[{ required: true }]}
        initialValue={spotlight.type}
      >
        <Select onSelect={handleTypeSelect}>
          <Option value="string">string</Option>
          <Option value="icon">icon</Option>
          <Option value="stringIcon">string icon</Option>
        </Select>
      </Form.Item>

      {loading ? (
        <LoadingOutlined />
      ) : (
        <>
          {typeValue === "string" ? (
            <Form.Item
              label="Value"
              name="value"
              rules={[{ required: true }]}
              initialValue={spotlight.value}
            >
              <Input placeholder="Flash Deal (exmaple)" />
            </Form.Item>
          ) : (
            <></>
          )}

          {typeValue === "icon" || spotlight.type === "icon" ? (
            <Form.Item
              label="Icon Type"
              name="iconType"
              rules={[{ required: true }]}
              initialValue={spotlight.iconType}
            >
              <Select>
                <Option value="MaterialCommunityIcons">
                  Material Community Icons
                </Option>
                <Option value="MaterialIcons">Material Icons</Option>
                <Option value="Emoji">Emoji</Option>
              </Select>
            </Form.Item>
          ) : (
            <></>
          )}

          {typeValue === "stringIcon" || spotlight.type === "stringIcon" ? (
            <>
              <Form.Item
                label="Value"
                name="value"
                rules={[{ required: true }]}
                initialValue={spotlight.value}
              >
                <Input placeholder="Flash Deal (exmaple)" />
              </Form.Item>
              <Form.Item
                label="Icon Type"
                name="iconType"
                rules={[{ required: true }]}
                initialValue={spotlight.iconType}
              >
                <Select>
                  <Option value="MaterialCommunityIcons">
                    Material Community Icons
                  </Option>
                  <Option value="MaterialIcons">Material Icons</Option>
                  <Option value="Emoji">Emoji</Option>
                </Select>
              </Form.Item>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <Divider style={{ color: "#d6d6d6" }}>Optionals</Divider>

      <Form.Item
        label={<CustomLabel label="font weight" tipText={fontWeightTip} />}
        name="weight"
        initialValue={spotlight.weight}
      >
        <Select>
          <Option value="normal">normal</Option>
          <Option value="bold">bold</Option>
        </Select>
      </Form.Item>

      <Form.Item
        label={<CustomLabel label="color" tipText={colorTip} />}
        name="color"
        initialValue={spotlight.color}
      >
        <Input placeholder="Color of text or icon" />
      </Form.Item>

      <Form.Item
        label={<CustomLabel label="size" tipText={sizeTip} />}
        name="size"
        initialValue={spotlight.size}
      >
        <InputNumber min={0} max={20} />
      </Form.Item>

      <Row justify="end" gutter={[10, 0]}>
        <Col>
          <Button
            className="sm-box-shadow"
            shape="round"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Col>
        <Col>
          <Button
            htmlType="submit"
            className="sm-box-shadow"
            shape="round"
            type="primary"
          >
            Update spotlight
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

/**
 *
 * @param {string} label
 * @param {string} tipText
 *
 * Displays a custom label with a tool tip text.
 */
const CustomLabel = ({ label, tipText }) => (
  <Row gutter={[2, 0]}>
    <Col>{label}</Col>
    <Col>
      <Tooltip placement="top" title={tipText}>
        <QuestionCircleOutlined />
      </Tooltip>
    </Col>
  </Row>
);

export default EditSpotlightForm;
