import React, { useState } from "react";
import { Modal, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";

/**
 *
 * @param {Object} modalAction
 * @param {Object} currentBusiness
 * @param {Object} spotlight
 *
 * Deletes a spotlight from the current business.
 */
const DeleteSpotlightModal = ({ modalAction, currentBusiness, spotlight }) => {
  const firebase = useFirebase();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const handleOk = () => {
    if (currentBusiness && spotlight) {
      const docId = currentBusiness.id;
      let businessToUpdate = currentBusiness;

      // Filter out the spotlight to delete.
      businessToUpdate.spotlight = businessToUpdate.spotlight.filter(
        (item, idx) => item.index !== spotlight.index
      );

      // Re-assign the index to match the array index
      businessToUpdate.spotlight.forEach((item, idx) => {
        item.index = idx;
      });

      // console.log("updated spotlight: ", businessToUpdate.spotlight);

      // Update the current business (company) doc.
      firebase.getDb().doUpdateDoc("companies", docId, businessToUpdate, () => {
        dispatch(enableFetch());
        dispatch(disableFetch());
        handleCancel();
        message.success("Successfully deleted spotlight.");
      });
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <div onClick={() => setVisible(true)}><DeleteOutlined /> Delete spotlight</div>
      <Modal
        title="Delete spotlight"
        visible={visible}
        okText="Delete spotlight"
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          className: "sm-box-shadow",
          shape: "round",
          type: "danger",
        }}
        cancelButtonProps={{
          className: "sm-box-shadow",
          shape: "round",
        }}
      >
        Delete spotlight form
      </Modal>
    </>
  );
};

export default DeleteSpotlightModal;
