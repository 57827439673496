import React, { useEffect } from "react";
import { Card } from "antd";
import { Chart } from "@antv/g2";
import Helpers from "./helpers"

const CouponsImpressionsBarChart = (props) => {
  const { couponsList } = props;

  useEffect(() => {
    if (couponsList) {
      let data = [];
      const redeemables = couponsList.filter((coupon) => coupon.headline !== "" || coupon.headline === undefined);

      redeemables.forEach((coupon) => {
        data.push({
          headline: coupon.headline,
          impression: coupon.impressionsCount,
        });
      });
    }
  }, [couponsList]);

  useEffect(() => {
    if (couponsList) {
      let data = [];
      // const redeemables = couponsList.filter((coupon) => coupon.headline !== "" || coupon.headline === undefined);
      const redeemables = couponsList.filter((coupon, idx) => coupon.type !== "image");
      redeemables.forEach((coupon) => {
        data.push({
          headline: coupon.headline ? coupon.headline.substring(0, 20) : "",
          impression: coupon.impressionsCount,
        });
      });

      data.sort((a, b) => a.impression - b.impression);

      const chart = new Chart({
        container: "coupons-impressions-container",
        autoFit: true,
        height: 280,
      });
      chart.data(data);
      chart.scale({
        value: {
          max: 1400,
          min: 0,
          alias: "",
        },
      });
      chart.axis("headline", {
        title: null,
        tickLine: null,
        line: null,
      });

      chart.axis("impressions count", {
        label: null,
        title: {
          offset: 34,
          style: {
            fontSize: 12,
            fontWeight: 300,
          },
        },
      });

      chart.legend(true);
      chart.coordinate().transpose();
      chart
        .interval()
        .position("headline*impression")
        .size(30)
        .label("impressions count", {
          style: {
            fill: "#8d8d8d",
          },
          offset: 10,
        });
      chart.interaction("element-active");
      chart.render();
      Helpers.cleanCanvas(`coupons-impressions-container`);
    }
  }, [couponsList]);

  return (
    <Card className="sm-box-shadow round">
      <h3>Coupons Impressions</h3>
      <div id="coupons-impressions-container"></div>
    </Card>
  );
};

export default CouponsImpressionsBarChart;
