import React from "react";
import styled from "styled-components";
import { Input } from "antd";

const { Search } = Input;

const SearchBusinessesInput = ({ handleSearch }) => (
  <StyledSearch
    width="100%"
    placeholder="Filter by uid or business name"
    onSearch={(value) => handleSearch(value)}
    enterButton
  />
);

const StyledSearch = styled(Search)`
  input {
    border-top-left-radius: 32px !important;
    border-bottom-left-radius: 32px !important;
  }

  button {
    border-top-right-radius: 32px !important;
    border-bottom-right-radius: 32px !important;
  }
`;

export default SearchBusinessesInput;
