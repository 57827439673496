import React, { useState } from "react";
import {
  Input,
  Button,
  message,
  Form,
  Upload,
  DatePicker,
  Row,
  Col,
} from "antd";
import {
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { formLayout } from "../../../utility/formLayout.js";
import { useFirebase, useConstants, useToolTipText } from "../../../hooks.js";
import moment from "moment";
import { Loader } from "../../../components/Loader.js";
import FormLabelTooltip from "../../../components/FormLabelTooltip";
import { useDispatch } from "react-redux";
import {
  enableFetch,
  disableFetch,
} from "../../../redux/actions/componentLoaderActions";

const EditImageAdForm = (props) => {
  const { currentBusiness } = props;
  const dispatch = useDispatch();
  const { imageAd, handleCancel } = props;
  const firebase = useFirebase();
  const { COLLECTIONS } = useConstants();
  const { TOOL_TIP_TEXT } = useToolTipText();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(imageAd.url);
  const [aspectRatio, setAspectRatio] = useState(imageAd.aspectRatio);
  const [initialImage, setInitialImage] = useState(true);

  const onFinish = async (values) => {
    if (imageUrl === "") {
      message.error("Image is required");
      return;
    }
    let adToUpdate = values;
    const startDate = adToUpdate.startDate.toDate();
    const expires = adToUpdate.expires.toDate();
    adToUpdate.startDate = startDate;
    adToUpdate.expires = expires;

    console.log("ad: ", imageAd);

    const { image } = values;
    const { file } = image;

    // Upload the image
    let url = imageUrl;
    if (!initialImage) {
      const imageData = await firebase.doUploadFile(file, "imageCoupons");
      const { downloadURL } = imageData;
      url = downloadURL; // create the property to add to coupon
    }

    // Update the Ad
    delete values.image; // we don't need the image property anymore
    const update = {
      ...values,
      url,
      aspectRatio,
      link: values.link
    };
    console.log("update ==> ", update);

    const fullRefPath =
      COLLECTIONS.COMPANIES +
      "/" +
      currentBusiness.id +
      "/" +
      COLLECTIONS.COUPONS +
      "/" +
      imageAd.id;

    // Update the Ad
    firebase
      .getDb()
      .doUpdateByRefPath(fullRefPath, update)
      .then(() => {
        dispatch(enableFetch());
        message.success("Ad was successfully updated.");
        handleCancel();
        dispatch(disableFetch());
        // history.push(routes.dashboard.ads.index);
      })
      .catch((error) => {
        console.log("error updating image:", error);
      });
  };

  const onFinishFailed = (e) => {
    console.log(e);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleOnUploadChange = (info) => {
    // We don't want multiple images uploaded.
    let { file } = info;
    let newFileList = [file];
    info.fileList = newFileList;

    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, async (imageUrl) => {
        const dimensions = await getImageDimensions(imageUrl);
        const ratio = (dimensions.w / dimensions.h).toFixed(2);
        setImageUrl(imageUrl);
        setInitialImage(false);
        setAspectRatio(parseFloat(ratio));
        setLoading(false);
      });
    }
  };

  function getImageDimensions(file) {
    return new Promise(function (resolved, rejected) {
      var i = new Image();
      i.onload = function () {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  }

  const handleRemoveImage = () => {
    setImageUrl("");
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Form
      {...formLayout}
      name="basic"
      layout="horizontal"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        style={{ width: "250px" }}
        label="Upload Ad Image"
        name="image"
        initialValue={imageUrl}
        rules={[
          {
            required: true,
            message: "Image is required.",
          },
        ]}
      >
        <Upload.Dragger
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleOnUploadChange}
          multiple={false}
          disabled={imageUrl !== ""}
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess("ok");
            }, 0);
          }}
        >
          {imageUrl ? (
            <Row span={24}>
              <Col span={24}>
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              </Col>
              <Col span={24} style={{ paddingTop: "10px" }}>
                <Button type="link" onClick={handleRemoveImage}>
                  <DeleteOutlined
                    style={{ fontSize: "18px", position: "relative" }}
                  />
                  Remove Image
                </Button>
              </Col>
            </Row>
          ) : (
            <>
              {uploadButton}
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">Files must be smaller than 2mb</p>
            </>
          )}
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        label="Link"
        name="link"
        initialValue={typeof imageAd.link === "undefined" ? "" : imageAd.link}
      >
        <Input size="large" />
      </Form.Item>

      <Row span={24} gutter={[20, 0]}>
        <Col span={12}>
          <Form.Item
            label={
              <FormLabelTooltip
                name="Start Date"
                tooltipText={TOOL_TIP_TEXT.startDate}
              />
            }
            name="startDate"
            initialValue={
              typeof imageAd.startDate !== "undefined"
                ? moment(imageAd.startDate.toDate())
                : ""
            }
            rules={[{ required: true, message: "Please enter a start date." }]}
          >
            <DatePicker size="large" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <FormLabelTooltip
                name="Expiration Date"
                tooltipText={TOOL_TIP_TEXT.expirationDate}
              />
            }
            name="expires"
            initialValue={
              typeof imageAd.expires !== "undefined"
                ? moment(imageAd.expires.toDate())
                : ""
            }
            rules={[
              { required: true, message: "Please enter an expiration date." },
            ]}
          >
            <DatePicker size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Row gutter={10} justify="end">
          <Col>
            <Button
              block
              onClick={() => handleCancel()}
              shape="round"
              size="large"
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              block
              type="primary"
              htmlType="submit"
              shape="round"
              size="large"
            >
              Update
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default EditImageAdForm;
