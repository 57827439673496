import React from "react";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { Collapse, Divider, Row, Col, Typography } from "antd";
import { StarOutlined } from "@ant-design/icons";
import HomeLayout from "./components/HomeLayout";
import QuickActions from "./components/QuickActions";
import { useGetCurrentUser } from "../../hooks";

const { Panel } = Collapse;
const { Title, Paragraph } = Typography;

export default function Home() {
  const { currentUser } = useGetCurrentUser();

  function callback(key) {
    console.log(key);
  }

  return (
    <HomeLayout>
      <Title level={1}>Welcome to your dashboard</Title>
      {currentUser ? (
        <Paragraph>Signed in as {currentUser.email}</Paragraph>
      ) : (
        <></>
      )}
      <Divider />
      <Title level={3}>Let's get started</Title>
      <QuickActions />
      <Divider />
      <Title level={3}>Learn the basics</Title>
      <br />
      <Collapse
        defaultActiveKey={["0"]}
        onChange={callback}
        className="sm-box-shadow round"
      >
        <Panel
          header={
            <Row>
              <Col span={23}>
                <Title level={4}>What are ad impressions?</Title>
              </Col>
              <Col span={1}>
                <Title level={4}>
                  <StarOutlined />
                </Title>
              </Col>
            </Row>
          }
          key="0"
        >
          <div>
            <b>Impressions: Definition</b>
          </div>
          <div>
            How often your ad is shown. An impression is counted each time your
            ad is shown on a search result page or other site on the Google
            Network.
          </div>
          <a
            href="https://support.google.com/google-ads/answer/6320?hl=en"
            target="_blank"
          >
            Reference support Google
          </a>

          <ul>
            <li>
              Each time your ad appears on Google or the Google Network, it's
              counted as one impression.
            </li>
            <li>
              In some cases, only a section of your ad may be shown. For
              example, in Google Maps, we may show only your business name and
              location or only your business name and the first line of your ad
              text.
            </li>
            <li>
              You'll sometimes see the abbreviation "Impr" in your account
              showing the number of impressions for your ad.
            </li>
          </ul>
        </Panel>

        <Panel
          header={<Title level={4}>How do I create a new coupon?</Title>}
          key="1"
        >
          <ul>
            <li>
              Go to your{" "}
              <Link to={routes.dashboard.business.index}>
                <b>Business Profile</b>
              </Link>{" "}
              page.
            </li>
            <li>
              Hover over the <b>Add</b> dropdown button.
            </li>
            <li>
              Select <b>Create a new coupon</b> and fill in the required input
              fields.
            </li>
          </ul>
        </Panel>
        <Panel
          header={
            <Title level={4}>How do I create a new image advertisement?</Title>
          }
          key="2"
        >
          <ul>
            <li>
              Go to your{" "}
              <Link to={routes.dashboard.business.index}>
                <b>Business Profile</b>
              </Link>{" "}
              page.
            </li>
            <li>
              Hover over the <b>Add</b> dropdown button.
            </li>
            <li>
              Select <b>Create a new image ad</b> and fill in the required input
              fields.
            </li>
          </ul>
        </Panel>
        <Panel
          header={
            <Row>
              <Col span={23}>
                <Title level={4}>How can insights help me?</Title>
              </Col>
              <Col span={1}>
                <Title level={4}>
                  <StarOutlined />
                </Title>
              </Col>
            </Row>
          }
          key="3"
        >
          <ul>
            <li>Bar charts allow you to compare deals.</li>
            <li>
              Line charts allow you to see when coupons are spiking in
              redemptions.
            </li>
            <li>
              Allows you to compare which image advertisements are most viewed.
            </li>
            <li>
              Use these insights to help you plan accordingly and help grow your
              customer base.
            </li>
          </ul>
        </Panel>
        <Panel
          header={
            <Title level={4}>
              How do I monitor my coupons and image advertisements?
            </Title>
          }
          key="4"
        >
          <ul>
            <li>
              Go to your{" "}
              <Link to={routes.dashboard.insights.index}>
                <b>Insights</b>
              </Link>{" "}
              page.
            </li>
            <li>
              Insights displays impression (unqiue views) of coupons and totals
              for how many have been redeemed for each.
            </li>
            <li>
              Live tracking for how your coupons are being redeemed over a
              number of days.
            </li>
            <li>
              Coupons redeemed over time can be filtered by the given days.
            </li>
            <li>
              See impressions (unique views) totals for each of your image ads.
            </li>
            <li>
              Impression totals do not update live. To check for increases in
              impressions total, click the <b>Update</b> button located on the
              insights page at the top right.
            </li>
          </ul>
        </Panel>
      </Collapse>
    </HomeLayout>
  );
}
