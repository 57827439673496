import React, { useEffect } from "react";
import { Tag, Table, Button, Empty, Row, Col } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import NewSpotlightModal from "./NewSpotlightModal";
import SpotlightTableActions from "./SpotlightTableActions";
import { useSelector } from "react-redux";

/**
 *
 * @param {Object} spotlight
 * @param {Object} currentBusiness
 *
 * Displays the list of spotlights.
 */
const SpotlightsList = ({ spotlight, currentBusiness }) => {
  const { componentLoaderReducer } = useSelector((state) => state);
  const { loading } = componentLoaderReducer;

  useEffect(() => {}, [loading, currentBusiness]);

  const columns = [
    {
      title: "Order",
      dataIndex: "index",
      render: (actions, record) => <>{record.index + 1}</>,
    },
    {
      title: "Active",
      dataIndex: "active",
      render: (active) => (
        <>
          {active ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Icon Type",
      dataIndex: "iconType",
    },
    {
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Font Weight",
      dataIndex: "weight",
      render: (weight) => <>{weight === "bold" ? <b>Bold</b> : "normal"}</>,
    },
    {
      title: "Color",
      dataIndex: "color",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (actions, record) => (
        <SpotlightTableActions
          currentBusiness={currentBusiness}
          spotlight={record}
        />
      ),
    },
  ];

  const EmptyTable = () => (
    <Empty description="" style={{ paddingTop: "40px" }}>
      <Row align="center" gutter={[0, 20]}>
        <Col span={24} align="center">
          <b>Create a your first spotlight</b>
        </Col>
        <Col span={24} align="center">
          <NewSpotlightModal
            currentBusiness={currentBusiness}
            modalAction={
              <Button
                icon={<PlusOutlined />}
                className="sm-box-shadow"
                shape="round"
              >
                Create a spotlight
              </Button>
            }
          />
        </Col>
      </Row>
    </Empty>
  );

  if (typeof currentBusiness.spotlight === "undefined") {
    return <EmptyTable />;
  }

  if (currentBusiness.spotlight.length === 0) {
    return <EmptyTable />;
  }

  if (currentBusiness.spotlight.length > 0 || currentBusiness) {
    return (
      <Table
        size="small"
        columns={columns}
        dataSource={currentBusiness.spotlight}
      />
    );
  }
};

export default SpotlightsList;
