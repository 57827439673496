import React from "react";
import NewCouponForm from "./NewCouponForm.js";
import AppPageHeader from "../../../components/AppPageHeader.js";
import { Typography } from "antd";
import { Container } from "../../../StyledComponents/styledComponents.js";

const { Title } = Typography;

export default function NewCouponPage() {
  return (
    <Container>
      <AppPageHeader />
      <br />
      <Title level={3}>New Coupon</Title>
      <p style={{ fontSize: "14px" }}>
        Coupons are one-time redeemable and are availble only through the mobile
        application.
      </p>
      <br />
      <NewCouponForm />
    </Container>
  );
}
