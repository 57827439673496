import React, { useState } from "react";
import { Button, Modal, Row, Col } from "antd";
import { EditOutlined } from "@ant-design/icons";
import EditCompanyForm from "../../Company/EditCompany/EditCompanyForm.js";

export default function EditBusinessModal(props) {
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <>
      <a onClick={showModal}>
        <Button shape="round" icon={<EditOutlined />} size="large">
          Edit
        </Button>
      </a>
      <Modal
        closable={false}
        closeIcon={false}
        visible={visible}
        footer={false}
        onOk={handleOk}
        onCancel={handleCancel}
        title="Edit business"
      >
        <Row
          style={{
            borderBottom: "1px solid #eee",
            paddingBottom: "6px",
            marginBottom: "6px",
          }}
        >
          <Col span={24}>
            <EditCompanyForm handleCancel={handleCancel} />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
