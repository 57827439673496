import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Menu, Row, Col, Input, Button, Form } from "antd";
import { FrownFilled, MehFilled, SmileFilled } from "@ant-design/icons";
import { useFirebase, useGetCompanyData } from "../../../hooks";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 20, span: 24 },
};

export default function FeedbackMenu() {
  const firebase = useFirebase();
  const { companyData } = useGetCompanyData();
  const [mood, setMood] = useState(null);
  const [status, setStatus] = useState("open");

  useEffect(() => {
    console.log("mood: ", mood);
  }, [mood]);

  const onFinish = (values) => {
    if (values && mood) {
      const { message } = values;
      const { id, data } = companyData;
      const { name } = data;
      const ticket = {
        company: name,
        companyRef: id,
        message,
        mood,
        status,
        created: firebase.getApp().firestore.Timestamp.now(),
      };
      firebase.getDb().addToCollection("tickets", ticket);
      setMood(""); // reset the mood
      message.success("Your feedback was sent successfully.");
    }
  };

  const onFinishFailed = () => {};

  const renderFace = () => {
    if (mood === "unhappy") {
      return (
        <Row align="center" gutter={[30, 20]}>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ textAlign: "center" }}
          >
            <h4>How would you rate your experience?</h4>
          </Col>
          <Col>
            <StyledFrown active />
          </Col>
          <Col>
            <StyledMeh
              style={{ fontSize: "32px" }}
              onClick={() => setMood("neutral")}
            />
          </Col>
          <Col>
            <StyledSmile
              style={{ fontSize: "32px" }}
              onClick={() => setMood("happy")}
            />
          </Col>
        </Row>
      );
    } else if (mood === "neutral") {
      return (
        <Row align="center" gutter={[30, 20]}>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ textAlign: "center" }}
          >
            <h4>How would you rate your experience?</h4>
          </Col>
          <Col>
            <StyledFrown onClick={() => setMood("unhappy")} />
          </Col>
          <Col>
            <StyledMeh active />
          </Col>
          <Col>
            <StyledSmile onClick={() => setMood("happy")} />
          </Col>
        </Row>
      );
    } else if (mood === "happy") {
      return (
        <Row align="center" gutter={[30, 20]}>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ textAlign: "center" }}
          >
            <h4>How would you rate your experience?</h4>
          </Col>
          <Col>
            <StyledFrown onClick={() => setMood("unhappy")} />
          </Col>
          <Col>
            <StyledMeh onClick={() => setMood("neutral")} />
          </Col>
          <Col>
            <StyledSmile active />
          </Col>
        </Row>
      );
    }
  };

  return (
    <Menu
      style={{
        boxShadow:
          "0 2px 3px 0 rgba(60,64,67,.3), 0 2px 4px 2px rgba(60,64,67,.15)",
        zIndex: "99",
      }}
    >
      {mood ? (
        <div
          style={{
            padding: "40px 10px 40px 10px",
            width: "450px",
            height: "350px",
          }}
        >
          <Row align="center" gutter={[30, 20]}>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ textAlign: "center" }}
            >
              <h4>{renderFace()}</h4>
            </Col>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: "A message is required" }]}
                >
                  <Input.TextArea
                    placeholder="Tell us about your experience..."
                    autoSize={{ minRows: 6, maxRows: 6 }}
                  />
                </Form.Item>
                <Form.Item {...tailLayout}>
                  <Button
                    shape="round"
                    type="primary"
                    htmlType="submit"
                    className="box-shadow"
                  >
                    Send
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      ) : (
        <div
          style={{
            padding: "40px 10px 40px 10px",
            width: "450px",
            height: "140px",
          }}
        >
          <Row align="center" gutter={[30, 20]}>
            <Col
              xl={24}
              lg={24}
              md={24}
              sm={24}
              xs={24}
              style={{ textAlign: "center" }}
            >
              <h4>How would you rate your experience?</h4>
            </Col>
            <Col>
              <StyledFrown onClick={() => setMood("unhappy")} />
            </Col>
            <Col>
              <StyledMeh onClick={() => setMood("neutral")} />
            </Col>
            <Col>
              <StyledSmile onClick={() => setMood("happy")} />
            </Col>
          </Row>
        </div>
      )}
    </Menu>
  );
}

const StyledFrown = styled(FrownFilled)`
  font-size: 32px;
  color: ${(props) => (props.active ? "#fadb14" : "#d3d3d3")};

  &:hover {
    color: #fadb14;
    cursor: pointer;
  }
`;

const StyledMeh = styled(MehFilled)`
  font-size: 32px;
  color: ${(props) => (props.active ? "#fadb14" : "#d3d3d3")};

  &:hover {
    color: #fadb14;
    cursor: pointer;
  }
`;

const StyledSmile = styled(SmileFilled)`
  font-size: 32px;
  color: ${(props) => (props.active ? "#fadb14" : "#d3d3d3")};

  &:hover {
    color: #fadb14;
    cursor: pointer;
  }
`;
