import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Form, Input, message } from "antd";
import { useFirebase, useGetCurrentUser } from "../../hooks.js";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

export const EditPasswordForm = () => {
  const firebase = useFirebase();
  const { currentUser } = useGetCurrentUser();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (currentUser !== null || currentUser !== undefined) {
      console.log("currentUser: ", currentUser);
      setUser(currentUser);
    }
  }, [currentUser]);

  if (!user) {
    return "Loading";
  }

  const onFinish = (value) => {
    const { email } = value;
    if (email === currentUser.email) {
      console.log("Emails match!");
      firebase.doSendPasswordResetEmail(email);
      message.success("Check your email to reset your password.");
    }
  };

  const onFinishFailed = () => {};

  return (
    <FormWrapper>
      <p style={{ marginBottom: "40px" }}>
        Enter your account's email address to reset your password.
      </p>
      <Form {...layout} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          label="Email Address"
          name="email"
          rules={[{ required: true, message: "Email address required." }]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            className="box-shadow"
            size="medium"
            type="primary"
            shape="round"
            htmlType="submit"
          >
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  margin: 0 0 60px 0;
`;
