import React from "react";
import styled from "styled-components";
import { Row, Col, Card, Button } from "antd";
import HeaderNav from "../Landing/HeaderNav.js";

const PricingPage = () => (
  <LandingWrapper>
    <GradientBackground />
    <HeaderNav />
    <br />
    <HomePageHero>
      <Row gutter={[20, 0]}>
        <Col span={24}>
          <Row vertical gutter={[0, 0]}>
            <Col style={{ zIndex: "99" }}>
              <H1>
                Pricing built for businesses of all sizes. Always know how
                you're coupons are doing.
              </H1>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <br />
      <Row>
        <Col lg={12}>
          <PrimaryPrice style={{ height: "480px" }}>
            <Row
              align="center"
              style={{
                textTransform: "uppercase",
                fontSize: "22px",
                fontWeight: 500,
                paddingBottom: "20px",
                borderBottom: "2px solid #f6f9fc",
              }}
            >
              Primary Price
            </Row>
          </PrimaryPrice>
        </Col>
        <Col lg={12}>
          <SecondaryPrice
            style={{
              height: "400px",
              marginTop: "30px",
              paddingBottom: "20px",
              borderBottom: "2px solid #f6f9fc",
            }}
          >
            <Row
              align="center"
              style={{
                textTransform: "uppercase",
                fontSize: "22px",
                fontWeight: 500,
              }}
            >
              Secondary Price
            </Row>
          </SecondaryPrice>
        </Col>
      </Row>
    </HomePageHero>
  </LandingWrapper>
);

const LandingWrapper = styled.div`
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
  min-height: 100vh;
`;

const HomePageHero = styled.div`
  margin: 0 auto;
  max-width: 980px;
  height: 640px;
  padding: 100px 10px 0 10px;
  background-color: #ffffff;
`;

const GradientBackground = styled.div`
  transform: rotate(170deg) translateY(65%);
  position: absolute;
  overflow: hidden !important;
  min-width: 3200px;
  min-height: 1400px;
  background: rgb(144, 218, 53);
  background: linear-gradient(
    90deg,
    rgba(144, 218, 53, 1) 0%,
    rgba(112, 218, 53, 1) 6%,
    rgba(23, 206, 192, 1) 99%
  );
`;

const H1 = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 1.2em;
  text-align: center;
  color: #32325d;
`;

const PrimaryPrice = styled(Card)`
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
`;

const SecondaryPrice = styled(Card)`
  color: #fff;
  box-shadow: none !important;
  background-color: #32325d;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
`;

export default PricingPage;
