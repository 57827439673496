import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Checkbox, Select } from "antd";
import { enableLoader } from "../../redux/actions/fullPageLoaderActions.js";
import { useFirebase, useRoutes, useConstants } from "../../hooks";
import SignUpError from "./SignUpError.js";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

const SignUpForm = () => {
  const dispatch = useDispatch();
  const { COLLECTIONS } = useConstants();
  const { routes } = useRoutes();
  const firebase = useFirebase();
  const { history } = useReactRouter();
  const [errorExist, setErrorsExist] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    firebase
      .getDb()
      .doFirestoreGetCollection(COLLECTIONS.COMPANIES)
      .then((collectionsList) => {
        setCompanies([...collectionsList]);
      });
  }, [COLLECTIONS.COMPANIES, firebase]);

  const onFinish = (values) => {
    const { email, password, companyRefPath } = values;

    firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then((authUser) => {
        // Sign in
        firebase.doSignInWithEmailAndPassword(email, password).then((data) => {
          const authUser = data.user;
          const doc = authUser.uid;
          const setObj = {
            companyRefPath,
            uid: authUser.uid,
            email,
          };

          // Add user to database and redirect to dashboard
          firebase
            .getDb()
            .doFirestoreSet(COLLECTIONS.COMPANY_USERS, doc, setObj, () => {
              dispatch(enableLoader("Setting up your account..."));
              history.push(routes.dashboard.home);
            });
        });
      })
      .catch((error) => {
        if (error) {
          const { message } = error;
          setErrorsExist(true);
          setErrorMessage(message);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <>
      <SignUpError value={{ errorExist, errorMessage }} />
      <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        scrollToFirstError={true}
        size="large"
      >
        {/* Email */}
        <Form.Item
          label={<b>Email</b>}
          name="email"
          rules={[{ required: true, message: "Please input your email" }]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
        <br />

        {/* Company */}
        <Form.Item
          label={<b>Company</b>}
          name="companyRefPath"
          rules={[{ required: true, message: "Please input your company" }]}
        >
          <Select
            placeholder="Select your company"
            showSearch={true}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            autoComplete="stopDamnAutocomplete"
          >
            {companies.length > 0 ? (
              <>
                {companies.map((idx, key) => (
                  <Select.Option key={key} value={idx.docRefPath}>
                    {idx.docData.name}
                  </Select.Option>
                ))}
              </>
            ) : (
              <></>
            )}
          </Select>
        </Form.Item>
        <br />

        {/* Password */}
        <Form.Item
          label={<b>Password</b>}
          name="password"
          rules={[{ required: true, message: "Please input your password" }]}
        >
          <Input.Password placeholder="Enter your password" />
        </Form.Item>

        <Form.Item {...tailLayout} name="remember" valuePropName="checked">
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" block className="sm-box-shadow">
            Create Account
          </Button>{" "}
        </Form.Item>
      </Form>
    </>
  );
};

export default SignUpForm;
