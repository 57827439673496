import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { Chart } from "@antv/g2";
import Helpers from "./helpers";

const CouponsRedeemedBarChart = (props) => {
  // const { currentBusiness } = props;
  const { couponsList } = props;

  useEffect(() => {
    if (couponsList) {
      let data = [];

      const coupons = couponsList.filter(
        (coupon, idx) => coupon.type !== "image"
      );

      coupons.forEach((coupon, idx) => {
        data.push({
          headline: coupon.headline ? coupon.headline.substring(0, 20) : "",
          redeemedCount: coupon.redeemed ? coupon.redeemed.length : 0,
        });
      });

      data.sort((a, b) => a.redeemedCount - b.redeemedCount);

      const chart = new Chart({
        container: "coupons-redeemed-container",
        autoFit: true,
        height: 280,
      });

      chart.data(data);
      chart.scale({
        value: {
          max: 1400,
          min: 0,
          alias: "",
        },
      });
      chart.axis("headline", {
        title: null,
        tickLine: null,
        line: null,
      });

      chart.axis("redeemed count", {
        label: null,
        title: {
          offset: 34,
          style: {
            fontSize: 12,
            fontWeight: 300,
          },
        },
      });

      chart.legend(true);
      chart.coordinate().transpose();
      chart
        .interval()
        .position("headline*redeemedCount")
        .size(30)
        .label("redeemed count", {
          style: {
            fill: "#8d8d8d",
          },
          offset: 10,
        });
      chart.interaction("element-active");
      chart.render();
      Helpers.cleanCanvas(`coupons-redeemed-container`);
    }
  }, [couponsList]);

  return (
    <Card className="sm-box-shadow round">
      <h3>Coupons Redeemed</h3>
      <div id="coupons-redeemed-container"></div>
    </Card>
  );
};

export default CouponsRedeemedBarChart;
