import React from "react";
import { Wrapper, Container } from "./styledComponents.js";
import { Card, Row, Col, Steps } from "antd";
import {
  UserOutlined,
  SmileOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import SignUpForm from "./SignUpForm";

const { Step } = Steps;

export default function Onboarding() {
  return (
    <Wrapper>
      <Container>
        <Row span={24} gutter={[0, 40]}>
          <Col span={24}>
            <Steps>
              <Step status="process" title="Sign Up" icon={<UserOutlined />} />
              <Step status="wait" title="Pay" icon={<CreditCardOutlined />} />
              <Step status="wait" title="Done" icon={<SmileOutlined />} />
            </Steps>
          </Col>
          <Col span={24}>
            <Card className="round box-shadow">
              <SignUpForm />
            </Card>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
}
