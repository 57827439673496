import React, { useState } from "react";
import { Card, Typography, message, Modal, Button, Row, Col } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { ExclamationCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import { useFirebase } from "../../../hooks.js";
import { enableFetch } from "../../../redux/actions/componentLoaderActions.js";
import { useDispatch } from "react-redux";

const { Paragraph, Text } = Typography;

/**
 *
 * @param {Object} currentBusiness
 * @param {Object} coupon
 * Receives the current business and the business' coupon to delete.
 */
const DeleteCouponModal = ({ currentBusiness, coupon }) => {
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [visible, setVisible] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleDelete = () => {
    setProcessing(true);
    if (coupon) {
      const couponDocId = coupon.id;
      const couponRefPath = `/companies/${currentBusiness.id}/coupons`;

      firebase
        .getDb()
        .deleteDocFromCollection(couponRefPath, couponDocId)
        .then(() => {
          dispatch(enableFetch());
          setVisible(false);
          message.success("Successfully deleted coupon.");
          setProcessing(false);
        });
    }
  };

  return (
    <>
      <span style={{ height: "10px" }} onClick={() => setVisible(true)}>
        <DeleteOutlined />
        Delete Coupon
      </span>
      <Modal
        title="Confirm Delete"
        visible={visible}
        footer={false}
        onCancel={() => setVisible(false)}
      >
        <Row span={24} style={{ marginBottom: "40px" }}>
          <Col span={22} style={{ fontSize: "16px", paddingTop: "4px" }}>
            <p>
              Deleting this <Text strong>{coupon.headline}</Text> will also
              remove all analytics and data associated to this coupon. This is
              an irreversible action.
            </p>
            <p>Create a new coupon with the same headline to "continue" it.</p>
            <br />
            <p>Confirm delete?</p>
          </Col>
        </Row>
        <Row gutter={10} justify="end">
          {processing ? (
            <Col>
              <Button
                disabled={processing}
                shape="round"
                className="box-shadow"
                icon={<LoadingOutlined />}
              >
                Deleting coupon
              </Button>
            </Col>
          ) : (
            <>
              <Col>
                <Button
                  shape="round"
                  type="ghost"
                  onClick={() => setVisible(false)}
                  className="box-shadow"
                >
                  Cancel
                </Button>
              </Col>
              <Col>
                <Button
                  shape="round"
                  type="danger"
                  onClick={handleDelete}
                  className="box-shadow"
                >
                  Delete Coupon
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Modal>
    </>
  );
};

export default DeleteCouponModal;
