import React from "react";
import styled from "styled-components";
import { Switch, Route } from "react-router-dom";
import { routes } from "../../constants/routes";
import NotFound from "../../ClientApp/ErrorResultPages/NotFound";
import { useGetCurrentAdmin } from "../../hooks";
import { withAdminAuth } from "../../components/hocs/hocs";
import DashboardHome from "../Dashboard/DashboardHome";
import Businesses from "../Businesses/Businesses";
import Business from "../Businesses/Business";
import Users from "../Users/Users";
import UserDetails from "../Users/UserDetails";
import Tickets from "../Tickets/Tickets";
import ProductsPage from "../Products/ProductsPage";
import AdminLayout from "./components/AdminLayout";
import { AuthLoader } from "./components/AuthLoader";
import Insights from "../Insights/Insights";

const AdminDashboard = () => {
  const { currentAdminData } = useGetCurrentAdmin();

  if (currentAdminData === null) {
    return <AuthLoader />
  }

  if (currentAdminData) {
    return (
      <AdminLayout>
        <Route
          render={({ location }) => (
            <DashboardContainer>
              <Switch location={location}>
                <Route path={routes.admin.insights.show} component={Insights} />
                <Route path={routes.admin.users.show} component={UserDetails} />
                <Route exact path={routes.admin.users.index} component={Users} />
                <Route exact path={routes.admin.products.index} component={ProductsPage} />
                <Route exact path={routes.admin.businesses.index} component={Businesses} />
                <Route path={routes.admin.businesses.show} component={Business} />
                <Route exact path={routes.admin.tickets.index} component={Tickets} />
                <Route exact path={routes.admin.dashboard.home} component={DashboardHome} />
                <Route render={() => <NotFound />} />
              </Switch>
            </DashboardContainer>
          )}
        />
      </AdminLayout>
    );
  }
};

export default withAdminAuth(AdminDashboard);

const DashboardContainer = styled.div`
  padding-top: 50px;
`;
