import React from "react";
import styled from "styled-components";

const InsightsLayout = ({ children }) => {
  return (
    <Wrapper>
      <Container>
        {children}
      </Container>
    </Wrapper>
  )
}

export default InsightsLayout;

const Wrapper = styled.div`
  margin: 0 auto;
`

const Container = styled.div`
  margin: 0 auto;
  padding: 0 60px 0 60px;
`