// export const enableLoader = () => ({
//   type: "TRUE"
// });
//
// export const disableLoader = () => ({
//   type: "FALSE"
// });

export const enableFetch = () => ({
  type: "TRUE"
});

export const disableFetch = () => ({
  type: "FALSE"
});