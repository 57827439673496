import moment from "moment";
import { DATE_FORMAT } from "../constants/dateFormat.js";

/**
 * Accepts a firebase timestamp and formats it to a readable date format.
 * @param {Timestamp} dateToFormat
 */
export const humanizeDate = (dateToFormat) => {
  return moment(dateToFormat.toDate()).format(DATE_FORMAT);
};

/**
 * Calculates from the start date till current date.
 * @param {object} coupon
 */
export const calculateDaysActive = (coupon) => {
  let daysActive = null;
  const { startDate } = coupon;
  const { expires } = coupon;

  const currentDate = moment();

  if (startDate !== undefined && expires !== undefined) {
    const formattedStartDate = moment(coupon.startDate.toDate());
    const formattedEndDate = moment(coupon.expires.toDate());

    // console.log(formattedStartDate)
    // console.log(formattedEndDate)
    // console.log(currentDate)

    daysActive = currentDate.diff(formattedStartDate, "days");
  }

  return daysActive;
};

export const isExpired = (coupon) => {
  let isExpired = false;
  let daysUntilExpire = null

  if (coupon !== null || coupon !== undefined) {
    const { startDate } = coupon;
    const { expires } = coupon;

    if (startDate !== undefined && expires !== undefined) {
      // const formattedStartDate = moment(coupon.startDate.toDate());
      // const formattedEndDate = moment(coupon.expires.toDate());

      // daysUntilExpire = moment().startOf('day').diff(new Date(expires.seconds*1000), 'days')*-1
      daysUntilExpire = moment().endOf('day').diff(new Date(expires.seconds*1000), 'days')*-1
      isExpired = daysUntilExpire < 0 ? true : false;
      return isExpired;
    }
  }
};
