import React from "react";
import styled from "styled-components";
import { Typography, Card, Row, Col } from "antd";
import { SignInForm } from "./SignInForm.js";
import SignInNav from "./SignInNav.js";
import { AppFooter } from "../../../components/Footer/AppFooter.js";

const { Title } = Typography;

export const SignIn = () => {
  return (
    <Wrapper>
      <Container>
        <Row gutter={[0, 30]} style={{ paddingTop: "20px" }}>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ textAlign: "center" }}
          >
            <SignInNav />
          </Col>
          <Col
            xl={24}
            lg={24}
            md={24}
            sm={24}
            xs={24}
            style={{ minHeight: "600px" }}
          >
            <StyledCard className="med-box-shadow round">
              <Title level={4}>Sign in to your account</Title>
              <SignInForm />
            </StyledCard>
            {/* <div style={{ textAlign: "center" }}>
              Don't have an account?{" "}
              <Link to={routes.auth.signUp}>Sign up</Link>
            </div> */}
          </Col>
        </Row>
      </Container>
      <AppFooter />
    </Wrapper>
  );
};

/**
 * Styled Components
 */

const StyledCard = styled(Card)`
  padding: 4px 24px 4px 24px;
`;

const Wrapper = styled.div`
  background-color: #f7fafc !important;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 480px;
  min-height: 100vh;
  padding: 30px 10px 0 10px;
`;
