import React, { useState } from "react";
import { Button, Modal, Row, Col, Typography } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";

const { Text } = Typography;

const CouponDetailsModal = ({ modalAction, couponParams }) => {
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  return (
    <>
      <a onClick={showModal}><EyeOutlined /> {modalAction}</a>
      <Modal
        title="Coupon details"
        visible={visible}
        onCancel={handleCancel}
        footer={false}
      >
        <Row gutter={[0, 20]} span={24}>
          <Col span={24}>
            <div>
              <Text strong>UID:</Text>
            </div>
            {couponParams.id}
          </Col>
          <Col span={24}>
            <div>
              <Text strong>Headline</Text>
            </div>
            {couponParams.headline}
          </Col>
          <Col span={24}>
            <div>
              <Text strong>Subline</Text>
            </div>
            {couponParams.subline}
          </Col>
          <Col span={24}>
            <div>
              <Text strong>Fineprint</Text>
            </div>
            {couponParams.finePrint}
          </Col>
        </Row>
        <Row span={24}>
          <Col span={12}>
            <div>
              <Text strong>Start Date</Text>
            </div>
            {moment(couponParams.startDate.toDate()).format("MM/DD/YYYY")}
          </Col>
          <Col span={12}>
            <div>
              <Text strong>End Date</Text>
            </div>
            {moment(couponParams.expires.toDate()).format("MM/DD/YYYY")}
          </Col>
        </Row>
        <Row justify="end">
          <Button
            type="default"
            className="sm-box-shadow"
            shape="round"
            onClick={handleCancel}
          >
            Close
          </Button>
        </Row>
      </Modal>
    </>
  );
};

export default CouponDetailsModal;
