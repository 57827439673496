import React from "react";
import { Wrapper, Container } from "../../../StyledComponents/styledComponents";

const HomeLayout = ({ children }) => {
  return (
    <Wrapper>
      <Container>{children}</Container>
    </Wrapper>
  );
};

export default HomeLayout;
