import React from "react";
import {
  Container,
  Wrapper,
} from "../../StyledComponents/styledComponents.js";
import { Typography, Card, Divider } from "antd";
import { EmailSection } from "./EmailSection";
import { PasswordSection } from "./PasswordSection";

const { Title } = Typography;

const Account = () => {
  return (
    <Wrapper>
      <Container>
        <Card className="box-shadow round">
          <Title level={2}>Business Settings</Title>
          <p>Update your email and password.</p>
          <br />
          <EmailSection />
          <Divider />
          <PasswordSection />
        </Card>
      </Container>
    </Wrapper>
  );
};

export default Account;
