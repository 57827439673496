import React from "react";
import styled from "styled-components";
import { Row, Col, Button } from "antd";
import homeDashboard from "../../assets/images/productImages/homeDashboard.png";
import couponStats from "../../assets/images/productImages/couponStats.png";

export default function ProductInfoSection() {
  return (
    <SectionWrapper id="product">
      <SectionContainer>
        <ProductsShowcase>
          <Row gutter={100} style={{ marginBottom: "60px" }}>
            <Col lg={12} md={12}>
              <SubTitle>
                A fully integrated suite of analytic tools
                to track impressions and manage coupons.
              </SubTitle>
              <br />
            </Col>
            <Col lg={8} md={8}>
              <ProductImg
                src={homeDashboard}
                alt="home dashboard"
                draggable={false}
              />
            </Col>
          </Row>
          <Row gutter={60}>
            <Col lg={14} md={14}>
              <CouponStats
                src={couponStats}
                alt="home dashboard"
                draggable={false}
              />
            </Col>
            <Col lg={10} md={10}>
              <SubTitle>Keep track of individual progress</SubTitle>
              <br />
            </Col>
          </Row>
        </ProductsShowcase>
      </SectionContainer>
    </SectionWrapper>
  );
}

const SectionWrapper = styled.div`
  background-color: #f6f7f9;
  height: 980px;
`;

const SectionContainer = styled.div`
  margin: 0 auto;
  max-width: 980px;
  padding: 80px 10px 0 10px;
`;

const ProductHeader = styled.div`
  font-weight: 700;
  font-size: 20px;
  color: #096dd9;
`;

const SubHeader = styled.div`
  width: 500px;
`;

const SubTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  color: #000;
`;

const P = styled.p`
  font-size: 18px;
`;

const ProductActionBtn = styled(Button)`
  border-radius: 20px !important;
`;

const ProductsShowcase = styled.div`
  height: 600px;
`;

const ProductImg = styled.img`
  width: 700px;
  background-color: #f6f9fc;
  border-radius: 20px;
  z-index: 1;
  padding: 12px;
  box-shadow: 0 20px 30px -20px rgba(50, 50, 93, 0.25),
    0 10px 30px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35) !important;
`;

const CouponStats = styled.img`
  width: 560px;
  height: 340px;
  background-color: #f6f9fc;
  border-radius: 20px;
  z-index: 1;
  padding: 12px;
  box-shadow: 0 20px 30px -20px rgba(50, 50, 93, 0.25),
    0 10px 30px -30px rgba(0, 0, 0, 0.3),
    inset 0 -2px 6px 0 rgba(10, 37, 64, 0.35) !important;
`;