import React, { useEffect, useState } from "react";
import { Typography, Card, Row, Col, Tabs, Spin } from "antd";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { useGetCompanyData } from "../../../hooks.js";
import Locations from "../Locations/Locations.js";
import ImageAdsList from "../../ImageAds/ImageAdsList.js";
import CouponsList from "../../Coupons/CouponsList/CouponsList.js";

const { Paragraph } = Typography;
const { TabPane } = Tabs;

function callback(key) {} // Call back for tabs

const BusinessTabs = () => {
  const { companyData } = useGetCompanyData();
  const [currentBusiness, setCurrentBusiness] = useState(null);

  useEffect(() => {
    if (companyData) {
      const { id, data } = companyData;
      setCurrentBusiness({ id, ...data });
    }
  }, [companyData]);

  if (!currentBusiness) {
    return (
      <Row span={24} style={{ marginBottom: "20px" }}>
        <Col
          span={24}
          align="center"
          style={{ position: "absolute", top: 0, right: 600 }}
        >
          <Spin indicator={<Loading3QuartersOutlined spin />} />
        </Col>
      </Row>
    );
  }

  if (currentBusiness) {
    return (
      <>
        <Card className="sm-box-shadow round">
          <Tabs type="card" animated={true} onChange={callback}>
            <TabPane
              tab={<TabTitle label="Image Ads" tooltipTitle="Locations" />}
              key="1"
            >
              <Paragraph>
                Drag the cards to re-arrange the order. This will change the
                order on the mobile app.
              </Paragraph>
              <ImageAdsList />
            </TabPane>
            <TabPane
              tab={<TabTitle label="Coupons" tooltipTitle="Image Ads" />}
              key="2"
            >
              <Paragraph>
                Drag the rows to re-arrange the order. This will change the
                order on the mobile app.
              </Paragraph>
              <CouponsList />
            </TabPane>
            <TabPane
              tab={
                <TabTitle label="Locations" tooltipTitle="Redeemable Coupons" />
              }
              key="3"
            >
              <Locations currentBusiness={currentBusiness} />
            </TabPane>
          </Tabs>
        </Card>
      </>
    );
  }
}

const TabTitle = ({ icon, label, tooltipTitle }) => {
  return (
    <span style={{ fontWeight: "600" }}>
      {icon} {label}
    </span>
  );
};

export default BusinessTabs;