import React from "react";
import {
  StyledMenu,
  StyledMenuItem,
} from "../../../StyledComponents/styledComponents";
import { routes } from "../../../constants/routes";
import { Button, Dropdown } from "antd";
import {
  EllipsisOutlined,
  LineChartOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const TableActions = ({ business }) => {
  const history = useHistory();

  const handleClick = () => {
    const { name } = business;
    const pathName = `${routes.admin.businesses.show}/${name}`;
    console.log(business);
    history.push({
      pathname: pathName,
      state: { ...business },
    });
  };

  const goToAnalytics = () => {
    console.log("current business => ", business);
    const { name, id, compId } = business;
    const pathName = `${routes.admin.insights.show}/${name}`;
    history.push({
      pathname: pathName,
      state: { ...business },
      // state: { name, id: compId } // this will cause some data note to load
    });
  };

  const menu = (
    <StyledMenu>
      <StyledMenuItem onClick={handleClick}>
        <ProfileOutlined /> View business
      </StyledMenuItem>
      <StyledMenuItem onClick={() => goToAnalytics()}>
        <LineChartOutlined /> View analytics
      </StyledMenuItem>
    </StyledMenu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <Button
          shape="round"
          icon={<EllipsisOutlined style={{ color: "ddd" }} />}
        />
      </a>
    </Dropdown>
  );
};

export default TableActions;
